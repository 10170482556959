import React, {useContext} from "react"

import "./style.css"
import { AuthClientContext } from "../../../../contexts/auth-client"

const Welcome = () => {

    const {user} = useContext(AuthClientContext)

    return (
        <section id="welcome" >
            <div className="wrapper flex-row">
                <div className="flex-column">
                    <h1>Olá, {user.primeiroNome}!</h1>
                    <p>
                        Explore e desfrute das melhores fotonovelas produzidas em Angola. 
                    </p>
                </div>
                <div className="image-wrapper">
                    <img src={require("../../../../assets/diva-plavalaguna.jpg")} alt="Image" />
                </div>
            </div>
            
        </section>
    )
}

export default Welcome