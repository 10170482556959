import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { formatDistanceToNow, setDefaultOptions } from 'date-fns'
import { pt } from 'date-fns/locale'

import "./style.css"
import { AdminSidebarContext } from "../../../contexts/admin-sidebar";
import {getAllSubscricoesPendentes} from "../../../services/admin/subscricao-api"

import { AuthAdminContext } from "../../../contexts/auth-admin";
import Loading from "../../../components/loading";

export default function AdminMain(){

    const navigate = useNavigate()

    setDefaultOptions({ locale: pt })

    const {toggleAdminSidebar} = useContext(AdminSidebarContext)
    const {user, logOut} = useContext(AuthAdminContext)
    const [fetchingSubscricoesPendentes, setFetchingSubscricoesPendentes] = useState(false)
    const [subscricoesPendentes, setSubscricoesPendentes] = useState([])
    const [totalSubscricoesPendentes, setTotalSubscricoesPendentes] = useState(0)
    const [notificationIsChecked, setNotificationIsChecked] = useState(false)

    const menuOnClickHandler = (e) => {
        e.preventDefault()

        toggleAdminSidebar()
    }

    const logoutHandler = e => {
        e.preventDefault()

        logOut()
    }

    useEffect( () => {

        fetchSubscricoesPendentes()

    }, [] )

    const fetchSubscricoesPendentes = async () => {
        setFetchingSubscricoesPendentes(true)

        try {
            const {
                subscricoes, total
            } = await getAllSubscricoesPendentes()

            setSubscricoesPendentes(subscricoes)
            setTotalSubscricoesPendentes(total)

            setFetchingSubscricoesPendentes(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setFetchingSubscricoesPendentes(false)
 
                return
            }

            setFetchingSubscricoesPendentes(false)

        }
    }

    const openSubscritorPage = (item) => {

        const subscritor = item.subscritor
        subscritor.subscricoes = []

        setNotificationIsChecked(false)

        navigate(
            `/painel-administrador/subscritores/${item.subscritor.id}`,
            {
                state: {
                    data: subscritor,
                }
            }
        )
    }

    const openUserProfilePage = (e) => {
        e.preventDefault()

        navigate(
            `/painel-administrador/meu-perfil`,
            {
                state: {
                    data: {}
                }
            })
    }

    return (
        <div id="admin-main">
            <div id="admin-main-header" className="flex-row">
                <a href="#" className="menu-btn" onClick={menuOnClickHandler}><span className="material-symbols-outlined">menu</span></a>
                <div
                    className="flex-row"
                    style={{gap: "5px",}}
                >

                    <div id="notifications">
                    {
                        !!user === true && fetchingSubscricoesPendentes === false
                            ? (
                                <>
                                    <input
                                        type="checkbox"
                                        checked={notificationIsChecked}
                                        onChange={() => setNotificationIsChecked( !notificationIsChecked )}
                                    />
                                    <span
                                        className="material-symbols-outlined"
                                    >
                                        notifications
                                    </span>
                                    <span className="pin" style={{
                                        display: totalSubscricoesPendentes > 0 ? "block" : "none"
                            
                                        }} ></span>
                                    <div
                                        className="notifications-menu"
                                        style={{
                                            display: totalSubscricoesPendentes > 0 ? "block" : "none"
                                        }}
                                    >
                                        <h1>Notificações</h1>
                                        <ul>
                                           {
                                            subscricoesPendentes.map ( subscricao => (
                                                <li key={subscricao.id}
                                                    onClick={openSubscritorPage.bind(this, subscricao)}
                                                >
                                                        <i className="type">Subscrição</i>
                                                        <h5>{subscricao.plano.nome}</h5>
                                                        <p>{`${subscricao.subscritor.primeiro_nome} ${subscricao.subscritor.ultimo_nome}`}</p>
                                                        <i>Há {
                                                            formatDistanceToNow(
                                                                new Date( subscricao.created_at.date ),
                                                            )
                                                        }</i>
                                                </li>
                                            ) )
                                           }
                                        </ul>
                                    </div>
                                </>
                            ) 
                            : <Loading gifSize="10px" />
                    }
                        
                    </div>

                    <div
                        className="flex-row user-profile-name"
                    >
                        <input type="checkbox" />
                        <div className="flex-column">
                            {
                                !!user === true
                                    ? (
                                        <>
                                            <span className="name">{`${user?.primeiro_nome} ${user?.ultimo_nome}`}</span>
                                            <span className="job-role">Admin</span>
                                        </>
                                    ) 
                                    : <Loading gifSize="10px" />
                            }
                        
                            
                        </div>
                        {!!user && <span className="material-symbols-outlined">arrow_drop_down</span>}
                        <div
                            id="user-profile-name-menu"
                        >
                            <a href="#" className="flex-row" onClick={openUserProfilePage}>
                                <span className="material-symbols-outlined">person</span>
                                <span>Meu perfil</span>
                            </a>
                            <a href="#" className="flex-row" onClick={logoutHandler}>
                                <span className="material-symbols-outlined">logout</span>
                                <span>Sair</span>
                            </a>
                        </div>
                    </div>

                    
                </div>
                
            </div>
            <div id="admin-main-pages">
                <Outlet context={ {refreshNotifications: async() => fetchSubscricoesPendentes()} }  />
            </div>
        </div>
    )
}
