
import "./style.css"

const SectionTitle = ({title, subtitle}) => {
    return (
        <div
            id="section-title"
            style={{
                display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                userSelect: "none"
            }}
        >
            <p
                style={{
                    textTransform: "uppercase",
                    fontSize: "0.8rem",
                    color: "#1d3557"
                }}
            >
                {subtitle}
            </p>
            <h3>
                {title}
            </h3>
            <span
                style={{
                    width: "60px", height: "4px",
                    background: "#fca311"
                }}
            >
            </span>
        </div>
    )
}

export default SectionTitle