import React from "react"
import LancamentoRating from "../../../../components/lancamento-rating"

import "./style.css"

export default function Comment ({data}) {

    return (
        <div id="comment">
            <h4>{data.nome_subscritor}</h4>
            <div>
                <LancamentoRating rating={data.valor_avaliacao} readOnly={true} />
            </div>
            <p>{data.comentario}</p>
        </div>
    )
}