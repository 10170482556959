
import { get, post, patch, remove, } from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}


export async function create (data) {
    await refreshJwtIfHasExpired()
    
    const endpoint = "/periodos"
    return await post(endpoint, data, configs)
   
}

export async function update (id, data) {
    await refreshJwtIfHasExpired()

    const endpoint = `/periodos/${id}`

    const dataToUpdate = {
        nome: data.nome,
        qtde_dias: data.qtde_dias
    }

    return await patch(endpoint, dataToUpdate, configs)
   
}

export async function eliminate (id) {
    await refreshJwtIfHasExpired()

    const endpoint = `/periodos/${id}`
    
    return await remove(endpoint, configs)
   
}

export async function getAll() {

    await refreshJwtIfHasExpired()

    const endpoint = `/periodos`

    const data = await get(endpoint, configs)

    return data
    
    
}

