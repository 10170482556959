import React, {useState} from "react"
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}; 

export default function InputSelectOne({placeholder, onChangeHandler, options, initialValue}) {

    const [currentValue, setCurrentValue] = useState(initialValue);

    const handleChange = (event) => {
        const {
        target: { value },
        } = event;

        setCurrentValue(value);
    };

    return (
        <div>
            <FormControl sx={{width: "100%", padding: 0}}>
                <Select
                    displayEmpty
                    size="small"
                    value={currentValue}
                    onChange={(e) => {handleChange(e); onChangeHandler(e);}}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em style={{color: "#85827E", fontWeight: "lighter"}} >{`Selecione o ${placeholder}`}</em>;
                        }

                        return selected;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                    <MenuItem disabled value="">
                        <em>{placeholder}</em>
                    </MenuItem>
                        {options.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
  );
}
