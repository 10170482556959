import "./style.css"

const IconBtn = ({googleIconTag = undefined, onClickHandler=null}) => {
    return (
        <div id="icon-btn">
            <a
                onClick={e => {
                    e.preventDefault()
                    
                    onClickHandler && onClickHandler()
                }}
            >
                {googleIconTag}
            </a>
    </div>
    )
}

export default IconBtn


