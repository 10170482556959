import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { useMediaQuery } from "@mui/material"
import json2mq from "json2mq"

import { addAvaliacao } from '../../services/client/fotonovela';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, TextField } from '@mui/material';
import Loading from "./../../components/loading"
import Alert from "./../../components/alert"
import Btn from "./../../components/btn"
import LancamentoRating from "./../../components/lancamento-rating"

const validationSchema = Yup.object({
    comentario: Yup
        .string()
        .min(6, "6 caracteres no mínimo")
        .max(150, "150 caracteres no máximo")
        .required("Campo obrigatório")
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function FeedbackFormDialog({idSubscritor, idFotonovela, closeFormHandler, afterSubmitHandler, }) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [avaliacao, setAvaliacao] = useState(1)

    const matchesMax480 = useMediaQuery(
        json2mq({
            maxWidth: 480
        })
    )
    
    const matchesMax361 = useMediaQuery(
        json2mq({
            maxWidth: 361
        })
    )

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await addAvaliacao(
                idFotonovela,
                {
                    id_subscritor: idSubscritor,
                    valor_avaliacao: avaliacao,
                    comentario: values.comentario
                }
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {
            comentario: ""
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div >
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#eee" }} id="customized-dialog-title">
                    Dê-nos o seu feedback
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Enviando..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {
                        !!success && (
                            <>
                                <Alert message="Obrigado pelo seu feedback!" severity="success" />
                            </>
                        )
                    }
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "0 16px 16px 16px",
                        width: matchesMax361 ? "250px" : ( matchesMax480 ? "300px" : "400px")
                    }}
                >

                    {
                        !!formSubmited === false
                        && (
                            <>
                                <div
                                    style={{
                                        marginBottom: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "stretch",
                                        gap: "10px",
                                        width: "100%"
                                    }}
                                >
                                   

                                    <div style={{paddingBottom: "10px", display: "flex", alignItems: "center"}}>
                                        <h5>Quantas estrelas merece? </h5><LancamentoRating readOnly={false} rating={avaliacao} handleChange={setAvaliacao} />
                                    </div>

                                    <TextField
                                        fullWidth
                                        id="comentario"
                                        name="comentario"
                                        label="Comentário"
                                        size="small"
                                        multiline={true}
                                        value={formik.values['comentario']}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched['comentario'] && Boolean(formik.errors['comentario'])}
                                        helperText={formik.touched['comentario'] && formik.errors['comentario']}
                                    />

                                </div>

                                <Btn size="large" isPrimary={true} text="Enviar" stretch={true} borderRadius="5px" onClickHandler={formik.submitForm} />
                                
                            </>
                        )
                    }
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}