import React, { createContext, useState, useEffect } from "react"
import { getDecryptedDataFromLocalStorage, saveEncryptedDataInLocalStorage } from "../utils"

export const ClientNavigationContext = createContext()

export const ClientNavigationContextProvider = ({children}) => {
    
    const [currentPage, setCurrentPage] = useState("home")

    useEffect(() => {
        const currentPage = getDecryptedDataFromLocalStorage("fn_current-client-page")
        setCurrentPage(currentPage ? currentPage : "home")
    }, [])

    const updateCurrentPage = (name) => {
        saveEncryptedDataInLocalStorage("fn_current-client-page", name)

        setCurrentPage(name)
    }

    return (
        <ClientNavigationContext.Provider value={{currentPage, updateCurrentPage}}>
            {children}
        </ClientNavigationContext.Provider>
    )
}