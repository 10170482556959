import React, { useState, useEffect, useContext} from "react"
import { useMediaQuery } from "@mui/material"
import json2mq from "json2mq"
import { useLocation } from "react-router-dom"

import "./style.css"
import {getAllFotonovelas} from "./../../../../services/client/subscritor-api"
import {getAllSubscricoes} from "./../../../../services/client/subscritor-api"
import {getAll as getAllPlanos} from "./../../../../services/client/plano-api"
import { AuthClientContext } from "../../../../contexts/auth-client"
import { ClientNavigationContext } from "../../../../contexts/client-navigation"

import Lancamento from "../../../../components/lancamento"
import UltimaVisualizacao from "./ultima-visualizacao"
import SubscricaoAtiva from "./subscricao-ativa"
import SemVisualizacao from "./ultima-visualizacao/sem-visualizacao"
import SemSubscricao from "./subscricao-ativa/sem-subscricao"
import Loading from "../../../../components/loading"
import { getDecryptedDataFromLocalStorage, saveEncryptedDataInLocalStorage } from "../../../../utils"
import { Link } from "react-router-dom"

const HomeContent = () => {

    const location = useLocation()

    const authClient = useContext(AuthClientContext)
    const loggedUser = location?.state?.loggedUser

    const user = !!authClient.user ? authClient.user : loggedUser

    const {updateCurrentPage} = useContext(ClientNavigationContext)

    const [lancamentos, setLancamentos] = useState([])
    const [totalLancamentos, setTotalLancamentos] = useState(0)
    const [loadingFotonovelas, setLoadingFotonovelas] = useState(false)
    const [subscricoes, setSubscricoes] = useState([])
    const [loadingSubscricoes, setLoadingSubscricoes] = useState(false)
    const [planos, setPlanos] = useState([])
    const [loadingPlanos, setLoadingPlanos] = useState(false)
    const [fotonovelasDoPlano, setFotonovelasDoPlano] = useState([])
    const [loadingFotonovelasDoPlano, setLoadingFotonovelasDoPlano] = useState(false)
    const [subscricaoAtiva, setSubscricaoAtiva] = useState(null)
    const [subscricaoEmEspera, setSubscricaoEmEspera] = useState(null)
    const [ultimaVisualizacao, setUltimaVisualizacao] = useState(null)
    const [error, setError] = useState(null)

    const matches = useMediaQuery(
        json2mq({
            maxWidth: 800
        })
    )

    useEffect(() => {

        fetchFotonovelas()

        fetchSubscricoes()

        fetchUltimaCena()

    },[])

    const fetchFotonovelas = async () => {

        setLoadingFotonovelas(true)
        setError(null)

        try {
            const {fotonovelas, total} =  await getAllFotonovelas(user.id, 3, 1, "", "id DESC")

            setLancamentos(fotonovelas)
            setTotalLancamentos(total)

            setLoadingFotonovelas(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingFotonovelas(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingFotonovelas(false)

        }
        
    }

    const fetchSubscricoes = async () => {
 
        setLoadingSubscricoes(true)
        setError(null)

        try {
            
            await  fetchPlanos()

            const {subscricoes} =  await getAllSubscricoes(user.id)
            
            setSubscricoes(subscricoes)

            if (subscricoes) {

                const subscricaoActived = subscricoes.filter( subscricao => subscricao.estado === "activo" )
                const subscricaoToValidate = subscricoes.filter( subscricao => subscricao.estado === "em espera" )

                if (subscricaoActived) {
                    setSubscricaoAtiva( subscricaoActived[0] )
                }

                if (subscricaoToValidate) {
                    setSubscricaoEmEspera( subscricaoToValidate[0] )
                }
            }

            

            setLoadingSubscricoes(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingSubscricoes(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingSubscricoes(false)

        }
        
    }

    const fetchPlanos = async () => {
 
        setLoadingPlanos(true)
        setError(null)

        try {
            
            const {planos, total} =  await getAllPlanos()
            
            setPlanos(planos)

            setLoadingPlanos(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingPlanos(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingPlanos(false)

        }
        
    }
    

    const fetchUltimaCena = async () => {
        const ultimaVisualizacao = getDecryptedDataFromLocalStorage("fn_last_episode_viewed")

        if (!ultimaVisualizacao) return

        if (ultimaVisualizacao?.idSubscritor === user.id) {
            setUltimaVisualizacao(ultimaVisualizacao)
        }

    }

    return (
        <section id="home-content">
            <div className="lancamentos">
                <div className="header">
                    <h1 className=" flex-row">
                        <span className="material-symbols-outlined">lists</span>
                        <span>Últimos lançamentos</span>
                    </h1>
                </div>
                <div className="lancamentos-list">

                    {
                        loadingFotonovelas
                            ? (
                                <div style={{paddingTop: "5rem"}} >
                                    <Loading />
                                </div>
                            )
                            : (
                                <>
                                    {
                                        lancamentos.map(item => {
                                            return ( <Lancamento key={item.id} lancamento={item} /> )
                                        })
                                    }
                                    {
                                        !loadingFotonovelas && lancamentos.length > 0 &&
                                        <p
                                            style={{
                                                textAlign: "center",
                                                fontSize: "0.8rem",
                                                margin: "1rem auto 0 auto",
                                                width: "fit-content",
                                                padding: "0 2rem",
                                                border: "1px solid #eee",
                                                borderRadius: "20px"
                                            }}  
                                        >
                                            <Link
                                                to="/painel-usuario/fotonovelas"
                                                style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "3px" }}
                                                onClick={() => updateCurrentPage("fotonovelas")}
                                            >
                                                Ver todos
                                                <span className="material-symbols-outlined" style={{fontWeight: "100", fontSize: "1.5rem"}}>arrow_right_alt</span>
                                            </Link>
                                        </p>
                                    }
                                   
                                </>
                            )
                    }

                    

                </div>
                
            </div>


            <div className="flex-column side-details">
                <div
                    className="subscricao-corrente"
                >
                    <div className="header">
                        <h1 className=" flex-row">
                            <span className="material-symbols-outlined">card_membership</span>
                            <span>Subscrição Ativa</span>
                        </h1>
                    </div>
                    <div>
                        {
                            loadingSubscricoes
                                ? <div
                                        style={{ height: "150px", display: "grid", placeItems: "center"}}
                                    >
                                        <Loading />
                                    </div> 
                                : (
                                    <>
                                        {
                                            subscricaoAtiva
                                                ? <SubscricaoAtiva subscricao={subscricaoAtiva} />
                                                : <SemSubscricao planos={planos} loggedUser={user} afterSubmitHandler={() => fetchSubscricoes()} existeSubscricaoEmEspera={!!subscricaoEmEspera} />
                                        }
                                    </>
                                )
                        }
                        
                    </div>
                    
                </div>


                <div
                    className="ultima-fotonovela-visualizada"
                >
                    <div className="header">
                        <h1 className=" flex-row">
                            <span className="material-symbols-outlined">slideshow</span>
                            <span>Em Visualização</span>
                        </h1>
                    </div>
                    <div>
                    {
                        loadingSubscricoes
                            ? <div
                                    style={{ height: "150px", display: "grid", placeItems: "center"}}
                                >
                                    <Loading />
                                </div> 
                            : (
                                <>
                                    {
                                            !!ultimaVisualizacao
                                                ? <UltimaVisualizacao ultimaVisualizacao={ultimaVisualizacao} subscricaoIsAtiva={!!subscricaoAtiva} refreshUltimaVisualizacao={fetchUltimaCena} idSubscritor={user.id} />
                                                : <SemVisualizacao />
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default HomeContent