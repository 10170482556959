
import {get} from "../api"
import {refreshJwtIfHasExpired} from "./usuario-api"

const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

export async function getData() {

    await refreshJwtIfHasExpired()

    const endpoint = "/dashboard-data"

    const data = await get(endpoint, configs)    

    return data
    
}