import React, { useState, useEffect, useContext, } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {format} from "date-fns"

import "./style.css"
import { getAllSubscricoes, resendConfirmationEmail } from '../../../../services/admin/subscritor-api';
import { baseURL } from '../../../../services/api';

import Button from "./../../components/button"
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';
import ValidarSubscricaoFormDialog from './varlidar-subscricao-form-dialog';
import DataTable from './data-table';

export default function AdminSubscritorDetalhes () {
    const location = useLocation()

    const {data} = location.state;

    const [validarSubscricaoFormOpen, setValidarSubscricaoFormOpen] = useState(false);
    const [subscricoes, setSubscricoes] = useState(data.subscricoes)
    const [fetchingSubscricoes, setFetchingSubscricoes] = useState(false)
    const [resendingConfirmationEmail, setResendingConfirmationEmail] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}
    const [subscricaoToValidate, setSubscricaoToValidate] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        fetchSubscricoes()
    }, [data.id])
   
    const openvalidarSubscricaoForm = (item) => {
        setSubscricaoToValidate(item)

        setValidarSubscricaoFormOpen(true)
    }

    const closevalidarSubscricaoForm = () => {
        setValidarSubscricaoFormOpen(false);
    };

    const downloadComprovante = (item) => {
        window.open(
            `${baseURL}/uploads/subscricoes/${item.id}/${item.comprovante}?nocache=${Date.now() * Math.random()}`,
            "_blank"
        )
    }


    const fetchSubscricoes = async () => {
 
        setFetchingSubscricoes(true)
        setError(null)

        try {
            const result = await getAllSubscricoes(data.id)

            setSubscricoes(result.subscricoes)

            setFetchingSubscricoes(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setFetchingSubscricoes(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setFetchingSubscricoes(false)

        }
        
    }
    
    const reconfirmEmail = async () => {
 
        setResendingConfirmationEmail(true)

        try {
            await resendConfirmationEmail(data.id)

            setResendingConfirmationEmail(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setResendingConfirmationEmail(false)
 
                return
            }
            setResendingConfirmationEmail(false)

        }
        
    }


    return (
        <>
            {
                validarSubscricaoFormOpen 
                    ? (<ValidarSubscricaoFormDialog
                        closeFormHandler={closevalidarSubscricaoForm}
                        afterSubmitHandler={fetchSubscricoes}
                        idSubscricao={subscricaoToValidate.id}
                    />) : ""
            }
            
            <div id="subscritor-detalhes-page">
                    <div>
                        <Button
                            text="Voltar"
                            googleIconName="undo"
                            crudOperation="cancel"
                            fullWidth={false}
                            primary={true}
                            clickHandler={() => navigate(-1)}
                        />
                    </div>
                    <div>
                        <div className="details">
                            
                            <div className="content-wrapper">
                                <div>
                                    <h2>{`${data.primeiro_nome} ${data.ultimo_nome}`}</h2>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2rem"
                                    }}
                                >
                                    <div>
                                        <span>E-mail</span>
                                        <p>{data.email}</p>
                                    </div>
                                    <div>
                                        <span>E-mail confirmado</span>
                                        <p>{`${data.email_confirmado ? "Sim" : "Não"}`}</p>
                                    </div>
                                    <div>
                                        <span>País</span>
                                        <p>{data.pais.nome}</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: "5px 0 0 0",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem"
                                    }}
                                >
                                    {
                                        resendingConfirmationEmail
                                            ? <div style={{width: "183px"}}> <Loading text="Enviando..." gifSize="15px" /> </div>
                                            : (
                                                data.email_confirmado !== true && <Button
                                                                    text="Reenviar email de confirmação"
                                                                    googleIconName="add"
                                                                    crudOperation="create"
                                                                    primary={true}
                                                                    fullWidth={false}
                                                                    hideIcon={true}
                                                                    clickHandler={reconfirmEmail}
                                                                />
                                            )
                                    }
                                    
                                
                                </div>
                            </div>
                        </div>
                        <div className="subscricoes">
                            <h4>Subscrições</h4>
                            <>
                                {
                                    fetchingSubscricoes
                                    && <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}  
                                    >
                                            <Loading text="Carregando..." />
                                        </div>
                                }
                                {!!error && <Alert message={error.message} severity="error" />}
                            </>

                            <div>
                                {
                                    !fetchingSubscricoes && Boolean(error) === false
                                    ? (
                                        <DataTable
                                            data = {subscricoes}
                                            validarBtnClickHandler={openvalidarSubscricaoForm}
                                            downloadComprovanteBtnClickHandler={downloadComprovante}
                                        />
                                    )
                                    : null
                                }
                            </div>
                            
                        </div> 
                    </div>

                </div>
                
        </>
    )
}