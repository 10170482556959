
import { post, remove, postWithMultipartBody,} from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

export async function createMany (data) {
    await refreshJwtIfHasExpired()
    
    const endpoint = "/cenas"
    return await post(endpoint, data, configs)
   
}

export async function update (data) {
    await refreshJwtIfHasExpired()

    const {id, img_cena_base64} = data

    const file = img_cena_base64 ? DataURIToBlob(img_cena_base64) : null
    const formData = new FormData();
    if (file) {
        formData.append('img_cena', file) 
    }

    const configsModified = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    }

    const endpoint = `/cenas/${id}`

    return await postWithMultipartBody(endpoint, formData, configsModified)
   
}

export async function eliminate (id) {
    await refreshJwtIfHasExpired()

    const endpoint = `/cenas/${id}`
    
    return await remove(endpoint, configs)
   
}


