import React from "react"

import "./style.css"

export default function FnButton({ iconButton="", variant="default", size="small", danger="", okay="", label="", icon=null, handleClick, inactive="", stretch=""}) {
    return (
        <button
            id="fnbutton"
            className={`${variant} ${size} ${danger} ${okay} ${iconButton} ${inactive} ${stretch}`}
            onClick={handleClick}
        >
            {icon} {label}
        </button>
    )
}