
import LancamentoRating from "../../../components/lancamento-rating"
import SectionTitle from "../global/section-title"
import "./style.css"

const Lancamentos = () => {
    return (
        <div id="lp-lancamentos">
            <SectionTitle title="Fotonovelas" subtitle="Últimos lançamentos" />
            <div className="lancamentos-wrapper">
                {
                    // mostrar os 6 ultimos lançamentos
                    /* 
                    <div className="lancamento">
                        <img src={require("../../../assets/thumbnails/img1.jpg")} alt="" />
                        <div>
                            <LancamentoRating rating={2} />
                            <h3>Setimo Ceu</h3>
                        </div>
                    </div>
                    */
                }
            </div>
        </div>
    )
}


export default Lancamentos