import React from "react"

const SemVisualizacao = () => {

    return (
    
            <div
                className="flex-column"
                style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "150px",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #eee",
                    userSelect: "none",
                }}
            >
                <span
                    className="material-symbols-outlined"
                    style={{
                        fontSize: "4rem",
                        fontWeight: "300",
                        color: "#bababa",
                    }}
                >
                    preview_off
                </span>
                <p
                    style={{
                        color: "#c6c5c5",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontSize: "0.7rem",
                        fontWeight: "600"
                    }}      
                >
                    Nenhuma encontrada
                </p>
            </div>
        
    )
}

export default SemVisualizacao