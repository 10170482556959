import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { createDemo } from "../../../../services/admin/fotonovela-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import { Box, Divider, TextField } from '@mui/material';
import InputSelectOne from '../../components/input-select-one';

const validationSchema = Yup.object({
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function CreateDemoFormDialog({closeFormHandler, afterSubmitHandler, capitulos, idFotonovela}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [idDemo, setIdDemo] = useState(null)

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler(idDemo)
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            const {id_demo} = await createDemo(
                idFotonovela,
                {
                    id_capitulo: currentCapitulo.split(" ")[0],
                    id_primeira_cena: primeiraCena,
                    id_ultima_cena: ultimaCena
                }
            )

            setIdDemo(id_demo)

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const [cenas, setCenas] = useState([])
    const inputCapituloSelectOptions = capitulos.map(capitulo => `${capitulo.id} - ${capitulo.titulo}`)


    const [currentCapitulo, setCurrentCapitulo] = useState("");
    const inputCapituloSelectChangeHandler = (event) => {

        const {
        target: { value },
        } = event;

        setCurrentCapitulo(value);

        const idCapitulo = value.split(" ")[0]

        setCenas(
            capitulos.filter (item => item.id == idCapitulo)[0].cenas.map(cena => cena.id)
        )
       
    };

    const [primeiraCena, setPrimeiraCena] = useState("");
    const inputPrimeiraCenaSelectChangeHandler = (event) => {
        const {
        target: { value },
        } = event;

        setPrimeiraCena(value);

    };

    const [ultimaCena, setUltimaCena] = useState("");
    const inputUltimaCenaSelectChangeHandler = (event) => {
        const {
        target: { value },
        } = event;

        setUltimaCena(value);

    };

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Configurar demo
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                            width: "400px"
                        }}
                    >
                        
                        <InputSelectOne placeholder="Capitulo" onChangeHandler={inputCapituloSelectChangeHandler} options={inputCapituloSelectOptions} initialValue="" />
                        <InputSelectOne placeholder="Primeira Cena" initialValue={primeiraCena} options={cenas} onChangeHandler={inputPrimeiraCenaSelectChangeHandler} />
                        <InputSelectOne placeholder="Ultima Cena" initialValue={ultimaCena} options={cenas} onChangeHandler={inputUltimaCenaSelectChangeHandler} />

                    </div>

                    <Button
                        text="Salvar as alterações"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={formik.submitForm}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}