import React, {useState} from "react"

import "./../data-table.css"

import Button from "../../components/button"
import DeleteFormDialog from "./delete-form-dialog"
import UpdateFormDialog from "./update-form-dialog"

export default function DataTable({data, afterSubmitHandler}) {
    const [updateFormOpen, setUpdateFormOpen] = useState(false)
    const [deleteFormOpen, setDeleteFormOpen] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState()

    const openUpdateFormHandler = (item) => {
        setSelectedRecord(item)
        setUpdateFormOpen(true)
    }

    const openDeleteFormHandler = (item) => {
        setSelectedRecord(item)
        setDeleteFormOpen(true)
    }

    return (
        <div className="admin-data-table">
            <table>
                <thead>
                    <tr
                        style={{backgroundColor: "#dfdcdc"}}
                    >
                       <th style={{width: "50px"}}>N/O</th>
                       <th>Nome</th>
                       <th>Qtde de dias</th>
                       <th className="btns-operacao">Operação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => (
                        <tr key={item.id} >
                            <td>{index + 1}</td>
                            <td>{item.nome}</td>
                            <td>{item.qtde_dias}</td>
                            <td className="btns-operacao">
                                <Button
                                    text="Alterar"
                                    googleIconName="edit"
                                    crudOperation="update"
                                    primary={false}
                                    size="small"
                                    clickHandler={openUpdateFormHandler.bind(this, item)}
                                />
                                <Button
                                    text="Deletar"
                                    googleIconName="delete"
                                    crudOperation="delete"
                                    primary={false}
                                    size="small"
                                    clickHandler={openDeleteFormHandler.bind(this, item)}
                                />
                            </td>
                        </tr>
                    ))
                }
                    
                </tbody>
            </table>
            {
                updateFormOpen && (
                        <UpdateFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setUpdateFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            {
                deleteFormOpen && (
                        <DeleteFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setDeleteFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            
        </div>
    )
}


