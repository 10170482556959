import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { create } from "../../../../services/admin/plano-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import { Divider, TextField } from '@mui/material';
import InputSelectOne from '../../components/input-select-one';

const validationSchema = Yup.object({
    "nome": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(30, "30 caracteres no máximo")
        .required("Campo obrigatório"),
    "descricao": Yup
        .string()
        .required("Campo obrigatório"),
    "preco": Yup
        .string()
        .required("Campo obrigatório"),
    "id_periodo": Yup
        .string()
        .required("Campo obrigatório"),
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function CreateFormDialog({closeFormHandler, afterSubmitHandler, periodos}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [idPeriodo, setIdPeriodo] = useState(0)

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        const {nome, descricao, preco} = values

        try {
            await create({
                nome,
                descricao,
                preco,  
                id_periodo: idPeriodo !== 0 ? idPeriodo.split(" ")[0] : idPeriodo
            })

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const periodoSelectChangeHandler = (event) => {
        const {
        target: { value },
        } = event;

        setIdPeriodo(value)
    };

    const formik = useFormik({
        initialValues: {
            "nome": "",
            "descricao": "",
            "preco": 0,
            "id_periodo": 0
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    const periodoOptions = periodos.map( periodo => `${periodo.id} - ${periodo.nome}` )

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Adicionar novo plano
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                            width: "400px"
                        }}
                    >
                        <TextField
                            fullWidth
                            id="nome"
                            name="nome"
                            label="Nome"
                            size="small"
                            value={formik.values['nome']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['nome'] && Boolean(formik.errors['nome'])}
                            helperText={formik.touched['nome'] && formik.errors['nome']}
                        />

                        <TextField
                            fullWidth
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            size="small"
                            multiline={true}
                            value={formik.values['descricao']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['descricao'] && Boolean(formik.errors['descricao'])}
                            helperText={formik.touched['descricao'] && formik.errors['descricao']}
                        />

                        <TextField
                            fullWidth
                            id="preco"
                            name="preco"
                            label="Preço"
                            size="small"
                            value={formik.values['preco']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['preco'] && Boolean(formik.errors['preco'])}
                            helperText={formik.touched['preco'] && formik.errors['preco']}
                        />
                        
                        <InputSelectOne placeholder="Período" onChangeHandler={periodoSelectChangeHandler} options={periodoOptions} initialValue="" />
                                        
                    </div>

                    <Button
                        text="Salvar o registo"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={formik.submitForm}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}