
import React, { useContext } from "react"
import { AuthClientContext } from "./contexts/auth-client"
import ClientLoadingPage from "./scenes/client/loading-page"
import { Navigate } from "react-router-dom"

export default function ClientPrivateRoute({children}){

    const {signedIn, loading} = useContext(AuthClientContext)

    if (loading) {
        return <ClientLoadingPage text="Carregando a página..." />
    }

    if (!signedIn) {
        return <Navigate to="/login" />
    }

    return (
        <>
            {children}
        </>
    )
}

