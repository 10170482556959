
import { get, post, remove, postWithMultipartBody, } from "../api"

import {refreshJwtIfHasExpired} from "./subscritor-api"

const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

export async function addAvaliacao(idFotonovela, data) {

    await refreshJwtIfHasExpired()

    const endpoint = `/foto_novelas/${idFotonovela}/avaliacoes`

    return await post(endpoint, data, configs)
    
}


