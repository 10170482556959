
import { Outlet } from "react-router-dom";
import "./style.css"
import NavClient from "../nav"

const UserPanel = () => {
    
    return (
        <div id="user-panel">
            <NavClient />
            <div className="main">
                <Outlet />
            </div>
        </div>
    )
}

export default UserPanel