import React from "react"
import { useNavigate } from "react-router-dom"
import {format} from "date-fns"

import "./style.css"
import { baseURL } from "../../services/api"
import {calculateFotonovelaRating, saveEncryptedDataInLocalStorage} from "./../../utils"

import LancamentoRating from "../lancamento-rating"
import Generos from "./genero"

const Lancamento = ({lancamento}) => {

    const navigate = useNavigate()

    const handleClick = () => {

        saveEncryptedDataInLocalStorage("fn_current-fn", lancamento)

        navigate(
            `/painel-usuario/fotonovelas/${lancamento.id}`, {
                state: {
                    data: lancamento
                }
            }
        )
    }

    return (
        <article
            id="lancamento" className="flex-row"
            onClick={handleClick}
        >
            <img
                src={`${baseURL}/uploads/fotonovelas/${lancamento.id}/${lancamento.img_capa}?nocache=${Date.now() * Math.random()}`}
                alt={lancamento.img_capa}
            />
            <div className="flex-column">
                <div className="title">
                    <h1>{lancamento.titulo}</h1>
                    <span
                        className="material-symbols-outlined"
                        style={{
                            color: lancamento.available ? "#198754" : "#ccc"
                        }}
                    >
                        verified
                    </span>
                    <span
                        className="material-symbols-outlined"
                        style={{
                            color: lancamento.favorite ? "#198754" : "#ccc"
                        }}
                    >
                        favorite
                    </span>
                </div>
                <Generos generos={lancamento.generos}/>
                <div className="description">
                    <span>Atualizado em {format(new Date(lancamento.updated_at.date), "dd/MM/yyyy hh:mm")} - {lancamento.capitulos.length} Capitulos</span>
                </div>
                
                <div>
                    <LancamentoRating rating={calculateFotonovelaRating(lancamento.avaliacoes)} readOnly={true} maxWidth={80} inactiveFillColor="#888" />
                </div>
                
            </div>
        </article>
    )
}

export default Lancamento