
import { get, post, remove, patch, } from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}


export async function updateEstado (id, data) {
    await refreshJwtIfHasExpired()

    const endpoint = `/subscricoes/${id}`

    const dataToUpdate = {
        estado: data.estado,
        motivo_rejeicao: data.motivo_rejeicao
    }

    return await patch(endpoint, dataToUpdate, configs)
   
}

export async function getAllSubscricoesPendentes() {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscricoes`

    const configsModified = {
        ...configs, params: {
            estado: "em espera"
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}


