import React, {useContext, useState} from "react"
import { useNavigate } from "react-router-dom"

import "./../data-table.css"
import { baseURL } from '../../../../services/api';
import { calculateFotonovelaRating } from "../../../../utils";

import LancamentoRating from "./../../../../components/lancamento-rating"
import Button from "../../components/button"
import UpdateFormDialog from "./update-form-dialog"
import DeleteFormDialog from "./delete-form-dialog"

export default function DataTable({data, afterSubmitHandler}) {
    const [updateFormOpen, setUpdateFormOpen] = useState(false)
    const [deleteFormOpen, setDeleteFormOpen] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState()

    const navigate = useNavigate()

    const openUpdateFormHandler = (item) => {
        setSelectedRecord(item)
        setUpdateFormOpen(true)
    }

    const openDeleteFormHandler = (item) => {
        setSelectedRecord(item)
        setDeleteFormOpen(true)
    }

    const openDetails = (item) => {
        navigate(
            `${item.id}`,
            {
                state: {
                    data: item
                }
            }
        )
    }

    return (
        <div className="admin-data-table">
            <table>
                <thead>
                    <tr
                        style={{backgroundColor: "#dfdcdc"}}
                    >
                       <th style={{width: "50px"}}>N/O</th>
                       <th style={{width: "0"}} >Capa</th>
                       <th>Título</th>
                       <th>Gêneros</th>
                       <th >Classificação</th>
                       <th className="btns-operacao">Operação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => (
                        <tr key={item.id} >
                            <td>{index + 1}</td>
                            <td>
                                <img
                                    src={`${baseURL}/uploads/fotonovelas/${item.id}/${item.img_capa}?nocache=${Date.now() * Math.random()}`}
                                    alt={item.img_capa}
                                    width="60px"
                                />
                            </td>
                            <td>{item.titulo}</td>
                            <td>{
                                (item.generos.reduce((previousValue, currentValue) => `${previousValue}, ${currentValue}`, "") ).substring(2)
                            }</td>
                            <td>
                                <LancamentoRating rating={calculateFotonovelaRating(item.avaliacoes)} />
                            </td>
                            <td
                                className="btns-operacao"
                                style={{
                                    height: "111px"
                                }}
                            >
                                <Button
                                    text="Alterar"
                                    googleIconName="edit"
                                    crudOperation="update"
                                    primary={false}
                                    size="small"
                                    clickHandler={openUpdateFormHandler.bind(this, item)}
                                />
                                <Button
                                    text="Deletar"
                                    googleIconName="delete"
                                    crudOperation="delete"
                                    primary={false}
                                    size="small"
                                    clickHandler={openDeleteFormHandler.bind(this, item)}
                                />
                                <Button
                                    text=""
                                    googleIconName="open_in_new"
                                    crudOperation="cancel"
                                    primary={false}
                                    size="small"
                                    clickHandler={openDetails.bind(this, item)}
                                />
                            </td>
        
                        </tr>
                    ))
                }
                    
                </tbody>
            </table>
            {
                updateFormOpen && (
                        <UpdateFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setUpdateFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            {
                deleteFormOpen && (
                        <DeleteFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setDeleteFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            
        </div>
    )
}


