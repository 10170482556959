import React, {useState, useEffect} from "react"
import { useFormik } from "formik";
import { useParams, useNavigate } from "react-router-dom";

import "./style.css"
import {activate} from "../../../services/client/subscritor-api"
import AppLogo from "./../../../components/app-logo"

import Loading from "../../../components/loading";
import Alert from "../components/alert";
import Success from "./sucess";

const ConfirmationPage = () => {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [stage, setStage] = useState("Activando a conta")

    const {id_subscritor} = useParams()

    useEffect(() => {

        submitForm()

    }, [])

    const navigate = useNavigate()

    const submitForm = async () => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await activate( atob(id_subscritor) )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    return (
            <div
                id="confirmation-client-success"
                style={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                
                <div
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        padding: ".5rem",
                        display: "grid",
                        placeItems: "center"
                    }}
                >
                    <AppLogo fontSize="1rem" />
                </div>

                <div>
                    {
                        submitingForm
                        && (
                            <>
                                <Loading text={stage}/>
                                <p>Aguarde um momento, por favor...</p>
                            </>
                        )
                    }
                    {
                        formSubmited && success && <Success title="Conta ativada com sucesso" content="Clique em fazer login" />
                    }
                    {
                        !!error
                        && (
                            <>
                                <Alert message={error.message} severity="error"  />
                                <p
                                    style={{
                                        textAlign: "center",
                                        marginTop: "1rem"
                                    }}
                                >
                                    Clique <a href="/login" style={{color: "dodgerblue"}}>aqui</a> para voltar
                                </p>
                            </>
                        )
                    }
                </div>
            </div>
    )
}

export default ConfirmationPage