
import "./style.css"

import loading from "./../../assets/loading.gif"

export default function Loading({text, gifSize = "20px"}) {
    return (
        <div id="loading-component">
            <img src={loading} alt="Carregando" width={gifSize} />
            { !!text && <p
                style={{
                    fontSize: "10px",
                    fontWeight: "600"
                }}
            >
                {text}
            </p>}
        </div>
    )
}