import React, {  } from "react"
import {format} from "date-fns"

import "./../data-table.css"

import Button from "../../components/button"

const formatCurrency = (number) => new Intl.NumberFormat( "ao-AO", {style: "currency", currency: "AOA"} ).format(number)

const calcExpirationDate = (initialDate, periodDays) => {
 
    const endDate = new Date(initialDate)
    endDate.setDate( initialDate.getDate() + periodDays )

    return endDate
}

const subscricaoIsExpired = (initialDate, periodDays) => {
    const endDate = calcExpirationDate(initialDate, periodDays)

    // console.log("inicio", initialDate)
    // console.log("dias", periodDays)
    // console.log("termino", endDate)
    
    const today = new Date()

    // console.log("hoje", today)
    // console.log("expired", today > endDate)

    return today > endDate
}

export default function DataTable({data, validarBtnClickHandler, downloadComprovanteBtnClickHandler}) {

    return (
            <div className="admin-data-table">
                <table>
                    <thead>
                        <tr
                            style={{backgroundColor: "#dfdcdc"}}
                        >
                            <th style={{width: "50px"}}>N/O</th>
                            <th>Plano</th>
                            <th>Periodo</th>
                            <th>Valor pago</th>
                            <th>Moeda</th>
                            <th>Câmbio</th>
                            <th>Estado</th>
                            <th>Início</th>
                            <th>Término</th>
                            <th>Submissão</th>
                            <th className="btns-operacao">Operação</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item, index) => (
                            <tr key={item.id} >
                                <td>{index + 1}</td>
                                <td>{item.plano.nome}</td>
                                <td>{`${item.plano.periodo.qtde_dias} dias` }</td>
                                <td>{formatCurrency(item.valor_pago)}</td>
                                <td>{item.moeda}</td>
                                <td>{item.cambio}</td>
                                <td>{item.estado}</td>
                                <td>{
                                    Boolean(item.inicio_validade)
                                        ? format(new Date(item.inicio_validade), "dd/MM/yyyy") : "-"}</td>
                                <td>{
                                    Boolean(item.inicio_validade)
                                        ? 
                                            format (
                                                calcExpirationDate( new Date(item.inicio_validade), item.plano.periodo.qtde_dias ),
                                                "dd/MM/yyyy"
                                            )
                                        : "-"
                                }</td>
                                <td>{format(new Date(item.created_at), "dd/MM/yyyy hh:mm")}</td>
                                <td
                                    className="btns-operacao"
                                >
                                    
                                     <Button
                                            text=""
                                            googleIconName="download"
                                            crudOperation="update"
                                            primary={false}
                                            size="small"
                                            clickHandler={downloadComprovanteBtnClickHandler.bind(this, item)}
                                        />

                                    {
                                        item.estado === "em espera" &&
                                        <Button
                                            text="Validar"
                                            googleIconName="done"
                                            crudOperation="create"
                                            primary={false}
                                            size="small"
                                            clickHandler={validarBtnClickHandler.bind(this, item)}
                                        />
                                    }
                                </td>
            
                            </tr>
                        ))
                    }
                        
                    </tbody>
                </table>
                
            </div>
    )
}


