import { createContext, useState } from "react"

export const AdminSidebarContext = createContext()

export const AdminSidebarContextProvider = ({children}) => {

    const [adminSidebarIsVisible, setVisible] = useState(false)

    const toggleAdminSidebar = () => {
        setVisible(!adminSidebarIsVisible)
    }

    return (
        <AdminSidebarContext.Provider value={{adminSidebarIsVisible, toggleAdminSidebar}}>
            {children}
        </AdminSidebarContext.Provider>
    )
}