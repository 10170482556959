import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { update } from "../../../../services/admin/capitulo-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import { Box, Divider, TextField } from '@mui/material';
import CustomImagePicker from '../../components/image-picker';

const validationSchema = Yup.object({
    "titulo": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(30, "30 caracteres no máximo")
        .required("Campo obrigatório"),
    "descricao": Yup
        .string()
        .min(5, "5 caracteres no mínimo")
        .max(150, "150 caracteres no máximo")
        .required("Campo obrigatório"),
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function UpdateCapituloFormDialog({idFotonovela, data, closeFormHandler, afterSubmitHandler}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        const {titulo, descricao} = values

        try {
            await update({
                id: data.id,
                titulo,
                descricao,
                img_capa_base64: base64Image    
            })

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const [base64Image, setBase64Image] = useState(null)

    const imagePickerChangeHandler = (base64) => {
        setBase64Image(base64)
    }

    const formik = useFormik({
        initialValues: {
            "titulo": data?.titulo,
            "descricao": data?.descricao,
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Alterar capítulo
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                            width: "400px"
                        }}
                    >
                        <TextField
                            fullWidth
                            id="titulo"
                            name="titulo"
                            label="Título"
                            size="small"
                            value={formik.values['titulo']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['titulo'] && Boolean(formik.errors['titulo'])}
                            helperText={formik.touched['titulo'] && formik.errors['titulo']}
                        />
                        
                        <TextField
                            fullWidth
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            size="small"
                            multiline={true}
                            value={formik.values['descricao']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['descricao'] && Boolean(formik.errors['descricao'])}
                            helperText={formik.touched['descricao'] && formik.errors['descricao']}
                        />

                        <Box>
                            <CustomImagePicker
                                extensions={['jpg', 'jpeg', 'png']}
                                dimensions={{
                                    minWidth: 100,
                                    // maxWidth: 500,
                                    minHeight: 100, 
                                    // maxHeight: 500
                                }}
                                changeHandler={ imagePickerChangeHandler }
                                what="a imagem de capa"
                            />
                        </Box>

                    </div>

                    <Button
                        text="Salvar o registo"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={formik.submitForm}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}