
import "./style.css"

export default function Alert({message, severity}) {
    return (
        <div id="alert-component" className={severity}>
            <span className="material-symbols-outlined">error</span>
            <p>{message}</p>
        </div>
    )
}