
import React, {useState, useContext} from "react";

import "./style.css"
import { AuthAdminContext } from "../../../../contexts/auth-admin";
import AppLogo from "./../../../../components/app-logo"

import { Divider, TextField } from '@mui/material';
import Button from "../../components/button";
import Alert from "../../../../components/alert";
import Loading from "./../../../../components/loading"

export default function AdminLoginPage() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)

    const [loading, setLoading] = useState(false)

    const {login} = useContext(AuthAdminContext)


    const submitHandler = async (e) => {
        e.preventDefault()

        setLoading(true)
        setError(null)

        try {
            await login(email, password)

            setLoading(false)
        } catch(error) {
            const reason = JSON.parse(error)

            if (reason.code == 401) {
                setError({message: "Credenciais inválidas"})
                setLoading(false)
                return
            }

            setError({message: `Ooops! Erro inesperado. Tente novamente`})
            setLoading(false)
        }
    }

    return (
        <div id="admin-login-page">

            <div
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    padding: ".5rem",
                    display: "grid",
                    placeItems: "center"
                }}
                className="app-logo-wrapper"
            >
                <AppLogo fontSize="1.5rem" />
            </div>

            <div>
                <h2
                    style={{
                        textAlign: "center"
                    }}
                >
                    Login
                </h2>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        fontWeight: "600",
                        fontSize: "12px"
                    }}
                >
                    Entre com o seu e-mail e password
                </p>

                <Divider />

                <div
                    style={{
                        marginTop: "10px"
                    }}
                >
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!loading && <Loading text="Verificando as credenciais..." gifSize="15px"  />}
                </div>
                

                <form
                    style={{
                        pointerEvents: `${loading ? "none" : "auto"}`,
                        marginTop: "15px"
                    }}
                >
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="E-mail"
                        size="small"
                        placeholder="email@exemplo.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            marginBottom: "10px",
                        }}
                    />
                    
                    <TextField
                        fullWidth
                        type="password"
                        id="password"
                        name="password"
                        label="Password"
                        size="small"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                            marginBottom: "10px"
                        }}
                    />

                    <Button
                        text="Entrar"
                        googleIconName="login"
                        crudOperation="create"
                        clickHandler={submitHandler}
                        size="big"
                        fullWidth={true}
                        primary={true}
                        type="submit"
                        hideIcon={true}
                    />
                </form>
               
            </div>
        </div>
    )
}