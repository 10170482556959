
import { get, remove, postWithMultipartBody,} from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

export async function create (data) {
    await refreshJwtIfHasExpired()

    const {titulo, descricao, id_fotonovela, img_capa_base64} = data

    const file = img_capa_base64 ? DataURIToBlob(img_capa_base64) : null
    const formData = new FormData();
    formData.append('titulo', titulo)
    formData.append('descricao', descricao)
    formData.append('id_fotonovela', id_fotonovela)
    if (file) {
        formData.append('img_capa', file) 
    }

    const configsModified = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    }
    
    const endpoint = "/capitulos"
    return await postWithMultipartBody(endpoint, formData, configsModified)
   
}

export async function update (data) {
    await refreshJwtIfHasExpired()

    const {id, titulo, descricao, img_capa_base64} = data

    const file = img_capa_base64 ? DataURIToBlob(img_capa_base64) : null
    const formData = new FormData();
    formData.append('titulo', titulo)
    formData.append('descricao', descricao)
    if (file) {
        formData.append('img_capa', file) 
    }

    const configsModified = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    }

    const endpoint = `/capitulos/${id}`

    return await postWithMultipartBody(endpoint, formData, configsModified)
   
}

export async function eliminate (id) {
    await refreshJwtIfHasExpired()

    const endpoint = `/capitulos/${id}`
    
    return await remove(endpoint, configs)
   
}

export async function getAllByIdFotonovela(idFotonovela) {

    await refreshJwtIfHasExpired()

    const endpoint = `/foto_novelas/${idFotonovela}/capitulos`

    const data = await get(endpoint, configs)

    return data
    
    
}

