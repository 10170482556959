import React, {useContext} from 'react';
import { Link, useLocation } from 'react-router-dom';

import {ClientPaginationContext} from "./../../../../contexts/client-pagination"

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Box } from '@mui/material';

export default function ClientPagination({count, route,}) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const currentPage = parseInt(query.get('pagina') || '1', 10);

  const { updatePage } = useContext(ClientPaginationContext)

  const onChangeHandler = (e, value) => {
    updatePage(value)
  }

  return (
    <Box
        sx={{
            "& .MuiPagination-ul": {
                justifyContent: "center",
            },
            padding: 0,
        }}
    >
        <Pagination
            page={currentPage}
            count={count}
            size="large"
            renderItem={(item) => (
                <PaginationItem
                component={Link}
                to={`${route}${item.page === 1 ? '' : `?pagina=${item.page}`}`}
                {...item}
                />
            )}
            onChange={onChangeHandler}
        />
    </Box>
    
  );
}