import axios from "axios";

export const baseURL = process.env.REACT_APP_API_BASE_URL

export const api = axios.create({
    baseURL: baseURL
});

export const get = async (endpoint, configs = {} ) => {
    try {

        const response =  await api.get(endpoint, configs)

        return response.data

    } catch (error) {

        return handleError(error)
    }
}

export const post = async (endpoint, data = {}, configs = {}) => {

    try {

        const response =  await api.post(endpoint, JSON.stringify(data), configs)

        return response.data

    } catch (error) {
        return handleError(error)
    }
    
}

export const postWithMultipartBody = async (endpoint, data, configs = {}) => {

    try {

        const response =  await api.post(endpoint, data, configs)

        return response.data

    } catch (error) {
        return handleError(error)
    }
    
}

export const patch = async (endpoint, data = {}, configs = {}) => {

    try {

        const response =  await api.patch(endpoint, JSON.stringify(data), configs)

        return response.data

    } catch (error) {
        return handleError(error)
    }
    
}

export const remove = async (endpoint, configs = {}) => {

    try {

        const response =  await api.delete(endpoint, configs)

        return response.data

    } catch (error) {
        return handleError(error)
    }
    
}

export const options = async (endpoint, configs = {}) => {

    try {

        await api.get(endpoint)

    } catch (error) {
        return handleError(error)
    }
    
}

const handleError = async (error) => {

    let reason

    if (error.response) {
        console.log("The request was made and the server responded with a status code that falls out of the range of 2xx")
        console.log(error.response.status)
        console.log(error.response.data)
        console.log(error.response.headers)

        reason = JSON.stringify( {
            code: error.response.status,
            message: error.response?.data?.error
        } ) 

    } else if (error.request) {
        console.log("The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js")
        console.log(error.request)

        reason = JSON.stringify( {
            code: 0,
            message: "Erro do servidor"
        } ) 

    
    } else {
        console.log("Something happened in setting up the request that triggered an Error")
        console.log(error.message)

        reason = JSON.stringify( {
            code: 0,
            message: error?.message
        } ) 

    }

    console.log(error?.config)

    return Promise.reject(reason)
}


