import CryptoJS from "crypto-js";

export function encrypt(data) {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(data, secretKey).toString();
}

export const decrypt = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8);   
}

export function saveEncryptedDataInLocalStorage(name, data) {

    const preparedData = typeof data === "string" ? data : JSON.stringify(data);

    const encrypted = encrypt(preparedData)

    localStorage.setItem(name, encrypted);
}

export const getDecryptedDataFromLocalStorage = (name) => {

    const encrypted = localStorage.getItem(name);

    if (!encrypted) return null;

    const decrypted = decrypt( encrypted )

    try {
        return JSON.parse(decrypted);
    } catch(error) {
        return decrypted;
    }
    
}


export function calculatePaginationTotalPages(totalRecords, itemsPerPage=3) {
    let totalPages = 1

    if (totalRecords > 0) {
        //pegar o valor absoluto do total_de_paginas/total_itens_a_exibir_por_pagina
        totalPages = (totalRecords / itemsPerPage).toFixed()

        // se existe uma parte decimal, implica mais uma pagina para os restantes itens
        if (!!String(totalRecords / itemsPerPage).split(".")[1]) {
            totalPages++
        }
    }

    return Number(totalPages)
}

export function calculateFotonovelaRating(avaliacoes) {
    if(avaliacoes.length === 0) return 0

    const rating = avaliacoes.reduce((previousValue, currentValue) => {
        return {valor_avaliacao: previousValue.valor_avaliacao + currentValue.valor_avaliacao }
    }, {valor_avaliacao: 0})

    return Math.round(
        rating.valor_avaliacao/(avaliacoes.length)
    )
}