import React, { useState, useEffect, useContext, } from 'react';

import "./../pages.css"
import {getAll} from "../../../../services/admin/subscritor-api"
import {AdminPaginationContext} from "../../../../contexts/admin-pagination"

import SearchField from "../../components/search-field"
import AdminPagination from "../../components/pagination"
import DataTable from "./data-table"
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';

export default function Subscritores () {

    const [subscritoresFound, setSubscritoresFound] = useState([])
    const [totalSubscritoresFound, setTotalSubscritoresFound] = useState(0)
    const [paginationCount, setPaginationCount] = useState(1)
    const [loadingData, setLoadingData] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}
    const [searchField, setSearchField] = useState("")

    const {page} = useContext(AdminPaginationContext)

    useEffect(function(){
        fetchData()
    }, [page])

    const fetchData = async () => {
 
        setLoadingData(true)
        setError(null)

        try {
            const {subscritores, total} =  await getAll(10, page, searchField)
            setSubscritoresFound(subscritores)

            setTotalSubscritoresFound(total)

            setPaginationCount(
                total > 0 ? Math.round( total / 10) + 1  : 1
            )

            setLoadingData(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingData(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingData(false)

        }
        
    }

    return (
        <div id="subscritores-page" className="page">
            <h1>Subscritores</h1>
            <div>
                <div>
                    <SearchField
                        placeholder="Pesquisar pelo email..."
                        onClickHandler = {fetchData}
                        onChangeHandler = {(e) => setSearchField(e.target.value)}
                    />
                </div>
                <>
                    {
                        loadingData
                        && <div
                            style={{
                                backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                boxShadow: "10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1)"
                            }}  
                        >
                                <Loading text="Carregando os dados..." />
                            </div>
                    }
                    {!!error && <Alert message={error.message} severity="error" />}
                </>
                {
                    !loadingData && Boolean(error) === false
                    ? (
                        <DataTable
                            data = {subscritoresFound}
                        />
                    )
                    : null
                }

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    {
                        !loadingData && Boolean(error) === false
                            ? <>
                                <span
                                    style={{marginRight: "20px", fontSize: "12px", fontWeight: "500"}} 
                                >
                                    {`${ subscritoresFound.length } de ${totalSubscritoresFound} registos encontrados`}
                                </span>
                                
                                <AdminPagination
                                    count={ paginationCount === 0 ? 1 : paginationCount }
                                    route="/painel-administrador/subscritores"
                                />
                            </>
                            : null
                    }

                </div>
            </div>
        </div>
    )
}