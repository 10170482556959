import "./style.css"

const Generos = ({generos = []}) => {
    return (
        <ul id="generos" className="flex-row" >
            {
                generos.map(genero => {
                    return (
                        <li
                            key={genero}
                            style={{display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px"}}
                        >
                            {genero}
                        </li>
                    )
                })
            }
        </ul>
    )
}


export default Generos