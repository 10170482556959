import React, {useContext, useState} from 'react';

import "./style.css"
import {AuthClientContext} from "./../../../contexts/auth-client"

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IconBtn from '../../../components/icon-btn';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FnButton from "./../components/fn-button"
import AlterPasswordFormDialog from "./alter-password-form-dialog"
import AlterPersonalDataFormDialog from "./alter-personal-data-form-dialog"


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientUserProfile({isOpen = false, handleClose}) {

  const {user} = useContext(AuthClientContext)
  const [alterPasswordFormOpen, setAlterPasswordFormOpen] = useState(false)
  const [alterPersonalDataFormOpen, setAlterPersonalDataFormOpen] = useState(false)

  return (
    <>
      {
        alterPasswordFormOpen
        && <AlterPasswordFormDialog
          closeFormHandler={() => setAlterPasswordFormOpen(false)}
          afterSubmitHandler={() => null}
          initialValues={{
            id: user.id
          }}
        />
      }
      {
        alterPersonalDataFormOpen
        && <AlterPersonalDataFormDialog
          closeFormHandler={() => setAlterPersonalDataFormOpen(false)}
          afterSubmitHandler={() => null}
          initialValues={{
            id: user.id,
            primeiro_nome: user.primeiroNome,
            ultimo_nome: user.ultimoNome
          }}
        />
      }
      <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <div id="userprofile-modal-wrapper">
            <div id="userprofile-modal-header">
              <h1>Meu Perfil</h1>
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "5%",

                }}
              >
                <IconBtn googleIconTag={<span className="material-symbols-outlined" style={{color: "#dbd9d9"}}>close</span>} onClickHandler={handleClose} />
              </div>
            </div>
            <div id="userprofile-modal-content">
                <div>
                  <span className="material-symbols-outlined">account_circle</span>
                  <h1>{`${user.primeiroNome} ${user.ultimoNome}`}</h1>
                  <p>{user.email}</p>
                </div>

                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>Dados pessoais</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <FnButton label="Editar" variant="primary" size="big" handleClick={() => setAlterPersonalDataFormOpen(true)}  />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>Password</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>

                        <FnButton label="Alterar" variant="primary" size="big"   handleClick={() => setAlterPasswordFormOpen(true)}  />

                        </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
            
            </div>
          </div>
        
        </Dialog>
    </>
      
  );
}
