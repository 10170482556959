import React, {useState} from "react"

import "./style.css"
import { baseURL } from "../../../../../services/api"
import ViewFotonovela from "../../../../view-fotonovela"

const UltimaVisualizacao = ({ ultimaVisualizacao, subscricaoIsAtiva, refreshUltimaVisualizacao, idSubscritor }) => {

    const [viewFotonovela, setViewFotonovela] = useState(false)

    const openViewFotonovela = () => {
        setViewFotonovela(true)
    }
   
    const closeViewFotonovela = () => {
        setViewFotonovela(false)

        refreshUltimaVisualizacao()
    }

    const handleClick = () => {
        if (subscricaoIsAtiva) {
            openViewFotonovela()
        }
    }

    const bg = `${baseURL}/uploads/fotonovelas/${ultimaVisualizacao.fotonovela.id}/${ultimaVisualizacao.fotonovela.img_capa}?nocache=${Date.now() * Math.random()}`

    return (
        <>
            {
                viewFotonovela 
                    ? (<ViewFotonovela
                        isOpen={viewFotonovela}
                        handleClose={closeViewFotonovela}
                        fotonovela={ultimaVisualizacao.fotonovela}
                        inicialChapterIndex={ultimaVisualizacao.chapterIndex}
                        inicialSceneIndex={ultimaVisualizacao.sceneIndex}
                        idSubscritor={idSubscritor}
                    />) : ""
            }

            <div
                id="ultima-visualizacao"
                className="flex-row"
                style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
                onClick={handleClick}
            >
                <div
                    style={{zIndex: 2}}
                    className="content flex-column"
                >
                    <div className="description">
                        <h1>{ultimaVisualizacao.fotonovela.titulo}</h1>
                        <p>{`${ultimaVisualizacao.capitulo.titulo} - Cena ${ultimaVisualizacao.sceneIndex + 1}`}</p>
                    </div>
                    <span className="material-symbols-outlined">restart_alt</span>
                    <p className="action-description">Clique para retomar</p>
                </div>
            </div>
        </>
        
    )
}

export default UltimaVisualizacao