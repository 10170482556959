import AdminSidebar from "./sidebar"
import AdminMain from "./main"

import "./style.css"

const AdminHome = () => {

    return (
        <div id="admin-home">
            <AdminSidebar />
            <AdminMain />
        </div>
    )
}

export default AdminHome