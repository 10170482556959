

import Footer from "./footer"
import Header from "./header"
import Lancamentos from "./lancamentos"
import Planos from "./planos"
import Statistic from "./statistic"
import Testemunho from "./testemonial"

const LangingPage = () => {

    return (
        <>
            <Header />
            <Statistic />
            <Lancamentos />
            <Planos />
            <Testemunho />
            <Footer />
        </>
    )
}

export default LangingPage 