
import { get, post, remove, postWithMultipartBody, } from "../api"

import {refreshJwtIfHasExpired} from "./subscritor-api"

const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

export async function getAll() {

    await refreshJwtIfHasExpired()

    const endpoint = "/planos"

    const configsModified = {
        ...configs, params: {
            limit: 1000,
            is_available: true
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}

