import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { create } from "../../../../services/admin/usuario-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import Switch from '@mui/material/Switch';
import { Box, Divider, TextField } from '@mui/material';

const validationSchema = Yup.object({
    "primeiro_nome": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(15, "15 caracteres no máximo")
        .required("Campo obrigatório"),
    "ultimo_nome": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(15, "15 caracteres no máximo")
        .required("Campo obrigatório"),
    email: Yup
        .string()
        .email("Email inválido")
        .required("Campo obrigatório"),
    password: Yup
        .string()
        .min(6, "6 caracteres no mínimo")
        .required("Campo obrigatório")
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function UsuarioCreateFormDialog({closeFormHandler, afterSubmitHandler}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()

    }

    const submitForm = async (values) => {
        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await create(values)

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {
            "primeiro_nome": "",
            "ultimo_nome": "",
            email: "",
            password: "",
            "is_enabled": true
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Adicionar novo usuário
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                            width: "400px"
                        }}
                    >
                        <TextField
                            fullWidth
                            id="primeiro_nome"
                            name="primeiro_nome"
                            label="Primeiro Nome"
                            size="small"
                            value={formik.values['primeiro_nome']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['primeiro_nome'] && Boolean(formik.errors['primeiro_nome'])}
                            helperText={formik.touched['primeiro_nome'] && formik.errors['primeiro_nome']}
                        />
                    
                        <TextField
                            fullWidth
                            id="ultimo_nome"
                            name="ultimo_nome"
                            label="Ultimo Nome"
                            size="small"
                            value={formik.values['ultimo_nome']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['ultimo_nome'] && Boolean(formik.errors['ultimo_nome'])}
                            helperText={formik.touched['ultimo_nome'] && formik.errors['ultimo_nome']}
                        />
                        
                    
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Informe o email"
                            size="small"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />

                        <TextField
                            fullWidth
                            type="password"
                            id="password"
                            name="password"
                            label="Password"
                            size="small"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />

                        <Box>
                            <label
                                htmlFor="is_enabled"
                                style={{fontWeight: "600", fontSize: "12px"}}
                            >
                                {formik.values['is_enabled'] ? "Activo" : "Inactivo"}
                            </label>
                            <Switch
                                id="is_enabled"
                                name="is_enabled"
                                checked={formik.values['is_enabled']}
                                onChange={formik.handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Box>
                    </div>

                    <Button
                        text="Salvar o registo"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={formik.submitForm}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}