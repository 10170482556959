import React, { useState, useContext, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import "./style.css"
import { AdminSidebarContext } from "../../../contexts/admin-sidebar"
import AppLogo from "../../../components/app-logo"
import { encrypt, decrypt, saveEncryptedDataInLocalStorage, getDecryptedDataFromLocalStorage } from "../../../utils"

export default function AdminSidebar(){

    const [activedLink, setActivedLink] = useState( "dashboard" )

    const navigate = useNavigate()

    const {adminSidebarIsVisible, toggleAdminSidebar} = useContext(AdminSidebarContext)

    useEffect(() => {

        const currentPage = getDecryptedDataFromLocalStorage("fn_current-admin-page")

        if (currentPage) {
            setActivedLink( currentPage )
            navigate(`/painel-administrador/${ currentPage }`)
        }

    }, [])

    const linkClickHandler = (page) => {

        setActivedLink( page )

        saveEncryptedDataInLocalStorage("fn_current-admin-page", page)

        toggleAdminSidebar()
    }

    return (
        <div id="admin-sidebar" className={`${adminSidebarIsVisible ? "visible" : ""}`}>
            <div id="admin-sidebar-header">
                <AppLogo to="/painel-administrador" fontSize="1.5rem" />
            </div>
            <ul>
                    <li className = {activedLink === "dashboard" ? "active" : ""}>
                        <Link
                            to="/painel-administrador"
                            className="flex-row"
                            onClick={() => linkClickHandler("dashboard")}
                        >
                            <span className="material-symbols-outlined">dashboard</span>
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    <span className="group-title">Produtos</span>
                    <li className = {activedLink === "fotonovelas" ? "active" : ""}>
                        <Link
                            to="/painel-administrador/fotonovelas"
                            className="flex-row"
                            onClick={() => linkClickHandler("fotonovelas")}
                        >
                            <span className="material-symbols-outlined">video_camera_back</span>
                            <span>Fotonovelas</span>
                        </Link>
                    </li>

                    <li className = {activedLink === "planos" ? "active" : ""}>
                        <Link
                            to="/painel-administrador/planos"
                            className="flex-row"
                            onClick={() => linkClickHandler("planos")}
                        >
                            <span className="material-symbols-outlined">verified</span>
                            <span>Planos</span>
                        </Link>
                    </li>
                    <li className = {activedLink === "periodos" ? "active" : ""}>
                        <Link
                            to="/painel-administrador/periodos"
                            className="flex-row"
                            onClick={() => linkClickHandler("periodos")}
                        >
                            <span className="material-symbols-outlined">schedule</span>
                            <span>Períodos</span>
                        </Link>
                    </li>

                    <span className="group-title">Subscrições</span>
                    <li className = {activedLink === "subscritores" ? "active" : ""}>
                        <Link
                            to="/painel-administrador/subscritores"
                            className="flex-row"
                            onClick={() => linkClickHandler("subscritores")}
                        >
                            <span className="material-symbols-outlined">people</span>
                            <span>Subscritores</span>
                        </Link>
                    </li>


                    <span className="group-title">Segurança</span>
                    <li className = {activedLink === "usuarios" ? "active" : ""}>
                        <Link
                            to="/painel-administrador/usuarios"
                            className="flex-row"
                            onClick={() => linkClickHandler("usuarios")}
                        >
                            <span className="material-symbols-outlined">lock</span>
                            <span>Usuários</span>
                        </Link>
                    </li>
                </ul>
        </div>
    )
}
