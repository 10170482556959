import "./style.css"

export default function SearchField ({
        placeholder = "Pesquisar...",
        onClickHandler,
        onChangeHandler
    }) {
    return (
        <div id="admin-search-field">
            <input
                type="text"
                placeholder={placeholder}
                onChange={onChangeHandler}
            />
            <span className="material-symbols-outlined" onClick={onClickHandler}>sync</span>
        </div>
    )
}