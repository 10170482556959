import React, {useState} from "react"

import "./cena.css"
import ContextMenu from "../../components/context-menu"
import { baseURL } from '../../../../services/api';

export default function Cena ({idFotonovela, data, openUpdateCenaForm, openDeleteCenaForm}) {

    const [anchorElementToClickCapitulo, setAnchorElementToClickCapitulo] = useState(null);

    const rightButtonClickHandler = (e) => {
        setAnchorElementToClickCapitulo(e.currentTarget);
    }

    const contextMenuCloseHander = (e) => {
        setAnchorElementToClickCapitulo(null);
    }

    const contextMenuHandler = (e) => {
        e.preventDefault()

        rightButtonClickHandler(e)
    }

    const contextMenuItens = [
        {
            name: "Editar",
            clickHandler: () => {

                openUpdateCenaForm(data)
                contextMenuCloseHander()
            }
        },
        {
            name: "Deletar",
            clickHandler: () => {

                openDeleteCenaForm(data)
                contextMenuCloseHander()
            }
        },
    ]

    return (
        <div id="cena-fotonovela-detalhes" onContextMenu={contextMenuHandler} >
            <>
                <ContextMenu
                    anchorEl={anchorElementToClickCapitulo}
                    handleClose={contextMenuCloseHander}
                    menuItens={contextMenuItens}
                />
            </>
            <img
                src={`${baseURL}/uploads/fotonovelas/${idFotonovela}/${data.id_capitulo}/${data.img_cena}?nocache=${Date.now() * Math.random()}`}
                alt={data.img_cena}
                width="80px"
                height="100%"
                style={{objectFit: "cover"}}
            />

        <div className="bg"></div>
            
        </div>
    )
}