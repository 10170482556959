import React, {useState, useEffect} from 'react';

import "./style.css"
import { baseURL } from '../../services/api';
import { saveEncryptedDataInLocalStorage } from '../../utils';
import { addVisualizacao } from '../../services/client/capitulo';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IconBtn from "./../../components/icon-btn"
import Loading from "./../../components/loading"
import FeedbackFormDialog from './feedback-form-dialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewFotonovela({
  isOpen = false,
  handleClose,
  fotonovela,
  inicialChapterIndex = 0,
  inicialSceneIndex = 0,
  idSubscritor
}) {

  const [currentChapter, setCurrentChapter] = useState(fotonovela.capitulos[inicialChapterIndex])
  const [currentChapterIndex, setCurrentChapterIndex] = useState(inicialChapterIndex)
  const [currentScene, setCurrentScene] = useState(currentChapter.cenas[inicialSceneIndex])
  const [currentSceneIndex, setCurrentSceneIndex] = useState(inicialSceneIndex)
  const [savingVisualizacaoCapitulo, setSavingVisualizacaoCapitulo] = useState(false)
  const [fimFotonovela, setFimFotonovela] = useState(false)
  const [feedbackFormDialogOpen, setFeedbackFormDialogOpen] = useState(false)
  const [error, setError] = useState(null)

  const [leftBtnHidden, setLeftBtnHidden] = useState(false)
  const [rightBtnHidden, setRightBtnHidden] = useState(false)

  useEffect( () => {

    if (
      currentChapterIndex === 0
      && currentSceneIndex === 0
    ) {
      setLeftBtnHidden(true)
    } else {
      setLeftBtnHidden(false)
    }
   

    if (
      currentChapterIndex === fotonovela.capitulos.length - 1
      && currentSceneIndex === currentChapter.cenas.length - 1 
    ) {
      setFimFotonovela(true)
    } else {
      setRightBtnHidden(false)
    }


  }, [currentSceneIndex, currentChapterIndex] )

  const nextScene = async () => {
    if (
      currentSceneIndex === currentChapter.cenas.length - 1 
    ) {
      // salvar a visualizacao do capitulo anterior
      await saveVisualizacao()

      if (fimFotonovela) {
        setRightBtnHidden(true)

        setFeedbackFormDialogOpen(true)

        return
      }

      // ir para o proximo capitulo
      setCurrentChapter(fotonovela.capitulos[currentChapterIndex + 1])
      setCurrentChapterIndex(currentChapterIndex + 1)
      setCurrentScene( fotonovela.capitulos[currentChapterIndex + 1].cenas[0] )
      setCurrentSceneIndex( 0 )

      return
    }

    // ir para a proxima cena
    setCurrentSceneIndex( currentSceneIndex + 1 )
    setCurrentScene(currentChapter.cenas[currentSceneIndex + 1])


    // salvar a ultima visualizacao no local storage
    const ultimaVisualizacao = {
      idSubscritor: idSubscritor,
      fotonovela: fotonovela,
      capitulo: currentChapter,
      cena: currentScene,
      chapterIndex: currentChapterIndex,
      sceneIndex: currentSceneIndex
    }
    saveEncryptedDataInLocalStorage("fn_last_episode_viewed", ultimaVisualizacao)
                  
  }
  
  const previousScene = () => {

    setFimFotonovela(false)

    if (
      currentSceneIndex === 0
    ) {

      // ir para o capitulo anterior
      setCurrentChapter(fotonovela.capitulos[currentChapterIndex - 1])
      setCurrentChapterIndex(currentChapterIndex - 1)
      setCurrentScene( fotonovela.capitulos[currentChapterIndex - 1].cenas[0] )
      setCurrentSceneIndex( fotonovela.capitulos[currentChapterIndex - 1].cenas.length - 1 )

      return
    }

    // ir para cena anterior
    setCurrentSceneIndex( currentSceneIndex - 1 )
    setCurrentScene(currentChapter.cenas[currentSceneIndex - 1])
  }

  const saveVisualizacao = async () => {
 
    setSavingVisualizacaoCapitulo(true)
    setError(null)

    try {
        await addVisualizacao( currentChapter.id, idSubscritor  )

        setSavingVisualizacaoCapitulo(false)

    } catch (error) {
        const reason = JSON.parse(error)

        if (reason.code !== 400) {
          setSavingVisualizacaoCapitulo(false)
            setError({message: `Ooops! Erro inesperado`})

            return
        }

        setError({message: reason?.message})
        setSavingVisualizacaoCapitulo(false)

    }
    
}

const closeFeedbackForm = () => {
  // ir para cena anterior
  setCurrentSceneIndex( currentSceneIndex - 1 )
  setCurrentScene(currentChapter.cenas[currentSceneIndex - 1])

  // fechar o form de feedback
  setFeedbackFormDialogOpen(false)
}

const afterSubmitHandler = () => {
  // fechar o form de feedback
  setFeedbackFormDialogOpen(false)

  // fetchar a view fotonovela
  handleClose()
}

  return (
    <>
      {
        feedbackFormDialogOpen && <FeedbackFormDialog idSubscritor={idSubscritor} idFotonovela={fotonovela.id} closeFormHandler={closeFeedbackForm} afterSubmitHandler={afterSubmitHandler} />
      }
      <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <div id="modal-wrapper">
            <div id="modal-header">
              <h1>{fotonovela.titulo}</h1>
              <h2>{currentChapter.titulo}</h2>
              <h3>{(currentSceneIndex + 1) + "/" + currentChapter.cenas.length}</h3>
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "5%",

                }}
              >
                <IconBtn googleIconTag={<span className="material-symbols-outlined" style={{color: "#dbd9d9"}}>close</span>} onClickHandler={handleClose} />
              </div>
            </div>
            <div id="modal-content" className="flex-row">
            
              <div className="left" >
                <a
                  onClick={previousScene}
                  className={`
                    nav-btn left
                    ${ leftBtnHidden || savingVisualizacaoCapitulo ? "hidden" : "" }
                  `}>
                    <span className="material-symbols-outlined">chevron_left</span>
                </a>
              </div>
              <div className="center">
                {
                  savingVisualizacaoCapitulo
                    ? <Loading />
                    : <img src={`${baseURL}/uploads/fotonovelas/${fotonovela.id}/${currentChapter.id}/${currentScene.img_cena}?nocache=${Date.now() * Math.random()}`} />
                }
                
              </div>
              <div className="right">
                <a
                  onClick={nextScene}
                  className={`
                    nav-btn right
                    ${ rightBtnHidden || savingVisualizacaoCapitulo ? "hidden" : "" }
                  `}
                >
                    <span className="material-symbols-outlined">chevron_right</span>
                </a>
              </div>
            </div>
          </div>
        
        </Dialog>
    </>
     
  );
}
