import React from "react"

import "./style.css"

const NotificationsBox = ({show = false}) => {
    return (
        <div id="notifications-box" className={`${show ? "show" : ""}`}>
            <div className="header">
                <h1>Notifications</h1>
            </div>
            <div className="items flex-column">
                <a href="#" className="flex-column">
                    <span className="title">Pagammento aceite</span>
                    <span className="date">01/Ago/2020</span>
                </a>
                <a href="#" className="flex-column">
                    <span className="title">Novo lançamento realizado</span>
                    <span className="date">01/Set/2022</span>
                </a>
                <a href="#" className="flex-column">
                    <span className="title">Novo lançamento realizado</span>
                    <span className="date">30/Set/2022</span>
                </a>
                <a href="#" className="flex-column">
                    <span className="title">Subscrição expirada</span>
                    <span className="date">30/Dez/2022</span>
                </a>
                <a href="#" className="flex-column">
                    <span className="title">Novo capitulo da fotonovela xyz Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi porro recusandae, omnis, amet quasi, quod eos maiores molestiae modi deleniti officiis magni earum exercitationem tenetur libero. Autem aut eligendi alias.</span>
                    <span className="date">21/Jan/2023</span>
                </a>
                <a href="#" className="flex-column">
                    <span className="title" style={{textAlign: "center",}}>Ver todos</span>
                </a>
            </div>
        </div>
    )
}


export default NotificationsBox