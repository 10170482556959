
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import AppLogo from "../../../components/app-logo";
import "./style.css"
import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';

const Topbar = () => {

    const [showMenu, setShowMenu] = useState(false)

    const isSmallDevice = useMediaQuery(json2mq({
        maxWidth: 480
    }))
    
    const handleShowMenuButtonClick = (e) => {
        e.preventDefault()

        setShowMenu(!showMenu)
    }

    const handleCloseMenuButtonClick = (e) => {
        e.preventDefault()

        setShowMenu(!showMenu)
    }

    const handleMenuItemClick = (e) => {
        setShowMenu(!showMenu)
    }

    return (
        <div
            id="lp-topbar"
        >
            <div><AppLogo fontSize={`${isSmallDevice ? "1.6rem" : "2rem"}`} /></div>
            <div
                className={`nav ${showMenu ? "show" : ""}`}
            >
                <ul>
                    <li>
                        <HashLink
                            to="/"
                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                            onClick={handleMenuItemClick}
                        >
                            Home
                        </HashLink>
                    </li>
                    <li>
                        <HashLink
                            to="/#lp-lancamentos"
                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                            onClick={handleMenuItemClick}
                        >
                            Lançamentos
                        </HashLink>
                    </li>
                    <li>
                        <HashLink
                            to="/#lp-planos"
                            scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                            onClick={handleMenuItemClick}
                        >
                            Planos
                        </HashLink>
                    </li>
                </ul>
                <div className="action-btns">
                    <Link
                        to="/login"
                        onClick={handleMenuItemClick}
                    >
                        Entrar
                    </Link>
                    <Link
                        to="/registrar-se"
                        className="primary"
                        onClick={handleMenuItemClick}
                    >
                        Registrar-se
                    </Link>
                </div>
                <div className="close-menu-btn-wrapper">
                    <a
                        href="#"
                        onClick={handleCloseMenuButtonClick}
                    >
                        <span className="material-symbols-outlined">expand_less</span>
                    </a>
                </div>
            </div>
            <div className="menu-btn-wrapper">
                <a
                    href="#"
                    className="menu"
                    onClick={handleShowMenuButtonClick}
                >
                    <span className="material-symbols-outlined">menu</span>
                </a>
            </div>
            
        </div>
    )



}

export default Topbar