
import SectionTitle from "../global/section-title"
import "./style.css"

const Testemunho = () => {
    return (
        <div id="lp-testemonials">
            <SectionTitle title="Testemunhos" subtitle="O que nossos clientes dizem de nôs" />
            <div
                className="testemonials-wrapper"
            >
                <div>
                    <p>As estórias são bué cativantes! Aguardando por mais lançamentos...</p>
                    <div>
                        <h3>Perivaldo André</h3>
                    </div>
                </div>
                <div>
                    <p>Amei a ideia de termos estórias contadas em sequência de fotografias com ilustrações e textos bastante cativantes. Desejo muito força a equipa toda!!</p>
                    <div>
                        <h3>Luisa Pacheco</h3>
                    </div>
                </div>
                <div>
                    <p>Isso é bom, temos gente criativa na banda</p>
                    <div>
                        <h3>Pedro da Silva</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Testemunho