import React, {useState} from "react"

import "./capitulo.css"
import ContextMenu from "../../components/context-menu"
import { baseURL } from '../../../../services/api';

export default function Capitulo ({idFotonovela, data, selectHandler, openUpdateCapituloForm, openDeleteCapituloForm, openAdicionarCenaForm, openViewFotonovelaTest}) {
    const [anchorElementToClickCapitulo, setAnchorElementToClickCapitulo] = useState(null);

    const rightButtonClickHandler = (e) => {
        setAnchorElementToClickCapitulo(e.currentTarget);
    }

    const closeContextMenu = (e) => {
        setAnchorElementToClickCapitulo(null);
    }

    const contextMenuHandler = (e) => {
        e.preventDefault()

        const radio = document.getElementById(e.target.id)
      
        if (!e.target.id) return

        if (!radio.checked) {
            return
        }

        rightButtonClickHandler(e)
    }

    const radioChangeHandler = (e) => {
        selectHandler(e, data.id)
    }

    const contextMenuItens = [
        {
            name: "Editar",
            clickHandler: () => {
                openUpdateCapituloForm()
                closeContextMenu()
            }
        },
        {
            name: "Deletar",
            clickHandler: () => {
                openDeleteCapituloForm()
                closeContextMenu()
            }
        },
        {
            name: "Adicionar cenas",
            clickHandler: () => {
                openAdicionarCenaForm()
                closeContextMenu()
            }
        },
        {
            name: "Visualizar este",
            clickHandler: () => {
                openViewFotonovelaTest()
                closeContextMenu()
            }
        },
    ]

    return (
        <div id="capitulo-fotonovela-detalhes" onContextMenu={contextMenuHandler} >
            <>
                <ContextMenu
                    anchorEl={anchorElementToClickCapitulo}
                    handleClose={closeContextMenu}
                    menuItens={contextMenuItens}
                />
            </>
            <img
                src={`${baseURL}/uploads/fotonovelas/${idFotonovela}/${data.id}/${data.img_capa}?nocache=${Date.now() * Math.random()}`}
                alt={data.img_capa}
                width="120px"
                height="100%"
                style={{objectFit: "cover"}}
            />

            <div className="bg"></div>

            <input type="radio" name="radio-capitulo" id={data.id} onChange={radioChangeHandler } />
            
        </div>
    )
}



