
import { get, post, remove, postWithMultipartBody, } from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

export async function create (data) {
    await refreshJwtIfHasExpired()

    const {titulo, descricao, generos, imgCapaBase64} = data

    const file = imgCapaBase64 ? DataURIToBlob(imgCapaBase64) : null
    const formData = new FormData();
    formData.append('titulo', titulo)
    formData.append('descricao', descricao)
    formData.append('generos', generos)
    if (file) {
        formData.append('img_capa', file) 
    }

    const configsModified = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    }
    
    const endpoint = "/foto_novelas"
    return await postWithMultipartBody(endpoint, formData, configsModified)
   
}

export async function update (id, data) {
    await refreshJwtIfHasExpired()

    const {titulo, descricao, generos, imgCapaBase64} = data

    const file = imgCapaBase64 ? DataURIToBlob(imgCapaBase64) : null
    const formData = new FormData();
    formData.append('titulo', titulo)
    formData.append('descricao', descricao)
    formData.append('generos', generos)
    if (file) {
        formData.append('img_capa', file) 
    }

    const configsModified = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    }

    const endpoint = `/foto_novelas/${id}`

    return await postWithMultipartBody(endpoint, formData, configsModified)
   
}

export async function eliminate (id) {
    await refreshJwtIfHasExpired()

    const endpoint = `/foto_novelas/${id}`
    
    return await remove(endpoint, configs)
   
}

export async function getAll(limit, page, titulo="") {

    await refreshJwtIfHasExpired()

    const endpoint = "/foto_novelas"

    const configsModified = {
        ...configs, params: {
            limit,
            page,
            titulo,
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}


export async function createDemo (idFotonovela, data) {
    await refreshJwtIfHasExpired()
    
    const endpoint = `/foto_novelas/${idFotonovela}/demo`
    return await post(endpoint, data, configs)
   
}

export async function eliminateDemo (idFotonovela) {
    await refreshJwtIfHasExpired()
    
    const endpoint = `/foto_novelas/${idFotonovela}/demo`
    return await remove(endpoint, configs)
   
}

export async function getAllByIdPlano(idPlano) {

    await refreshJwtIfHasExpired()

    const endpoint = `/planos/${idPlano}/produtos`

    const data = await get(endpoint, configs)

    return data
}

export async function eliminateFromPlano(idPlano, idFotonovela) {

    await refreshJwtIfHasExpired()

    const endpoint = `/planos/${idPlano}/produtos/${idFotonovela}`

    const data = await get(endpoint, configs)

    return data
    
}