import React, {createContext, useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { api } from "../services/api"
import { signIn, verifyToken } from "../services/admin/usuario-api"
import {
    getDecryptedDataFromLocalStorage,
    saveEncryptedDataInLocalStorage
} from "../utils";

export const AuthAdminContext = createContext()

export const AuthAdminContextProvider = ({children}) => {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(function(){

        const loggedUser = getDecryptedDataFromLocalStorage("fn_user")
        const token = getDecryptedDataFromLocalStorage("fn_token")
        // const loggedUser = localStorage.getItem("user")
        // const token = localStorage.getItem("token")

        if (!loggedUser || !token) {
            logOut()
        }

        if ( loggedUser && token ) {

            (async () => {
                try {
                    
                    await verifyToken(token)

                    setUser( loggedUser )

                    api.defaults.headers.Authorization = `Bearer ${token}`

                    setLoading(false)
                    
                } catch (error) {
                    logOut()
                }
                

            })()

        }
        
    }, [])

    const login = async (email, password) => {

        try {

            const data = await signIn(email, password)

            const jwtDecoded = jwtDecode(data.jwt)
            const userData = jwtDecoded.data
            const token = data.jwt

            saveEncryptedDataInLocalStorage("fn_user", userData)
            saveEncryptedDataInLocalStorage("fn_token", token)
            // localStorage.setItem("user", JSON.stringify(userData))
            // localStorage.setItem("token", token)

            setUser(userData)

            api.defaults.headers.Authorization = `Bearer ${token}`

            navigate("/painel-administrador")
 
        } catch(error) {
            return Promise.reject(error)
        }

    }

    const logOut = () => {
        setLoading(false)

        localStorage.removeItem("fn_user")
        localStorage.removeItem("fn_token")
        localStorage.removeItem("fn_current-admin-page")
        localStorage.removeItem("fn_current-client-page")
        localStorage.removeItem("fn_current-fn")
        

        navigate("/painel-administrador/login")
    }

    return (
        <AuthAdminContext.Provider value={{signedIn: !!user, user, login, logOut, loading}}>
            {children}
        </AuthAdminContext.Provider>
    )
}