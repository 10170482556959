import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientPaginationContext } from '../../../contexts/client-pagination';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListIcon from '@mui/icons-material/List';
import FavoriteIcon  from '@mui/icons-material/Favorite';
import { Box } from '@mui/material';

const screens = [
    "/painel-usuario/fotonovelas",
    "/painel-usuario/fotonovelas/favoritos",
]

function FotonovelasTabs() {
    const [value, setValue] = React.useState(0);

    //quando mudar de tab, resetar a paginacao para 1
    const {updatePage} = useContext(ClientPaginationContext)

    const navigate = useNavigate()

    useEffect(() => {
        setValue(0)
        navigate(screens[0])
    }, [])
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      updatePage(1)

      navigate(screens[newValue])
    };
  
    return (
        <Box
            sx={{
                padding: "0 5%",
                "& .css-1nppm96-MuiButtonBase-root-MuiTab-root": {
                    minHeight: "40px",
                    fontWeight: "800"
                },
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon position tabs example"
                variant='fullWidth'
            >
                <Tab icon={<ListIcon />} iconPosition="start" label="Todos" />
                <Tab icon={<FavoriteIcon  />} iconPosition="start" label="Favoritos" />
            </Tabs>
        </Box>
      
    );
  }

export default FotonovelasTabs