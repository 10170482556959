import { Link } from "react-router-dom"
import "./style.css"

const img = require("../../../assets/jackson-david.png")

const Banner = () => {
    return (
        <div
            id="lp-banner">
            <div>
                <h1>
                    Veja as melhores fotonovelas produzidas em Angola
                </h1>
                <p
                    className="banner-details-text"
                    style={{
                        fontWeight: "500",
                        color: "#495057"
                    }}
                >
                Um subgênero da Literatura que ilustra uma narrativa utilizando em conjunto a fotografia e o texto verbal, como em quadradinhos da banda desenhada, onde cada quadradinho da sequência corresponde uma cena da história acompanhada da mensagem textual. Aqui passará não só fotonovelas nacionais, mas de outros países da lusofonia também. 
                </p>
                <div
                    className="registrar-wrappper"
                    style={{
                        marginTop: "3rem",
                        width: "100%",
                    }}
                >
                    <h4
                        style={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "800",
                            fontSize: "1.2rem",
                            color: "#1d3557"
                        }}
                    >
                        Registre-se
                    </h4>
                    <p
                         style={{
                            fontWeight: "500",
                            color: "#495057"
                        }}
                    >
                        Crie uma conta em poucos segundos e desfrute dos novos lançamentos
                    </p>
                    <div className="action-btns">
                        <Link to="/registrar-se" className="primary">Registrar-se</Link>
                        <Link
                            to="/login"
                        >
                            Entrar
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <img
                    src={img} alt=""   
                />
            </div>
        </div>
    )
}


export default Banner