import React from "react"
import {format} from "date-fns"

import "./style.css"
import { baseURL } from "../../services/api"

const calcExpirationDate = (initialDate, periodDays) => {
 
    const endDate = new Date(initialDate)
    endDate.setDate( initialDate.getDate() + periodDays )

    return endDate
}


const Subscricao = ({subscricao}) => {

    const handleImprimirFatura = () => {
    
        window.open(
            `${baseURL}/uploads/subscricoes/${subscricao.id}/recibo.pdf?nocache=${Date.now() * Math.random()}`,
            "_blank"
        )
    }
    
    const handleImprimirComprovate = () => {
    
        window.open(
            `${baseURL}/uploads/subscricoes/${subscricao.id}/${subscricao.comprovante}?nocache=${Date.now() * Math.random()}`,
            "_blank"
        )
    }

    const colorEstado = (estado) => {

        if (estado === "activo") return "green"
        if (estado === "em espera") return "orange"
        if (estado === "rejeitado") return "red"
        return "gray"
    }

    return (
        <article id="subscricao" className="flex-column">
            <section>
                <h1>{subscricao.plano.nome} </h1>
                <div>
                    {
                        subscricao.estado !== "em espera" &&
                        <div onClick={handleImprimirFatura}>
                            <span className="material-symbols-outlined">print</span>
                            <span>Recibo</span>
                        </div>
                    }
                   
                    <div onClick={handleImprimirComprovate}>
                        <span className="material-symbols-outlined">print</span>
                        <span >Comprovante</span>
                    </div>
                </div>
            </section>

            <div className="flex-row">
                <div className="flex-column">
                    <span>Submissão</span>
                    <span>
                        {
                            format (
                                new Date(subscricao.created_at),
                                "dd/MM/yyyy"
                            )
                        }
                    </span>
                </div>
                <div className="flex-column">
                    <span>Validade</span>
                    <span>
                        {
                            subscricao.inicio_validade
                                ? format (
                                        calcExpirationDate( new Date(subscricao.inicio_validade), subscricao.plano.periodo.qtde_dias ),
                                        "dd/MM/yyyy"
                                    )
                                : "-"
                        }
                    </span>
                </div>
                <div className="flex-column">
                    <span>Valor pago</span>
                    <span>{subscricao.valor_pago}</span>
                </div>
                <div className="flex-column">
                    <span>Moeda</span>
                    <span>{subscricao.moeda}</span>
                </div>
                <div className="flex-column">
                    <span>Cambio</span>
                    <span>{subscricao.cambio}</span>
                </div>
                <div className="flex-column">
                    <span>Estado</span>
                    <span
                        className={
                            `${ colorEstado(subscricao.estado) }`
                        }
                    >
                        {subscricao.estado}
                    </span>
                </div>
                
            </div>
        </article>
    )
}

export default Subscricao