import React, {useEffect, useState, useContext} from "react"
import json2mq from "json2mq"
import { useMediaQuery } from "@mui/material"

import "./style.css"
import Subscricao from "../../../components/subscricao"
import {getAllSubscricoes} from "./../../../services/client/subscritor-api"
import {getAll as getAllPlanos} from "./../../../services/client/plano-api"
import { AuthClientContext } from "./../../../contexts/auth-client"

import Btn from "./../../../components/btn"
import Loading from "../../../components/loading"
import SubscribeFormDialog from "../subscribe-form-dialog"
import FlatButton from "../../../components/flat-btn"

const Faturacao = () => {

    const {user} = useContext(AuthClientContext)

    const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)
    const [subscricoes, setSubscricoes] = useState([])
    const [loadingSubscricoes, setLoadingSubscricoes] = useState(false)
    const [planos, setPlanos] = useState([])
    const [loadingPlanos, setLoadingPlanos] = useState(false)
    const [error, setError] = useState(null)
    const [subscricaoAtiva, setSubscricaoAtiva] = useState(null)
    const [subscricaoEmEspera, setSubscricaoEmEspera] = useState(null)

    const matches = useMediaQuery(
        json2mq({
            maxWidth: 800
        })
    )

    useEffect(() => {
        
        fetchSubscricoes()

    },[])

    const fetchPlanos = async () => {
 
        setLoadingPlanos(true)
        setError(null)

        try {
            
            const {planos, total} =  await getAllPlanos()
            
            setPlanos(planos)

            setLoadingPlanos(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingPlanos(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingPlanos(false)

        }
        
    }

    const fetchSubscricoes = async () => {
 
        setLoadingSubscricoes(true)
        setError(null)

        try {
            await fetchPlanos()
            
            const {subscricoes} =  await getAllSubscricoes(user.id)
            
            setSubscricoes(subscricoes)

            if (subscricoes) {
                const subscricaoActived = subscricoes.filter( subscricao => subscricao.estado === "activo" )
                const subscricaoToValidate = subscricoes.filter( subscricao => subscricao.estado === "em espera" )

                if (subscricaoActived) {
                    setSubscricaoAtiva( subscricaoActived[0] )
                }

                if (subscricaoToValidate) {
                    setSubscricaoEmEspera( subscricaoToValidate[0] )
                }
            }

            setLoadingSubscricoes(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingSubscricoes(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingSubscricoes(false)

        }
        
    }
    
    return (
        <>
            {
                subscribeFormOpen && <SubscribeFormDialog planos={planos} loggedUser={user} closeFormHandler={() => setSubscribeFormOpen(false)} afterSubmitHandler={() => fetchSubscricoes()} />
            }
            <div id="faturacao">
            {
                    loadingSubscricoes
                        ? null
                        : (
                            <>
                            {
                                !!subscricaoAtiva === false
                                && matches === false
                                && !loadingSubscricoes
                                && !loadingPlanos
                                && !!subscricaoEmEspera === false
                                && <FlatButton onClickHandler={() => setSubscribeFormOpen(true)}  />
                            }
                            </>
                        )
                    
                }
                
                <div className="header">
                    <h1>Faturação</h1>
                    <small>Clique em nova subscrição para ativar um plano ao seu gosto</small>
                </div>
                <div className="tab-content">
                    {
                        subscricoes.length > 0 && !loadingSubscricoes && !loadingPlanos && !!subscricaoEmEspera === false &&
                        <div
                            style={{paddingTop: "10px"}}
                        >
                            {
                                !!subscricaoAtiva === false
                                && matches === true
                                && subscricoes.length > 0
                                && !loadingSubscricoes
                                && !loadingPlanos
                                && !!subscricaoEmEspera === false
                                && <Btn isPrimary={true} stretch={matches} text="+ Nova subscrição" borderRadius="20px" onClickHandler={() => setSubscribeFormOpen(true)} />
                            }
                        </div>
                    }
                    <div id="subscricoes">
                        {
                            loadingSubscricoes
                                ? (
                                    <div
                                        style={{paddingTop: "10rem"}}
                                    >
                                        <Loading text="Carregando as subscrições..." />
                                    </div>
                                )
                                :
                                    <>
                                        {
                                            subscricoes.map(subscricao => {
                                                return <Subscricao key={subscricao.id} subscricao={subscricao} />
                                            })
                                        }
                                        {
                                            subscricoes.length < 1 &&
                                           <div
                                                className="flex-column"
                                                style={{
                                                    justifyContent: "center",
                                                    gap:"1.5rem",
                                                    paddingTop: "10rem"
                                                }}
                                            >
                                                <div
                                                    className="flex-row"
                                                    style={{
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        gap:"3px",
                                                        color: "#bababa"
                                                    }}>
                                                    <span className="material-symbols-outlined" style={{color: "#bababa", fontSize: "15px"}}>
                                                        error
                                                    </span>
                                                    <small style={{color: "#bababa",}}>Nenhum subscrição encontrada</small>
                                                </div>
                                                {
                                                    !!subscricaoAtiva === false
                                                    && matches === true
                                                    && subscricoes.length < 1
                                                    && !loadingSubscricoes
                                                    && !loadingPlanos
                                                    && !!subscricaoEmEspera === false &&
                                                    <Btn isPrimary={true} stretch={false} text="+ Nova subscrição" borderRadius="20px" onClickHandler={() => setSubscribeFormOpen(true)} />
                                                }
                                            </div>
                                        }
                                    </>  
                        }
                    
                    </div>
                    
                </div>
            </div>
        </>
        
    )
}

export default Faturacao