import React, {useContext, useState} from "react"

import "./../data-table.css"

import StatusBadge from "../../components/status-badge"
import Button from "../../components/button"
import UsuarioUpdateFormDialog from "./update-form-dialog"
import UsuarioDeleteFormDialog from "./delete-form-dialog"

export default function UsuarioDataTable({data, loggedUser, afterSubmitHandler}) {
    const [updateFormOpen, setUpdateFormOpen] = useState(false)
    const [deleteFormOpen, setDeleteFormOpen] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState()

    const openUpdateFormHandler = (item) => {
        setSelectedRecord(item)
        setUpdateFormOpen(true)
    }

    const openDeleteFormHandler = (item) => {
        setSelectedRecord(item)
        setDeleteFormOpen(true)
    }

    return (
        <div className="admin-data-table">
            <table>
                <thead>
                    <tr
                        style={{backgroundColor: "#dfdcdc"}}
                    >
                       <th style={{width: "50px"}}>N/O</th>
                       <th>Nome</th>
                       <th>Sobrenome</th>
                       <th >Email</th>
                       <th style={{width: "100px"}}>Status</th>
                       {
                            loggedUser.is_super && <th className="btns-operacao">Operação</th>
                        }
                    </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => (
                        <tr key={item.id} >
                            <td>{index + 1}</td>
                            <td>{item.primeiro_nome}</td>
                            <td>{item.ultimo_nome}</td>
                            <td>{item.email}</td>
                            <td>
                                {
                                    <StatusBadge text={item.is_enabled ? "Activo" : "Inactivo"} status={item.is_enabled ? "green" : "gray"} />
                                }
                            </td>
                            {
                                loggedUser.is_super
                                    ? (
                                        loggedUser.id !== item.id
                                            ? (
                                                <td className="btns-operacao">
                                                    <Button
                                                        text="Alterar"
                                                        googleIconName="edit"
                                                        crudOperation="update"
                                                        primary={false}
                                                        size="small"
                                                        clickHandler={openUpdateFormHandler.bind(this, item)}
                                                    />
                                                    <Button
                                                        text="Deletar"
                                                        googleIconName="delete"
                                                        crudOperation="delete"
                                                        primary={false}
                                                        size="small"
                                                        clickHandler={openDeleteFormHandler.bind(this, item)}
                                                    />
                                                </td>
                                                )
                                                : <td></td>
                                    )
                                    : null
                            }
        
                        </tr>
                    ))
                }
                    
                </tbody>
            </table>
            {
                updateFormOpen && (
                        <UsuarioUpdateFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setUpdateFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            {
                deleteFormOpen && (
                        <UsuarioDeleteFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setDeleteFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            
        </div>
    )
}


