
import React, {useState} from "react"
import { useFilePicker } from 'use-file-picker';
import {FileSizeValidator} from "use-file-picker/validators"

import "./style.css"

import Alert from "../alert"
import Button from "../../scenes/admin/components/button";

export default function CustomFilePicker({extensions=["*"], changeHandler, what = "o ficheiro"}) {

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const { openFilePicker, filesContent, loading, errors, plainFiles, clear } = useFilePicker({
        readAs: 'DataURL',
        accept: extensions,
        multiple: false,
        validators: [
            new FileSizeValidator(({ maxFileSize: "2000000"  })) //2MB
        ],
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            // this callback is always called, even if there are errors
            // console.log('onFilesSelected', plainFiles, filesContent, errors);
        },
        onFilesRejected: ({ errors }) => {
            // this callback is called when there were validation errors
            // console.log('onFilesRejected', errors);
            setError(true)
            setErrorMessage(errors[0].reason)
        },
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
            // this callback is called when there were no validation errors
            // console.log('onFilesSuccessfullySelected', plainFiles, filesContent);
            changeHandler(filesContent[0].content)
        },
    });

    return (
        <div id="custom-file-picker" onClick={(e) => { openFilePicker(); setError(false); setErrorMessage("") }} >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "stretch"
                }}
            >
                <div >
                    <button type="button" >
                        <span className="material-symbols-outlined">cloud_upload</span>
                        <span>Clique para buscar {what}</span>
                        <small style={{color: "green"}}>{!!filesContent.length && filesContent[0].path}</small>
                    </button>
                </div>
                
            </div>
            
            {error && <Alert message={errorMessage} severity="error" />}
        </div>
    )
}