import React from "react";

import { Route, Routes } from "react-router-dom";

import LandingPage from "./scenes/landing-page";
import LoginClient from "./scenes/client/login";
import RegisterClient from "./scenes/client/register";
import UserPanel from "./scenes/client/user-panel";
import Home from "./scenes/client/home";
import Faturacao from "./scenes/client/faturacao";
import Fotonovelas from "./scenes/client/fotonovelas";
import Todos from "./scenes/client/fotonovelas/todos";
import Favoritos from "./scenes/client/fotonovelas/favoritos";
import DetalhesFotonovela from "./scenes/client/detalhes-fotonovela";
import ClientPrivateRoute from "./ClientPrivateRoute"

import NotFound from "./scenes/not-found-page";

import AdminHome from "./scenes/admin";
import AdminDashboard from "./scenes/admin/pages/dashboard";
import AdminUsuarios from "./scenes/admin/pages/usuarios";
import AdminLoginPage from "./scenes/admin/pages/login";
import AdminPrivateRoute from "./AdminPrivateRoute"
import AdminFotonovelas from "./scenes/admin/pages/fotonovelas"
import AdminFotonovelasDetalhes from "./scenes/admin/pages/fotonovela-detalhes"


import { AdminSidebarContextProvider } from "./contexts/admin-sidebar";
import {AdminPaginationContextProvider} from "./contexts/admin-pagination"
import { AuthAdminContextProvider } from "./contexts/auth-admin";
import { AuthClientContextProvider } from "./contexts/auth-client";
import Periodo from "./scenes/admin/pages/periodo";
import Planos from "./scenes/admin/pages/planos";
import AdminPlanoDetalhes from "./scenes/admin/pages/plano-detalhes";
import Subscritores from "./scenes/admin/pages/subscritores";
import AdminSubscritorDetalhes from "./scenes/admin/pages/subscritor-detalhes";
import AdminUserProfile from "./scenes/admin/pages/admin-user-profile";
import ConfirmationPage from "./scenes/client/register/confirmation-page";
import PasswordRecovery from "./scenes/client/password-recovery"
import ChangePasswordPage from "./scenes/client/password-recovery/change-password-page";
import { ClientPaginationContextProvider } from "./contexts/client-pagination";
import { ClientNavigationContextProvider } from "./contexts/client-navigation";

export const ProvidersForAdminRoutes = ({children}) => {
  return (
      <AdminSidebarContextProvider>
        <AdminPaginationContextProvider>
          <AuthAdminContextProvider>
            {children}
          </AuthAdminContextProvider>
        </AdminPaginationContextProvider>
      </AdminSidebarContextProvider>
  )
}

export const ProvidersForClientRoutes = ({children}) => {
  return (
    <ClientNavigationContextProvider>
      <ClientPaginationContextProvider>
        <AuthClientContextProvider>
          {children}
        </AuthClientContextProvider>
      </ClientPaginationContextProvider>
    </ClientNavigationContextProvider>
  )
}

function App() {

  return (
      <div
        className="app"
      >
  
        <Routes>
            <Route path="/" element={
                <LandingPage
            />} />

            <Route path="/login" element={
              <ProvidersForClientRoutes>
                <LoginClient /> 
              </ProvidersForClientRoutes>
            }/>

            <Route path="/registrar-se" element={ <RegisterClient /> } />
            <Route path="/confirmacao-conta/:id_subscritor" element={ <ConfirmationPage /> } />
            <Route path="/redefinir-password/:id_subscritor" element={ <ChangePasswordPage /> } />
            <Route path="/recuperacao-password" element={ <PasswordRecovery /> } />


            <Route path="/painel-usuario" element={
              <ProvidersForClientRoutes>
                <ClientPrivateRoute>
                  <UserPanel />
                </ClientPrivateRoute>
              </ProvidersForClientRoutes>
            }>
              <Route path="" element={ <Home /> } />
              
              <Route path="home" element={ <Home /> } />

              <Route path="faturacao" element={ <Faturacao /> } />

              <Route path="fotonovelas" element={ <Fotonovelas />}>
                <Route path="" element={<Todos />} />
                <Route path="favoritos" element={<Favoritos />} />
              </Route>
              
              <Route path="fotonovelas">
                <Route path=":id_fotonovela" element={<DetalhesFotonovela />} />
              </Route>

              <Route path="*" element={<NotFound text="A página solicitada está indisponível" />} />
            </Route>


            <Route path="/painel-administrador/login" element={
              <ProvidersForAdminRoutes> 
                <AdminLoginPage />
              </ProvidersForAdminRoutes>
            }/>

              <Route path="/painel-administrador" element={
                <ProvidersForAdminRoutes>
                    <AdminHome />
                </ProvidersForAdminRoutes>
              }>
                
                  <Route path="" element={
                        <AdminPrivateRoute>
                          <AdminDashboard />
                        </AdminPrivateRoute>
                  } />

                  <Route path="dashboard" element={
                          <AdminPrivateRoute>
                            <AdminDashboard />
                          </AdminPrivateRoute>
                  } />

                  <Route path="fotonovelas" element={
                      <AdminPrivateRoute>
                        <AdminFotonovelas />
                      </AdminPrivateRoute>
                  } />
                  
                  <Route path="fotonovelas/:id_fotonovela" element={
                      <AdminPrivateRoute>
                        <AdminFotonovelasDetalhes />
                      </AdminPrivateRoute>
                  } />
                  
                  <Route path="planos" element={
                        <AdminPrivateRoute>
                          <Planos />
                        </AdminPrivateRoute>
                  } />

                  <Route path="planos/:id_plano" element={
                      <AdminPrivateRoute>
                        <AdminPlanoDetalhes />
                      </AdminPrivateRoute>
                  } />

                  <Route path="periodos" element={
                        <AdminPrivateRoute>
                          <Periodo />
                      </AdminPrivateRoute>
                  } />
                 
                  <Route path="subscritores" element={
                        <AdminPrivateRoute>
                          <Subscritores />
                        </AdminPrivateRoute>
                  } />
                  
                  <Route path="subscritores/:id_subscritor" element={
                        <AdminPrivateRoute>
                          <AdminSubscritorDetalhes />
                        </AdminPrivateRoute>
                  } />

                  <Route path="usuarios" element={
                        <AdminPrivateRoute>
                          <AdminUsuarios />
                        </AdminPrivateRoute>
                  } />

                  <Route path="meu-perfil" element={
                        <AdminPrivateRoute>
                          <AdminUserProfile />
                        </AdminPrivateRoute>
                  } />
                  
                  <Route path="*" element={<NotFound text="A página solicitada está indisponível" />} />
                  
              </Route>

              <Route path="*" element={<NotFound text="Desculpe-nos, não conseguimos encontrar a página que procuras" />} />
              

        </Routes>
      </div>
    
  );
}

export default App;
