import { jwtDecode } from "jwt-decode";

import {api, get, post, patch, remove, options, postWithMultipartBody} from "../api"
import {
    saveEncryptedDataInLocalStorage,
    getDecryptedDataFromLocalStorage
} from "../../utils"

const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

export async function signIn (email, password) {
    const endpoint = "/auth/sign-in-subscritores"
    const data = {email, password}

    return await post(endpoint, data, configs)
    
}

export async function refreshJwt () {
    const endpoint = "/auth/refresh-subscritores"
    const data = {}

    const loggedUser = getDecryptedDataFromLocalStorage("fn_user")
    const savedToken = getDecryptedDataFromLocalStorage("fn_token")

    if (!loggedUser || !savedToken) { 
        return Promise.reject(
            JSON.stringify({code: 0, message: "Token inválido"})
        )  
    }

    try {
        const responseData = await post(endpoint, data, configs)

        const jwtDecoded = jwtDecode(responseData.jwt)
        const userData = jwtDecoded.data
        const token = responseData.jwt

        saveEncryptedDataInLocalStorage("fn_user", userData)
        saveEncryptedDataInLocalStorage("fn_token", token)

        api.defaults.headers.Authorization = `Bearer ${token}`

    } catch (error) {
        return Promise.reject(error)
    }

}

export async function verifyToken (jwt) {
    const endpoint = "/auth/verify-subscritores"

    api.defaults.headers.Authorization = `Bearer ${jwt}`

    try {
        await options(endpoint)

        return true
    } catch (error) {
        return Promise.reject(
            JSON.stringify({code: 0, message: "Token inválido"})
        )
    }
    
}

export const refreshJwtIfHasExpired = async () => {

    const savedToken = getDecryptedDataFromLocalStorage("fn_token")

    if (savedToken) {
        api.defaults.headers.Authorization = `Bearer ${savedToken}`
    }

    try {
        await verifyToken(savedToken)

    } catch (error) {
        await refreshJwt()
    }
}

export async function create (data) {
    const endpoint = "/auth/sign-up-subscritores"
    return await post(endpoint, data, configs)
}

export async function recoverPassword (email) {
    const endpoint = `/auth/password-recovery-subscritores/${email}`
    return await post(endpoint, {}, configs)
}

export async function activate (id) {
    const endpoint = `/auth/activate-subscritores/${id}`
    
    return await patch(endpoint, {}, configs)
}

export async function updatePassword (id, data) {
    const endpoint = `/auth/update-password-subscritores/${id}`
    
    return await patch(endpoint, data, configs)
   
}

export async function getAllSubscricoes(id) {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${id}/subscricoes`

    const data = await get(endpoint, configs)    

    return data
    
}

export async function subscribe (data) {

    await refreshJwtIfHasExpired()

    const file = data.comprovante ? DataURIToBlob(data.comprovante) : null
    const formData = new FormData();
    formData.append('id_plano', data.id_plano)
    formData.append('id_subscritor', data.id_subscritor)
    formData.append('valor_pago', data.valor_pago)
    formData.append('moeda', "kwanza")
    formData.append('cambio', data.cambio)
    if (file) {
        formData.append('comprovante', file) 
    }

    const configsModified = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    }
    
    const endpoint = "/subscricoes"
    return await postWithMultipartBody(endpoint, formData, configsModified)
}

export async function getAllFotonovelas (idSubscritor, limit, page=1, titulo="", orderCriteria="titulo") {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${idSubscritor}/fotonovelas`

    const configsModified = {
        ...configs, params: {
            limit,
            page,
            titulo,
            order_criteria: orderCriteria
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}

export async function getAllFavoritos (idSubscritor, limit, page=1) {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${idSubscritor}/fotonovelas-favoritas`

    const configsModified = {
        ...configs, params: {
            limit,
            page,
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}

export async function addFavorito (idSubscritor, idFotonovela) {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${idSubscritor}/fotonovelas-favoritas/${idFotonovela}`

    const data = await post(endpoint, {}, configs)

    return data
    
}

export async function deleteFavorito (idSubscritor, idFotonovela) {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${idSubscritor}/fotonovelas-favoritas/${idFotonovela}`

    const data = await remove(endpoint, configs)

    return data
    
}

export async function update (id, data) {
    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${id}`
    
    return await patch(endpoint, data, configs)
   
}