import { Link } from 'react-router-dom';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./style.css"


const Footer = () => {
    return (
        <div id="lp-footer">
            <div>
                <div>
                    <h3
                        style={{
                            textTransform: "uppercase",
                            color: "#1d3557",
                            fontWeight: "800",
                            paddingBottom: "1rem"
                        }}
                    >
                        Quem somos Nôs
                    </h3>
                    <p
                        style={{
                            color: "#000",
                            paddingBottom: "2rem",
                        }}
                    >
                        Mibilton Studio, uma empresa de Prestação de Serviços, identificada pelo NIF 5000080454, atua na área de cobertura de eventos com captura de imagens (fotografias e videos), agora, virada também na produção e distribuição de fotonovelas físicas e digitais.
                    </p>
                    
                </div>
                <div>
                    <h3
                        style={{
                            textTransform: "uppercase",
                            color: "#1d3557",
                            fontWeight: "800",
                            paddingBottom: "1rem"
                        }}
                    >
                        Contactos
                    </h3>
                    <ul
                         style={{
                            paddingBottom: "1rem"
                        }}
                    >
                        <li style={{color: "#000", textDecoration: "none"}}>+244 933 363 336 (Whatsapp)</li>
                        <li style={{color: "#000", textDecoration: "none"}}>fotonovelas.ao@gmail.com</li>
                    </ul>
                </div>
                <div>
                    <h3
                        style={{
                            textTransform: "uppercase",
                            color: "#1d3557",
                            fontWeight: "800",
                            paddingBottom: "1rem"
                        }}
                    >
                        Siga-nos
                    </h3>
                    <div
                        className='flex-column'
                        style={{
                            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start"
                        }}
                    >
                        <a href='https://web.facebook.com/profile.php?id=100069125709809' target='_blank' className='flex-row' style={{padding: "4px 0"}} ><FacebookOutlinedIcon /><span style={{marginLeft: "4px"}}>Facebook</span></a>
                        <a href='https://www.instagram.com/fotonovelaangola/?utm_source=qr&r=nametag' target='_blank' className='flex-row' style={{padding: "4px 0"}}><InstagramIcon /><span style={{marginLeft: "4px"}}>Instagram</span></a>
                        
                    </div>
                </div>
            </div>
           <div
            style={{
                paddingTop: "2rem 0"
            }}
           >
                <p
                    style={{
                        color: "#1d3557",
                        fontSize: "0.8rem",
                    }}
                >
                    Desenvolvido por <a href="https://apedrodevelopers.ao" target="_blank" style={{color: "#1d3557", borderBottom: "1px solid #1d3557"}}>A Pedro Developers</a>
                </p>
                
            </div>
        </div>
    )
}


export default Footer