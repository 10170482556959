import { Outlet } from "react-router-dom"

import "./style.css"

import FotonovelasTabs from "./fotonovelas-tabs"

const Fotonovelas = () => {
    return (
        <div id="fotonovelas">
            <div className="header">
                <h1>Fotonovelas</h1>
                <small>Listagem de todos os lançamentos </small>
            </div>
            
            <FotonovelasTabs />
            <div className="tab-content">
                <Outlet />
            </div>
        </div>
    )
}

export default Fotonovelas