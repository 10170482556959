import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import "./../data-table.css"

import Button from "../../components/button"
import StatusBadge from "../../components/status-badge"


export default function DataTable({data}) {

    const navigate = useNavigate()

    const openDetails = (item) => {
        navigate(
            `${item.id}`,
            {
                state: {
                    data: item,
                }
            }
        )
    }

    return (
        <div className="admin-data-table">
            <table>
                <thead>
                    <tr
                        style={{backgroundColor: "#dfdcdc"}}
                    >
                       <th style={{width: "50px"}}>N/O</th>
                       <th>Primeiro nome</th>
                       <th>Último nome</th>
                       <th>E-mail</th>
                       <th>País</th>
                       <th className="btns-operacao">Operação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => (
                        <tr key={item.id} >
                            <td>{index + 1}</td>
                            <td>{item.primeiro_nome}</td>
                            <td>{ item.ultimo_nome }</td>
                            <td>
                                <div
                                    style={{
                                        display: "flex", gap: "10px"
                                    }}
                                >
                                    <div>{item.email}</div>
                                    { item.email_confirmado !== true && <StatusBadge text="Não confirmado" status="red-light" width="120px"/> }
                                </div>
                            </td>
                            <td>{item.pais.nome}</td>
                            <td
                                className="btns-operacao"
                            >
                                <Button
                                    text=""
                                    googleIconName="open_in_new"
                                    crudOperation="cancel"
                                    primary={false}
                                    size="small"
                                    clickHandler={openDetails.bind(this, item)}
                                />
                            </td>
        
                        </tr>
                    ))
                }
                    
                </tbody>
            </table>
            
        </div>
    )
}


