import React from "react"
import { useNavigate } from "react-router-dom"

import "./style.css"

export default function Success ({title, content}) {

    const navigate = useNavigate()

    return (
        <div
            id="register-client-success"
            style={{
                width: "300px",
                boxShadow: "-1px 4px 18px -8px #000000",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                textAlign: "center"
            }}
        >
            <span
                className="material-symbols-outlined"
                style={{
                    fontSize: "50px",
                    color: "rgb(46, 125, 50)"
                }}
            >
                done
            </span>
            <h3 style={{color: "rgb(46, 125, 50)"}}>{title}</h3>
            <p
                style={{
                    fontWeight: "600",
                    margin: "10px 0"
                }}
            >
                {content}
            </p>
            <button
                className="primary"
                onClick={ () => navigate("/login") }
            >
                Fazer login
            </button>
        </div>
    )
}