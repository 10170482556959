import React, {useEffect, useState} from "react"
import { useOutletContext } from "react-router-dom"

import GeographyChart from "./geographyChart"
import { getData } from "../../../../services/admin/dashboard"

import "./style.css"
import "./../pages.css"
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"

export default function AdminDashboard () {

    const [totalSubscritores, setTotalSubscritores] = useState()
    const [subscritosPorPais, setSubscritoresPorPais] = useState([])
    const [totalFotonovelas, setTotalFotonovelas] = useState()
    const [fotonovelasMaisVistas, setFotonovelasMaisVistas] = useState([])
    const [totalSubscricoesPendentes, setTotalSubscricoesPendentes] = useState()
    const [totalSubscricoesExpirados, setTotalSubscricoesExpirados] = useState()
    const [loadingDashboardData, setLoadingDashboardData] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}

    useEffect( () => {

        fetchDashboardData()

    }, [] )

    const {refreshNotifications} = useOutletContext()

    const fetchDashboardData = async () => {
        setLoadingDashboardData(true)
        setError(null)

        try {

            refreshNotifications()

            const {
                total_subscritores,
                subscritores_por_pais,
                total_fotonovelas,
                fotonovelas_mais_vistas,
                subscricoes_pendentes,
                subscricoes_expiradas
            } = await getData()

            setTotalSubscritores(total_subscritores)
            setSubscritoresPorPais(subscritores_por_pais)
            setTotalFotonovelas(total_fotonovelas)
            setFotonovelasMaisVistas(fotonovelas_mais_vistas)
            setTotalSubscricoesPendentes(subscricoes_pendentes)
            setTotalSubscricoesExpirados(subscricoes_expiradas)

            setLoadingDashboardData(false)

           

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingDashboardData(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingDashboardData(false)

        }
    }

    return (
        <div id="admin-dashboard-page" className="page">
            <h1>Dashboard</h1>
            <>
                {
                    loadingDashboardData
                    && <div
                        style={{
                            backgroundColor: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            boxShadow: "10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1)"
                        }}  
                    >
                            <Loading text="Carregando os dados..." />
                        </div>
                }
                {!!error && <Alert message={error.message} severity="error" />}
            </>
            
            {
                !loadingDashboardData &&
                <div id="dashboard-items">
                    <div className="subescritores-cadastrados flex-row">
                        <div className="flex-column" style={{ justifyContent: "space-between" }}>
                            <h1>{totalSubscritores}</h1>
                            <p>Subscritores</p>
                        </div>
                        <span className="material-symbols-outlined">group</span>
                    </div>
                    <div className="lancamentos flex-row" >
                        <div className="flex-column">
                            <h1>{totalFotonovelas}</h1>
                            <p>Lançamentos</p>
                        </div>
                        <span className="material-symbols-outlined">video_camera_back</span>
                    </div>
                    <div className="subscricoes-pendentes flex-row">
                        <div className="flex-column">
                            <h1>{totalSubscricoesPendentes}</h1>
                            <p>Subscrições Pendentes</p>
                        </div>
                        <span className="material-symbols-outlined">receipt_long</span>
                    </div>
                    <div className="subscricoes-expiradas flex-row">
                        <div className="flex-column">
                            <h1>{totalSubscricoesExpirados}</h1>
                            <p>Subscrições Expiradas</p>
                        </div>
                        <span className="material-symbols-outlined">error</span>
                    </div>
                    <div className="subescritores-por-pais">
                        <p>Subescritores Por País</p>
                        <div
                            style={{
                                height: "300px", width: "100%",
                                display: "flex", justifyContent: "center", alignContent: "center",
                                
                            }}
                        >
                            <GeographyChart data={subscritosPorPais} />
                        </div>
                    </div>
                    <div className="fotonovelas-mais-vistas">
                        <p>Fotonovelas Mais Vistas</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Titulo</th>
                                    <th>Views</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fotonovelasMaisVistas.map( (fn) => (
                                        <tr key={fn.id}>
                                            <td>{fn.id}</td>
                                            <td>{fn.titulo}</td>
                                            <td>{fn.views}</td>
                                        </tr>
                                    ) )
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            }
        </div>
    )
}