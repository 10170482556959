const Colors = {
    black: "#000000",
    white: "#ffffff",
    primary: "#fca311",
    secondary: "#1d3557",
    gray: "#495057",
    lightGreen: "#80ffdb",
    background: "#f8f9fa",
}

export default Colors