
import "./style.css"
import loadingAnimation from "./../../../../assets/loading.gif"

export default function AdminLoadingPage({text}) {
    return (
        <div id="admin-loading-page">
            <img src={loadingAnimation} alt="Carregando.." />
            { !!text && <p
                style={{
                    fontSize: "10px",
                    fontWeight: "600"
                }}
            >
                {text}
            </p>}
        </div>
    )
}