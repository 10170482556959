import { HashLink } from 'react-router-hash-link';
import Colors from '../../constants/colors';

const AppLogo = ({fontSize = "2rem", color = Colors.secondary, to="/" }) => {
    return (
        <HashLink
                to={to}
                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                className="app-logo"
                style={{
                    fontFamily: "Playfair Display",
                    fontSize: fontSize,
                    fontWeight: "bolder",
                    fontStyle: "italic",
                    userSelect: "none",
                    cursor: "pointer",
                    display: "block",
                    color: color,
                    transition: "all 0.5s ease",
                    textAlign: "left",
                    width: "fit-content"
                }}
            >
                Fotonovelas
            </HashLink>
    )
}

export default AppLogo