import React, {useState, useEffect} from 'react';

import "./style.css"
import { baseURL } from '../../services/api';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IconBtn from "../../components/icon-btn"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewFotonovelaTest({
  isOpen = false,
  handleClose,
  fotonovela,
  inicialChapterIndex = 0,
  inicialSceneIndex = 0
}) {

  const [currentChapter, setCurrentChapter] = useState(fotonovela.capitulos[inicialChapterIndex])
  const [currentChapterIndex, setCurrentChapterIndex] = useState(inicialChapterIndex)
  const [currentScene, setCurrentScene] = useState(currentChapter.cenas[inicialSceneIndex])
  const [currentSceneIndex, setCurrentSceneIndex] = useState(inicialSceneIndex)
  const [fimFotonovela, setFimFotonovela] = useState(false)
  const [error, setError] = useState(null)

  const [leftBtnHidden, setLeftBtnHidden] = useState(false)
  const [rightBtnHidden, setRightBtnHidden] = useState(false)

  useEffect( () => {

    if (
      currentChapterIndex === 0
      && currentSceneIndex === 0
    ) {
      setLeftBtnHidden(true)
    } else {
      setLeftBtnHidden(false)
    }
   

    if (
      currentChapterIndex === fotonovela.capitulos.length - 1
      && currentSceneIndex === currentChapter.cenas.length - 1 
    ) {
      setFimFotonovela(true)
    } else {
      setRightBtnHidden(false)
    }


  }, [currentSceneIndex, currentChapterIndex] )

  const nextScene = async () => {
    if (
      currentSceneIndex === currentChapter.cenas.length - 1 
    ) {

      if (fimFotonovela) {
        setRightBtnHidden(true)

        return
      }

      // ir para o proximo capitulo
      setCurrentChapter(fotonovela.capitulos[currentChapterIndex + 1])
      setCurrentChapterIndex(currentChapterIndex + 1)
      setCurrentScene( fotonovela.capitulos[currentChapterIndex + 1].cenas[0] )
      setCurrentSceneIndex( 0 )

      return
    }

    // ir para a proxima cena
    setCurrentSceneIndex( currentSceneIndex + 1 )
    setCurrentScene(currentChapter.cenas[currentSceneIndex + 1])
                  
  }
  
  const previousScene = () => {

    setFimFotonovela(false)

    if (
      currentSceneIndex === 0
    ) {

      // ir para o capitulo anterior
      setCurrentChapter(fotonovela.capitulos[currentChapterIndex - 1])
      setCurrentChapterIndex(currentChapterIndex - 1)
      setCurrentScene( fotonovela.capitulos[currentChapterIndex - 1].cenas[0] )
      setCurrentSceneIndex( fotonovela.capitulos[currentChapterIndex - 1].cenas.length - 1 )

      return
    }

    // ir para cena anterior
    setCurrentSceneIndex( currentSceneIndex - 1 )
    setCurrentScene(currentChapter.cenas[currentSceneIndex - 1])
  }

  return (
    <>
      <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <div id="modal-wrapper">
            <div id="modal-header">
              <h1>{fotonovela.titulo} - {"[TESTE]"}</h1>
              <h2>{currentChapter.titulo}</h2>
              <h3>{(currentSceneIndex + 1) + "/" + currentChapter.cenas.length}</h3>
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "5%",

                }}
              >
                <IconBtn googleIconTag={<span className="material-symbols-outlined" style={{color: "#dbd9d9"}}>close</span>} onClickHandler={handleClose} />
              </div>
            </div>
            <div id="modal-content" className="flex-row">
            
              <div className="left" >
                <a
                  onClick={previousScene}
                  className={`
                    nav-btn left
                    ${ leftBtnHidden ? "hidden" : "" }
                  `}>
                    <span className="material-symbols-outlined">chevron_left</span>
                </a>
              </div>
              <div className="center">
                <img src={`${baseURL}/uploads/fotonovelas/${fotonovela.id}/${currentChapter.id}/${currentScene.img_cena}?nocache=${Date.now() * Math.random()}`} />
                
              </div>
              <div className="right">
                <a
                  onClick={nextScene}
                  className={`
                    nav-btn right
                    ${ rightBtnHidden ? "hidden" : "" }
                  `}
                >
                    <span className="material-symbols-outlined">chevron_right</span>
                </a>
              </div>
            </div>
          </div>
        
        </Dialog>
    </>
     
  );
}
