import React, {useState} from "react"
import { useNavigate } from "react-router-dom"

import SubscribeFormDialog from "./../../../subscribe-form-dialog"
import { saveEncryptedDataInLocalStorage } from "../../../../../utils"

const SemSubscricao = ({planos, loggedUser, afterSubmitHandler, existeSubscricaoEmEspera}) => {

    const [subscribeFormOpen, setSubscribeFormOpen] = useState(false)

    const navigate = useNavigate()

    const handleCliqueAqui = e => {
        e.preventDefault()

        if (existeSubscricaoEmEspera) {
            navigate("/painel-usuario/faturacao")
            return
        }

        setSubscribeFormOpen(true)
    }

    return (
        <>
             {
                subscribeFormOpen && <SubscribeFormDialog planos={planos} loggedUser={loggedUser} closeFormHandler={() => setSubscribeFormOpen(false)} afterSubmitHandler={afterSubmitHandler} />
            }
            <div
                className="flex-column"
                style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "150px",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #eee",
                    userSelect: "none",
                }}
            >
                <span
                    className="material-symbols-outlined"
                    style={{
                        fontSize: "4rem",
                        fontWeight: "300",
                        color: "#bababa",
                    }}
                >
                    error
                </span>
                <p
                    style={{
                        color: "#c6c5c5",
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontSize: "0.7rem",
                        fontWeight: "600"
                    }}      
                >
                    Nenhuma encontrada
                </p>
                
                <p
                    style={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        fontSize: "0.7rem",
                        fontWeight: "600"
                    }}  
                >
                    {
                        existeSubscricaoEmEspera
                            ? (
                                <>
                                    Clique
                                    <a
                                        style={{
                                            fontWeight: "bolder",
                                            borderBottom: "1px solid tomato",
                                            padding: "0 5px 0.2rem 5px",
                                            cursor: "pointer"
                                        }}
                                        onClick={handleCliqueAqui}
                                    >
                                        aqui
                                    </a>
                                    para ver a subscrição em espera
                                </>
                            )
                            : (
                                <>
                                    Clique
                                    <a
                                        style={{
                                            fontWeight: "bolder",
                                            borderBottom: "1px solid tomato",
                                            padding: "0 5px 0.2rem 5px",
                                            cursor: "pointer"
                                        }}
                                        onClick={handleCliqueAqui}
                                    >
                                        aqui
                                    </a>
                                    para subscrever
                                </>
                            )
                    }
                </p>
            </div>
        </>
    )
}

export default SemSubscricao