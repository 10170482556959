import React, {useState, useEffect} from 'react';

import "./style.css"
import { baseURL } from '../../services/api';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IconBtn from "../../components/icon-btn"
import Loading from "../../components/loading"
import FeedbackFormDialog from './feedback-form-dialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewFotonovelaDemo({
  isOpen = false,
  handleClose,
  fotonovela,
}) {

  const [currentScene, setCurrentScene] = useState(fotonovela.cenas[0])
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0)
  const [fimFotonovela, setFimFotonovela] = useState(false)
  const [error, setError] = useState(null)

  const [leftBtnHidden, setLeftBtnHidden] = useState(false)
  const [rightBtnHidden, setRightBtnHidden] = useState(false)

  useEffect( () => {

    if (
      currentSceneIndex === 0
    ) {
      setLeftBtnHidden(true)
    } else {
      setLeftBtnHidden(false)
    }
   

    if (
      currentSceneIndex === fotonovela.cenas.length - 1 
    ) {
      setFimFotonovela(true)
    } else {
      setRightBtnHidden(false)
    }


  }, [currentSceneIndex] )

  const nextScene = async () => {
    if (
      currentSceneIndex === fotonovela.cenas.length - 1 
    ) {

      if (fimFotonovela) {
        setRightBtnHidden(true)

        return
      }
    }

    // ir para a proxima cena
    setCurrentSceneIndex( currentSceneIndex + 1 )
    setCurrentScene(fotonovela.cenas[currentSceneIndex + 1])
                  
  }
  
  const previousScene = () => {

    setFimFotonovela(false)

    // ir para cena anterior
    setCurrentSceneIndex( currentSceneIndex - 1 )
    setCurrentScene(fotonovela.cenas[currentSceneIndex - 1])
  }

  return (
    <>
      <Dialog
          fullScreen
          open={isOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <div id="modal-wrapper">
            <div id="modal-header">
              <h1>{fotonovela.titulo} - {"[Demo]"}</h1>
              <h2>{fotonovela.capitulo.titulo}</h2>
              <h3>{(currentSceneIndex + 1) + "/" + fotonovela.cenas.length}</h3>
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "5%",

                }}
              >
                <IconBtn googleIconTag={<span className="material-symbols-outlined" style={{color: "#dbd9d9"}}>close</span>} onClickHandler={handleClose} />
              </div>
            </div>
            <div id="modal-content" className="flex-row">
            
              <div className="left" >
                <a
                  onClick={previousScene}
                  className={`
                    nav-btn left
                    ${ leftBtnHidden ? "hidden" : "" }
                  `}>
                    <span className="material-symbols-outlined">chevron_left</span>
                </a>
              </div>
              <div className="center">
                {
                  <img src={`${baseURL}/uploads/fotonovelas/${fotonovela.id}/${fotonovela.capitulo.id}/${currentScene.img_cena}?nocache=${Date.now() * Math.random()}`} />
                }
                
              </div>
              <div className="right">
                <a
                  onClick={nextScene}
                  className={`
                    nav-btn right
                    ${ rightBtnHidden ? "hidden" : "" }
                  `}
                >
                    <span className="material-symbols-outlined">chevron_right</span>
                </a>
              </div>
            </div>
          </div>
        
        </Dialog>
    </>
     
  );
}
