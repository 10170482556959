import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function ContextMenu({handleClose,  anchorEl, menuItens}) {
  const open = Boolean(anchorEl);

  return (
    <div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          menuItens.map((item, index) => {
            return (<MenuItem key={index} onClick={item.clickHandler}>{item.name}</MenuItem>)
          })
        }
      </Menu>
    </div>
  );
}