import React, {useState} from "react"
import { Link } from "react-router-dom";
import * as Yup from "yup"
import { useFormik } from "formik";

import "./style.css"
import {create} from "./../../../services/client/subscritor-api"
import AppLogo from "./../../../components/app-logo"

import { Box, TextField } from "@mui/material";
import Loading from "../../../components/loading";
import Alert from "../components/alert";
import Success from "./sucess";

const validationSchema = Yup.object({
    primeiro_nome: Yup
        .string()
        .max(15, "15 caracteres no máximo")
        .min(2, "2 caracteres no mínimo")
        .required("Campo obrigatório"),
    ultimo_nome: Yup
        .string()
        .max(15, "15 caracteres no máximo")
        .min(2, "2 caracteres no mínimo")
        .required("Campo obrigatório"),
    email: Yup
        .string()
        .email ("Email inválido")
        .required("Campo obrigatório"),
    password: Yup
        .string()
        .min(6, "6 caracteres no mínimo")
        .required("Campo obrigatório")
})

const RegisterClient = () => {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)


    const handleRegistrar = e => {
        e.preventDefault()
        
        formik.submitForm()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await create(values)

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {
            primeiro_nome: "",
            ultimo_nome: "",
            email: "",
            password: "",
            id_pais: "AGO"
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div
            id="register-client"
        >
            <div
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    padding: ".5rem",
                    display: "grid",
                    placeItems: "center"
                }}
            >
                <AppLogo fontSize="1rem" />
            </div>
            {
                formSubmited && success
                    ? <Success title="Conta criada com sucesso" content="Acabamos de enviar um link de confirmação para o seu email para poderes ativar a sua conta" />
                    : (
                        <div className="main">
                            <h1
                                style={{
                                    fontWeight: "800",
                                    color: "#1d3557",
                                    textAlign: "center"
                                }}
                            >
                                Criar conta
                            </h1>
                            <p
                                style={{
                                    fontWeight: "600",
                                    color: "#1d3557",
                                    textAlign: "center"
                                }}
                            >
                                Preencha os campos abaixo para criar uma conta
                            </p>
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                {submitingForm && <Loading text="Registando..." />}
                                {!!error && <Alert message={error.message} severity="error"  />}
                                {!!success && <Alert message="Registo salvo!" severity="success" />}
                            </div>
                            <form 
                                method=""
                                action=""
                                style={{
                                    transition: "all 300ms ease",
                                    pointerEvents: submitingForm ? "none" : "all",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    id="primeiro_nome"
                                    name="primeiro_nome"
                                    label="Nome"
                                    type="text"
                                    value={formik.values.primeiro_nome}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.primeiro_nome && !!formik.errors.primeiro_nome}
                                    helperText={formik.touched.primeiro_nome && formik.errors.primeiro_nome}
                                    variant="standard"
                                    autoComplete="off"
                                    sx={{
                                        mb: "1rem"
                                    }}
                                />
            
                                <TextField
                                    fullWidth
                                    id="ultimo_nome"
                                    name="ultimo_nome"
                                    label="Sobrenome"
                                    type="text"
                                    value={formik.values.ultimo_nome}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.ultimo_nome && !!formik.errors.ultimo_nome}
                                    helperText={formik.touched.ultimo_nome && formik.errors.ultimo_nome}
                                    variant="standard"
                                    sx={{
                                        mb: "1rem"
                                    }}
                                />
            
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="text"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && !!formik.errors.email}
                                    helperText={formik.touched.email && formik.errors.email}
                                    variant="standard"
                                    sx={{
                                        mb: "1rem"
                                    }}
                                />
                                
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && !!formik.errors.password}
                                    helperText={formik.touched.password && formik.errors.password}
                                    variant="standard"
                                    sx={{
                                        mb: "2rem"
                                    }}
                                />
            
                                <button type="submit" className="btn primary" onClick={handleRegistrar}>Registrar</button>
                                
                            </form>
                            <Box
                                sx={{
                                    mt: "2rem",
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                }}
                            >
                                <p style={{marginRight: "0.8rem", color: "#495057"}}>Já possui uma conta?</p>
                                <Link
                                    to="/login"
                                    style={{color:"#1d3557"}}
                                >
                                    Faça login
                                </Link>
                            </Box>
                        </div>
                    )
            }
           
        </div>
    )
}

export default RegisterClient