import React, { useState, useEffect, useContext, } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {format} from "date-fns"

import "./style.css"
import { getAllByIdPlano } from '../../../../services/admin/fotonovela-api';
import { toggleIsAvailable } from '../../../../services/admin/plano-api';

import Button from "./../../components/button"
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';
import AddFotonovelaFormDialog from './add-fotonovela-form-dialog';
import DeleteFotonovelaFormDialog from "./delete-fotonovela-form-dialog"
import DataTable from './data-table';

const formatCurrency = (number) => new Intl.NumberFormat( "ao-AO", {style: "currency", currency: "AOA"} ).format(number)

export default function AdminPlanoDetalhes () {
    const location = useLocation()

    const {data, fotonovelas} = location.state;

    const [addFotonovelaFormOpen, setAddFotonovelaFormOpen] = useState(false);
    const [deleteFotonovelaFormOpen, setDeleteFotonovelaFormOpen] = useState(false);
    const [fetchingFotonovelas, setFetchingFotonovelas] = useState(false)
    const [updatingIsAvailable, setUpdatingIsAvailable] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}
    const [fotonovelasAdded, setFotonovelasAdded] = useState(data.fotonovelas)
    const [fotonovelaToDelete, setFotonovelaToDelete] = useState({
        id_plano: 0,
        id_fotonovela: 0
    })

    const navigate = useNavigate()
   
    const openAddFotonovelaForm = () => {
        setAddFotonovelaFormOpen(true)
    }

    const closeAddFotonovelaForm = () => {
        setAddFotonovelaFormOpen(false);
    };

    const openDeleteFotonovelaForm = (item) => {
        setDeleteFotonovelaFormOpen(true)

        setFotonovelaToDelete({
            id_plano: data.id,
            id_fotonovela: item.id_fotonovela
        })
    }
    
    const closeDeleteFotonovelaForm = () => {
        setDeleteFotonovelaFormOpen(false)
    }


    const fetchFotonovelas = async () => {
 
        setFetchingFotonovelas(true)
        setError(null)

        try {
            const result = await getAllByIdPlano(data.id)

            setFotonovelasAdded(result.fotonovelas)

            setFetchingFotonovelas(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setFetchingFotonovelas(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setFetchingFotonovelas(false)

        }
        
    }

    const updateIsAvailable = async () => {
        setUpdatingIsAvailable(true)

        try {
            await toggleIsAvailable(data.id)

            data.is_available = !data.is_available

            setUpdatingIsAvailable(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setUpdatingIsAvailable(false)
 
                return
            }

            setUpdatingIsAvailable(false)

        }
    }



    return (
        <>
            {
                addFotonovelaFormOpen 
                    ? (<AddFotonovelaFormDialog
                        closeFormHandler={closeAddFotonovelaForm}
                        afterSubmitHandler={fetchFotonovelas}
                        idPlano={data.id}
                        fotonovelas={fotonovelas}
                    />) : ""
            }
            
            {
                deleteFotonovelaFormOpen 
                    ? (<DeleteFotonovelaFormDialog
                        closeFormHandler={closeDeleteFotonovelaForm}
                        afterSubmitHandler={fetchFotonovelas}
                        data={fotonovelaToDelete}
                    />) : ""
            }
            
        
            <div id="plano-detalhes-page">
                    <div>
                        <Button
                            text="Voltar"
                            googleIconName="undo"
                            crudOperation="cancel"
                            fullWidth={false}
                            primary={true}
                            clickHandler={() => navigate(-1)}
                        />
                    </div>
                    <div>
                        <div className="details">
                            
                            <div className="content-wrapper">
                                <div>
                                    <h2>{data.nome}</h2>
                                </div>
                                <div>
                                    <span>Descrição</span>
                                    <p
                                        style={{
                                            display: "inline-block",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "500px"
                                        }}
                                    >{data.descricao}</p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2rem"
                                    }}
                                >
                                    <div>
                                        <span>Preço</span>
                                        <p>{formatCurrency(data.preco)}</p>
                                    </div>
                                    <div>
                                        <span>Periodo</span>
                                        <p>{data.periodo.nome}</p>
                                    </div>
                                    <div>
                                        <span>Disponivel</span>
                                        <p>{ `${data.is_available ? "Sim" : "Não"}` }</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: "5px 0 0 0",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem"
                                    }}
                                >
                                    {
                                        updatingIsAvailable
                                            ? <div style={{width: "130px"}}> <Loading text="Atualizando..." gifSize="15px" /> </div>
                                            : (
                                                    data.is_available === false
                                                        ? (
                                                            <Button
                                                                text="Tornar disponivel"
                                                                googleIconName="add"
                                                                crudOperation="create"
                                                                primary={true}
                                                                fullWidth={false}
                                                                hideIcon={true}
                                                                clickHandler={updateIsAvailable}
                                                            />
                                                        )
                                                        : (
                                                            <Button
                                                                text="Tornar indisponivel"
                                                                googleIconName="add"
                                                                crudOperation="delete"
                                                                primary={true}
                                                                fullWidth={false}
                                                                hideIcon={true}
                                                                clickHandler={updateIsAvailable}
                                                            />
                                                        )
                                            )
                                    }

                                    <Button
                                        text="Adicionar fotonovelas"
                                        googleIconName="add"
                                        crudOperation="cancel"
                                        primary={true}
                                        fullWidth={false}
                                        hideIcon={true}
                                        clickHandler={openAddFotonovelaForm}
                                      
                                    />
                                
                                </div>
                            </div>
                        </div>
                        <div className="fotonovelas">
                            <h4>Fotonovelas do plano</h4>
                            <>
                                {
                                    fetchingFotonovelas
                                    && <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}  
                                    >
                                            <Loading text="Carregando..." />
                                        </div>
                                }
                                {!!error && <Alert message={error.message} severity="error" />}
                            </>

                            <>
                                {
                                    !fetchingFotonovelas && Boolean(error) === false
                                    ? (
                                        <DataTable
                                            data = {fotonovelasAdded}
                                            deleteBtnClickHandler={openDeleteFotonovelaForm}
                                        />
                                    )
                                    : null
                                }
                            </>
                            
                        </div> 
                    </div>

                </div>
                
        </>
    )
}