import React, { useState, useEffect, useContext, } from 'react';

import "./../pages.css"
import {getAll} from "../../../../services/admin/fotonovela-api"
import {AdminPaginationContext} from "../../../../contexts/admin-pagination"

import Button from "../../components/button"
import SearchField from "../../components/search-field"
import AdminPagination from "../../components/pagination"
import DataTable from "./data-table"
import CreateFormDialog from "./create-form-dialog";
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';

export default function AdminFotonovelas () {

    const [formOpen, setFormOpen] = useState(false);
    const [fotonovelas, setFotonovelas] = useState([])
    const [totalFotonovelas, setTotalFotonovelas] = useState(0)
    const [paginationCount, setPaginationCount] = useState(1)
    const [loadingData, setLoadingData] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}
    const [searchField, setSearchField] = useState("")

    const {page} = useContext(AdminPaginationContext)

    useEffect(function(){
        fetchData()
    }, [page])

    const openForm = () => {
        setFormOpen(true)
    }

    const closeForm = () => {
        setFormOpen(false);
    };

    const fetchData = async () => {
 
        setLoadingData(true)
        setError(null)

        try {
            const {fotonovelas, total} =  await getAll(4, page, searchField)

            setFotonovelas(fotonovelas)

            setTotalFotonovelas(total)

            setPaginationCount(
                total > 0 ? Math.round( total / 4) + 1  : 1
            )

            setLoadingData(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingData(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingData(false)

        }
        
    }

    return (
        <>
        {
            formOpen ? (<CreateFormDialog closeFormHandler={closeForm} afterSubmitHandler={fetchData} />) : ""
        }
        <div id="fotonovelas-page" className="page">
            <h1>Fotonovelas</h1>
            <div>
                <div>
                    <SearchField
                        placeholder="Pesquisar pelo título..."
                        onClickHandler = {fetchData}
                        onChangeHandler = {(e) => setSearchField(e.target.value)}
                    />
                    
                    <Button
                        text="Adicionar novo"
                        googleIconName="add"
                        crudOperation="create"
                        type="submit"
                        primary={true}
                        hideIcon={true}
                        clickHandler={openForm}
                    />
                </div>

                <>
                    {
                        loadingData
                        && <div
                            style={{
                                backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                boxShadow: "10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1)"
                            }}  
                        >
                                <Loading text="Carregando os dados..." />
                            </div>
                    }
                    {!!error && <Alert message={error.message} severity="error" />}
                </>
                {
                    !loadingData && Boolean(error) === false
                    ? (
                        <DataTable
                            data = {fotonovelas}
                            afterSubmitHandler={fetchData}
                        />
                    )
                    : null
                }

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <span
                        style={{marginRight: "20px", fontSize: "12px", fontWeight: "500"}} 
                    >
                        {`${ fotonovelas.length } de ${totalFotonovelas} registos encontrados`}
                    </span>
                    
                    <AdminPagination
                        count={ paginationCount === 0 ? 1 : paginationCount }
                        route="/painel-administrador/fotonovelas"
                    />
                </div>
            </div>
        </div>
        </>
    )
}