import SectionTitle from "../global/section-title"
import "./style.css"

const Statistic = () => {
    return (
        <div
            id="lp-statistic"
        >
            <SectionTitle title="Estatísticas" subtitle="Os nossos números falam por nós" />
            <div
                className="statistic"
            >
                <div className="statistic-item">
                    <h3>1+</h3>
                    <p>Fotonovelas lançadas</p>
                </div>
                <div className="statistic-item">
                    <h3>10+</h3>
                    <p>Usuários registrados nos últimos 25 dias</p>
                </div>
                <div className="statistic-item">
                    <h3>5+</h3>
                    <p>Comentários feitos pelos usuários</p>
                </div>
            </div>
        </div>
    )
}


export default Statistic