import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { updateEstado } from "../../../../services/admin/subscricao-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import { Divider, TextField } from '@mui/material';
import InputSelectOne from "../../components/input-select-one"

const validationSchema = Yup.object({})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function ValidarSubscricaoFormDialog({idSubscricao, closeFormHandler, afterSubmitHandler}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [estado, setEstado] = useState(null)

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        if (!estado) return

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        const estadoValue = estado === "Rejeitado" ? "rejeitado" : "activo"
        const motivoRejeicao = estado === "Rejeitado" ? values?.motivo_rejeicao : ""

        try {
            await updateEstado(
                idSubscricao,
                {
                    estado: estadoValue,
                    motivo_rejeicao: motivoRejeicao 
                }
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {
            "motivo_rejeicao": ""
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    const estadoSelectChangeHandler = (event) => {
        const {
        target: { value },
        } = event;

        setEstado(value)
    };

    const estadoOptions = [
        "Válido",
        "Rejeitado"
    ]

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Validar subscrição
                </DialogTitle>

                {
                    !formSubmited &&
                        <IconButton
                            aria-label="close"
                            onClick={closeHandler}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                }
                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                     {
                            formSubmited
                                ? (
                                    <>
                                        <div
                                            style={{
                                                width: "400px"
                                            }}
                                        >
                                            <Button
                                                text="Fechar"
                                                googleIconName="close"
                                                crudOperation="cancel"
                                                primary={true}
                                                clickHandler={closeHandler}
                                                fullWidth={true}
                                                hideIcon={true}
                                            />
                                        </div>
                                    </>
                                )
                                : (
                                    <>
                                        <div
                                            style={{
                                                marginBottom: "15px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "stretch",
                                                gap: "10px",
                                                width: "400px"
                                            }}
                                        >

                                            <InputSelectOne placeholder="Estado" onChangeHandler={estadoSelectChangeHandler} options={estadoOptions} initialValue="" />
                                            
                                            {
                                                estado !== "Válido"
                                                    ? <TextField
                                                        fullWidth
                                                        id="motivo_rejeicao"
                                                        name="motivo_rejeicao"
                                                        label="Motivo"
                                                        size="small"
                                                        multiline={true}
                                                        value={formik.values['motivo_rejeicao']}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched['motivo_rejeicao'] && Boolean(formik.errors['motivo_rejeicao'])}
                                                        helperText={formik.touched['motivo_rejeicao'] && formik.errors['motivo_rejeicao']}
                                                    />
                                                    : null
                                            }

                                        </div>

                                        <Button
                                            text="Salvar o registo"
                                            googleIconName="save"
                                            crudOperation="create"
                                            primary={true}
                                            clickHandler={formik.submitForm}
                                            fullWidth={true}
                                            hideIcon={true}
                                        />
                                    </>
                                )
                        }

                    
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}