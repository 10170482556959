import React from "react"
import {format} from "date-fns"

import "./style.css"

const calcExpirationDate = (initialDate, periodDays) => {
 
    const endDate = new Date(initialDate)
    endDate.setDate( initialDate.getDate() + periodDays )

    return endDate
}

const SubscricaoAtiva = ({subscricao}) => {

    return (
        <>
           
            <div
                id="subscricao-ativa"
                className="flex-column"
            
            >
                <span
                    className="material-symbols-outlined"
                    style={{
                        fontSize: "4rem",
                        color: "#52b788",

                    }}
                >
                    verified
                </span>
                <h4
                    style={{
                        fontSize: "1.2rem", fontWeight: "300",
                        textTransform: "uppercase",
                        color: "#000"
                    }}
                >
                    { subscricao.plano.nome }
                </h4>
                <p
                    style={{
                        fontSize: "0.8rem", fontWeight: "700",
                        textTransform: "uppercase",
                    }}
                >
                    válido até {
                        format (
                            calcExpirationDate( new Date(subscricao.inicio_validade), subscricao.plano.periodo.qtde_dias ),
                            "dd/MM/yyyy"
                        )
                    }
                </p>
            </div>
        </>
      
    )
}

export default SubscricaoAtiva