import React from "react-transition-group"
import { Link } from "react-router-dom"

import "./style.css"

const FlatButton = ({onClickHandler = null }) => {
    return (
        <div
            id="flat-btn"
            onClick={onClickHandler}
        >
            <span className="material-symbols-outlined">edit_square</span>    
        </div>
        
    )
}

export default FlatButton