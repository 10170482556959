import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
import json2mq from "json2mq"

import "./style.css"
import { AuthClientContext } from "../../../contexts/auth-client"
import { ClientNavigationContext } from "../../../contexts/client-navigation"

import AppLogo from "../../../components/app-logo"
import IconBtn from "../../../components/icon-btn"
import PerfilBox from "./perfil-box"
import NotificationsBox from "./notifications-box"
import ClientUserProfile from "./../client-user-profile"

const NavClient = () => {

    const [showPerfilBox, setShowPerfilBox] = useState(false)
    const [showNotificationsBox, setShowNotificationsBox] = useState(false)

    const [showUserProfile, setShowUserProfile] = useState(false)

    const {user, logOut} = useContext(AuthClientContext)
    const {currentPage, updateCurrentPage} = useContext(ClientNavigationContext)

    const matches = useMediaQuery(
        json2mq({
            maxWidth: 1024
        })
    )


    const handleShowPerfilBox = () => {
        setShowPerfilBox(!showPerfilBox)
        setShowNotificationsBox(false)
    }

    const handleShowNotificationsBox = () => {
        setShowNotificationsBox(!showNotificationsBox)
        setShowPerfilBox(false)
    }

    const logoutHandler = () => {
        setShowPerfilBox(!showPerfilBox)
        setShowNotificationsBox(false)

        logOut()
    }

    const openUserProfilePage = () => {
        setShowUserProfile(true)
        setShowPerfilBox(!showPerfilBox)
        setShowNotificationsBox(false)
    }
    
    const closeUserProfilePage = () => {
        setShowUserProfile(false)
    }

    return (
        <>
        {
            <ClientUserProfile  isOpen={showUserProfile} handleClose={closeUserProfilePage} />
        }
            <div
                id="nav-client" className="flex-row"
            >
                <div >
                    <AppLogo to="/painel-usuario" fontSize={`${matches ? "1.7rem" : "2rem"}`} />
                </div>

                <div className="nav flex-row">
                    <ul className="flex-row">
                        <li
                            className={currentPage === "home" ? "active" : ""}
                        >
                            <Link
                                to="/painel-usuario"
                                className="flex-row"
                                onClick={() => updateCurrentPage("home")}
                            >
                                <span className="material-symbols-outlined">home</span>
                                <span>Home</span>
                            </Link>
                        </li>
                        <li
                            className={currentPage === "faturacao" ? "active" : ""}
                        >
                            <Link
                                to="/painel-usuario/faturacao"
                                className="flex-row"
                                onClick={() => updateCurrentPage("faturacao")}
                            >
                                <span className="material-symbols-outlined">receipt_long</span>
                                <span>Faturação</span>
                            </Link>
                        </li>
                        <li
                            className={currentPage === "fotonovelas" ? "active" : ""}
                        >
                            <Link
                                to="/painel-usuario/fotonovelas"
                                className="flex-row"
                                onClick={() => updateCurrentPage("fotonovelas")}
                            >
                                <span className="material-symbols-outlined">video_camera_back</span>
                                <span>Fotonovelas</span>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="flex-row icon-btns-wrapper">
                    <div className="notification"
                        onClick={handleShowNotificationsBox}
                    >
                        <IconBtn googleIconTag={
                            <span className="material-symbols-outlined">notifications</span>} 
                        />
                        <div className="notification-pin flex-row">
                        </div>
                    </div>

                    
                    <div
                        onClick={handleShowPerfilBox}
                    >
                        <IconBtn
                            googleIconTag={
                                <span className="material-symbols-outlined">account_circle</span>
                            } 
                        />
                    </div>

                    
                        
                </div>

                {
                    <>
                        <PerfilBox show={showPerfilBox} userData={user} handleSair={logoutHandler} handleOpenUserProfilePage={openUserProfilePage} />
                        <NotificationsBox show={showNotificationsBox} />
                    </>
                }

            </div>
        </>
        
    )
}

export default NavClient