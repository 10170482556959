
import { useNavigate } from "react-router-dom"

import notFoundImage from "./../../assets/not-found-image.jpg"
import Button from "./../admin/components/button"

import "./style.css"

export default function NotFound({text}) {

    const navigate = useNavigate()

    return (
        <div id="not-found-page">
            <img src={notFoundImage} width="300px" alt="Página Não Encontrada" />
            <p>{text}</p>

            <Button
                text="Clique para voltar"
                googleIconName="undo"
                crudOperation="create"
                clickHandler={() => navigate(-1)}
            />
        </div>
    )
}