import React, { useState, useEffect, useContext, } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {format} from "date-fns"

import "./style.css"
import { getAllByIdFotonovela } from '../../../../services/admin/capitulo-api';
import { baseURL } from '../../../../services/api';
import { calculateFotonovelaRating } from "./../../../../utils";

import Button from "./../../components/button"
import Generos from '../../../../components/lancamento/genero';
import LancamentoRating from '../../../../components/lancamento-rating';
import Capitulo from './capitulo';
import CreateCapituloFormDialog from './create-capitulo-form-dialog';
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';
import Cena from './cena';
import CreateDemoFormDialog from './create-demo-form-dialog';
import DeleteDemoFormDialog from "./delete-demo-form-dialog"
import UpdateCapituloFormDialog from './update-capitulo-form-dialog';
import DeleteCapituloFormDialog from "./delete-capitulo-form-dialog"
import CreateCenaFormDialog from "./create-cena-form-dialog"
import UpdateCenaFormDialog from "./update-cena-form-dialog"
import DeleteCenaFormDialog from "./delete-cena-form-dialog"
import ViewFotonovelaTest from "./../../../view-fotonovela/test"

export default function AdminFotonovelasDetalhes () {

    const [createCapituloFormOpen, setCreateCapituloFormOpen] = useState(false);
    const [updateCapituloFormOpen, setUpdateCapituloFormOpen] = useState(false);
    const [deleteCapituloFormOpen, setDeleteCapituloFormOpen] = useState(false);
    const [createCenaFormOpen, setCreateCenaFormOpen] = useState(false);
    const [updateCenaFormOpen, setUpdateCenaFormOpen] = useState(false);
    const [deleteCenaFormOpen, setDeleteCenaFormOpen] = useState(false);
    const [demoFormOpen, setDemoFormOpen] = useState(false);
    const [fetchingCapitulos, setFetchingCapitulos] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}
    const [deleteDemoFormOpen, setDeleteFormOpen] = useState(false)
    const [selectedCapitulo, setSelectedCapitulo] = useState(null)
    const [selectedCena, setSelectedCena] = useState(null)
    const [viewFotonovelaTest, setViewFotonovelaTest] = useState(false)
    const [initialChapterIndex, setInitialChapterIndex] = useState(0)
    
    const navigate = useNavigate()
    const location = useLocation()
    
    const data = location.state.data;

    const [capitulos, setCapitulos] = useState(data.capitulos)
    const [cenas, setCenas] = useState([])

    const [demo, setDemo] = useState(data.demo)
   
    const openViewFotonovelaTest = () => {
        setInitialChapterIndex(0)

        if (data.capitulos.length < 1) return

        setViewFotonovelaTest(true)
    }
    
    const openViewFotonovelaTestByChapter = (chapterIndex = 0) => {
        setInitialChapterIndex(chapterIndex)

        if (data.capitulos.length < 1) return

        setViewFotonovelaTest(true)
    }
   
    const closeViewFotonovelaTest = () => {
        setViewFotonovelaTest(false)
    }

    const closeCreateCapituloForm = () => {
        setCreateCapituloFormOpen(false);
    };
    
    const closeUpdateCapituloForm = () => {
        setUpdateCapituloFormOpen(false);
    };
    
    const closeDemoForm = () => {
        setDemoFormOpen(false);
    };
    
    const closeDeleteDemoForm = () => {
        setDeleteFormOpen(false);
    };

    const openCreateCapituloForm = () => {
        setCreateCapituloFormOpen(true)
    }
    
    const openUpdateCapituloForm = () => {
        setUpdateCapituloFormOpen(true)
    }

    const openDeleteCapituloForm = () => {
        setDeleteCapituloFormOpen(true)
    }
    
    const closeDeleteCapituloForm = () => {
        setDeleteCapituloFormOpen(false)
    }
    
    const openCreateCenaForm = () => {
        setCreateCenaFormOpen(true)
    }
   
    const closeCreateCenaForm = () => {
        setCreateCenaFormOpen(false)
    }
   
    const openUpdateCenaForm = (selectedCena) => {
        setSelectedCena(selectedCena)
        setUpdateCenaFormOpen(true)
    }
   
    const closeUpdateCenaForm = () => {
        setUpdateCenaFormOpen(false)
    }
    
    const openDeleteCenaForm = (selectedCena) => {
        setSelectedCena(selectedCena)
        setDeleteCenaFormOpen(true)
    }
   
    const closeDeleteCenaForm = () => {
        setDeleteCenaFormOpen(false)
    }
    
    const openDemoForm = () => {
        setDemoFormOpen(true)
    }
    
    const openDeleteDemoForm = () => {
        setDeleteFormOpen(true)
    }

    const fetchCapitulos = async () => {
 
        setFetchingCapitulos(true)
        setError(null)
        setSelectedCapitulo(null)
        setCenas([])

        try {
            const result = await getAllByIdFotonovela(data.id)

            setCapitulos(result)

            setFetchingCapitulos(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setFetchingCapitulos(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setFetchingCapitulos(false)

        }
        
    }

    const capituloSelectedHandler = (e, idCapitulo) => {
        setCenas(
            capitulos.filter( capitulo => capitulo.id === idCapitulo )[0].cenas
        ) 

        setSelectedCapitulo(
            capitulos.filter( capitulo => capitulo.id === idCapitulo )[0]
        )

    }

    const afterDeleteDemotHandler = () => {
        setDemo([])
    }
    const afterCreateDemotHandler = (idDemo) => {
        setDemo({
            id: idDemo,
            id_fotonovela: data.id
        })
    }

    return (
        <>
            {
                viewFotonovelaTest 
                    ? (<ViewFotonovelaTest
                        isOpen={viewFotonovelaTest}
                        handleClose={closeViewFotonovelaTest}
                        fotonovela={data}
                        inicialChapterIndex={initialChapterIndex}
                    />) : ""
            }
            {
                createCapituloFormOpen 
                    ? (<CreateCapituloFormDialog
                        closeFormHandler={closeCreateCapituloForm}
                        afterSubmitHandler={fetchCapitulos}
                        idFotonovela={data.id}
                    />) : ""
            }

            {
                updateCapituloFormOpen 
                    ? (<UpdateCapituloFormDialog
                        closeFormHandler={closeUpdateCapituloForm}
                        afterSubmitHandler={fetchCapitulos}
                        idFotonovela={data.id}
                        data={selectedCapitulo}
                    />) : ""
            }
            
            {
                deleteCapituloFormOpen 
                    ? (<DeleteCapituloFormDialog
                        closeFormHandler={closeDeleteCapituloForm}
                        afterSubmitHandler={fetchCapitulos}
                        idCapitulo={selectedCapitulo?.id}
                    />) : ""
            }
            
            {
                createCenaFormOpen 
                    ? (<CreateCenaFormDialog
                        closeFormHandler={closeCreateCenaForm}
                        afterSubmitHandler={fetchCapitulos}
                        idCapitulo={selectedCapitulo?.id}
                    />) : ""
            }
            
            {
                updateCenaFormOpen 
                    ? (<UpdateCenaFormDialog
                        closeFormHandler={closeUpdateCenaForm}
                        afterSubmitHandler={fetchCapitulos}
                        idCena={selectedCena.id}
                    />) : ""
            }
            
            {
                deleteCenaFormOpen 
                    ? (<DeleteCenaFormDialog
                        closeFormHandler={closeDeleteCenaForm}
                        afterSubmitHandler={fetchCapitulos}
                        idCena={selectedCena.id}
                    />) : ""
            }

            {
                demoFormOpen
                    ? (<CreateDemoFormDialog
                            closeFormHandler={closeDemoForm}
                            afterSubmitHandler={afterCreateDemotHandler}
                            idFotonovela={data.id}
                            capitulos={capitulos}
                        />) : ""
            }

            {
                deleteDemoFormOpen && (
                    <DeleteDemoFormDialog
                        initialValues={demo}
                        closeFormHandler={closeDeleteDemoForm}
                        afterSubmitHandler={afterDeleteDemotHandler}
                    />
                )
            }
            
        
            <div id="fotonovela-detalhes-page">
                    <div>
                        <Button
                            text="Voltar"
                            googleIconName="undo"
                            crudOperation="cancel"
                            fullWidth={false}
                            primary={true}
                            clickHandler={() => navigate(-1)}
                        />
                    </div>
                    <div>
                        <div className="details">
                            <div className="img-wrapper">
                                <img
                                    src={`${baseURL}/uploads/fotonovelas/${data.id}/${data.img_capa}?nocache=${Date.now() * Math.random()}`}
                                    alt={data.img_capa}
                                />
                            </div>
                            
                            <div className="content-wrapper">
                                <div>
                                    <span>Título</span>
                                    <span>{data.titulo}</span>
                                </div>
                                <div>
                                    <span>Descrição</span>
                                    <p
                                        style={{
                                            display: "inline-block",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "500px"
                                        }}
                                    >{data.descricao}</p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "2rem"
                                    }}
                                >
                                    <div>
                                        <span>Gêneros</span>
                                        {<Generos generos={data.generos} />}
                                    </div>
                                    <div>
                                        <span>Classificação</span>
                                        <LancamentoRating rating={calculateFotonovelaRating(data.avaliacoes)} readOnly={true} />
                                    </div>
                                    <div>
                                        <span>Atualizado em</span>
                                        <p>{format(new Date(data.updated_at.date), "dd/MM/yyyy hh:mm")}</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: "5px 0 0 0",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.5rem"
                                    }}
                                >
                                    <Button
                                        text="Visualizar"
                                        googleIconName="slideshow"
                                        crudOperation="create"
                                        primary={true}
                                        fullWidth={false}
                                        hideIcon={true}
                                        clickHandler={openViewFotonovelaTest}
                                    />
                                    <Button
                                        text="Adicionar capítulos"
                                        googleIconName="add"
                                        crudOperation="cancel"
                                        primary={true}
                                        fullWidth={false}
                                        hideIcon={true}
                                        clickHandler={openCreateCapituloForm}
                                    />
                                    { 
                                        !!demo["id"] 
                                            ? <Button
                                                text="Deletar demo"
                                                googleIconName="delete"
                                                crudOperation="delete"
                                                primary={true}
                                                fullWidth={false}
                                                hideIcon={true}
                                                clickHandler={openDeleteDemoForm}
                                            /> 
                                            
                                            :<Button
                                                text="Criar demo"
                                                googleIconName="slideshow"
                                                crudOperation="cancel"
                                                primary={true}
                                                fullWidth={false}
                                                hideIcon={true}
                                                clickHandler={openDemoForm}
                                            />
                                    }
                                
                                </div>
                            </div>
                        </div>
                        <div className="capitulos">
                            <h4>Capítulos { !!selectedCapitulo && ` > ${selectedCapitulo.titulo}`}</h4>
                            <>
                                {
                                    fetchingCapitulos
                                    && <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}  
                                    >
                                            <Loading text="Carregando..." />
                                        </div>
                                }
                                {!!error && <Alert message={error.message} severity="error" />}
                            </>

                            <>
                                {
                                    !fetchingCapitulos && Boolean(error) === false
                                    ? (
                                        <>
                                            
                                            <div>
                                                {
                                                    capitulos.map((item, index) => {
                                                        return <Capitulo
                                                            key={item.id} data={item}
                                                            idFotonovela={data.id}
                                                            selectHandler={capituloSelectedHandler}
                                                            openUpdateCapituloForm={openUpdateCapituloForm}
                                                            openDeleteCapituloForm={openDeleteCapituloForm}
                                                            openAdicionarCenaForm={openCreateCenaForm}
                                                            openViewFotonovelaTest={openViewFotonovelaTestByChapter.bind(this, index)}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                    : null
                                }
                            </>
                            
                        </div>
                        <div className="cenas">
                            <h4>Cenas</h4>       
                            <div>
                                {
                                    cenas.map((item) => {
                                        return <Cena
                                            key={item.id}
                                            data={item}
                                            idFotonovela={data.id}
                                            openUpdateCenaForm={openUpdateCenaForm}
                                            openDeleteCenaForm={openDeleteCenaForm}
                                        />
                                    })
                                }
                            </div>
                                
                        </div> 
                    </div>

                </div>
                
        </>
    )
}