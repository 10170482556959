import React, {useState} from "react"
import { Link } from "react-router-dom";
import * as Yup from "yup"
import { useFormik } from "formik";

import "./style.css"
import {recoverPassword} from "../../../services/client/subscritor-api"
import AppLogo from "./../../../components/app-logo"

import { Box, TextField } from "@mui/material";
import Loading from "../../../components/loading";
import Alert from "../components/alert";
import Success from "./sucess";

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email ("Email inválido")
        .required("Campo obrigatório"),
})

const PasswordRecovery = () => {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [email, setEmail] = useState("")

    const handleEnviar = e => {
        e.preventDefault()
        
        formik.submitForm()
    }

    const submitForm = async ({email}) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await recoverPassword(email)

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div
            id="client-password-recovery"
        >
            <div
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    padding: ".5rem",
                    display: "grid",
                    placeItems: "center"
                }}
            >
                <AppLogo fontSize="1rem" />
            </div>

            {
                formSubmited && success
                    ? <Success
                        title="Enviado com sucesso"
                        content="Acabamos de enviar um link de redefinição da password para o seu email."
                        showBtn={false}
                    />
                    : (
                        <div className="main">
                            <h2
                                style={{
                                    fontWeight: "800",
                                    color: "#1d3557",
                                    textAlign: "center"
                                }}
                            >
                                Recuperação da password
                            </h2>
                            <p
                                style={{
                                    fontWeight: "600",
                                    color: "#1d3557",
                                    textAlign: "center"
                                }}
                            >
                                Informe abaixo o seu email
                            </p>
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                {submitingForm && <Loading text="Enviando o pedido..." />}
                                {!!error && <Alert message={error.message} severity="error"  />}
                                {!!success && <Alert message="Registo salvo!" severity="success" />}
                            </div>
                            <form 
                                method=""
                                action=""
                                style={{
                                    transition: "all 300ms ease",
                                    pointerEvents: submitingForm ? "none" : "all",
                                }}
                            >

                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="text"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && !!formik.errors.email}
                                    helperText={formik.touched.email && formik.errors.email}
                                    variant="standard"
                                    sx={{
                                        mb: "1rem"
                                    }}
                                />

                                <button type="submit" className="btn primary" onClick={handleEnviar}>Enviar</button>
                                
                            </form>
                            <Box
                                sx={{
                                    mt: "1rem",
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                }}
                            >
                                <Link
                                    to="/login"
                                    style={{color:"#1d3557"}}
                                >
                                    Voltar ao formulário de login
                                </Link>
                            </Box>
                        </div>
                    )
            }
            
        </div>
    )
}

export default PasswordRecovery