import Topbar from "./../topbar"
import Banner from "./../banner"
import "./style.css"

const Header = () => {
    return (
        <div id="lp-header">
            <Topbar />
            <Banner />
        </div>
    )
}


export default Header