
import { get, post, remove, patch, } from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

export async function create (data) {
    await refreshJwtIfHasExpired()
    
    const endpoint = "/planos"
    return await post(endpoint, data, configs)
   
}

export async function eliminate (id) {
    await refreshJwtIfHasExpired()

    const endpoint = `/planos/${id}`
    
    return await remove(endpoint, configs)
   
}

export async function getAll(limit, page, nome="") {

    await refreshJwtIfHasExpired()

    const endpoint = "/planos"

    const configsModified = {
        ...configs, params: {
            limit,
            page,
            nome,
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}

export async function toggleIsAvailable (idPlano) {
    await refreshJwtIfHasExpired()
    
    const endpoint = `/planos/${idPlano}/toggle-is-available`
    return await patch(endpoint, configs)

}


export async function addProduto (idPlano, data) {
    await refreshJwtIfHasExpired()
    
    const endpoint = `/planos/${idPlano}/produtos`
    return await post(endpoint, data, configs)  
}

export async function eliminateProduto (idPlano, idFotonovela) {
    await refreshJwtIfHasExpired()
    
    const endpoint = `/planos/${idPlano}/produtos/${idFotonovela}`
    return await remove(endpoint, configs)

}

