import { useState } from 'react'
import { Rating, ThinStar } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'

export default function LancamentoRating ({
  rating, readOnly = true, maxWidth = 100, activeFillColor = "#fca311", inactiveFillColor = "#fbf1a9", handleChange=null
}) {
  
  return (
    <Rating
      style={{
        maxWidth: maxWidth,
        padding: 0,
        paddingLeft: "5px",
      }}
      itemStyles={{
        itemShapes: ThinStar,
        activeFillColor: activeFillColor,
        inactiveFillColor: inactiveFillColor

      }}
      value={rating}
      readOnly={readOnly}
      onChange={handleChange}
    />
  );
}