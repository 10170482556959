import React, {useState} from "react"
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup"
import { useFormik } from "formik";

import "./style.css"
import {updatePassword} from "../../../services/client/subscritor-api"
import AppLogo from "./../../../components/app-logo"

import { Box, TextField } from "@mui/material";
import Loading from "../../../components/loading";
import Alert from "../components/alert";
import Success from "./sucess";

const validationSchema = Yup.object({
    password: Yup
        .string()
        .min(6, "6 caracteres no mínimo")
        .required("Campo obrigatório")
})

const ChangePasswordPage = () => {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const {id_subscritor} = useParams()

    const handleEnviar = e => {
        e.preventDefault()
        
        formik.submitForm()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await updatePassword(
                atob(id_subscritor),
                values
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div
            id="client-password-recovery"
        >
            <div
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    padding: ".5rem",
                    display: "grid",
                    placeItems: "center"
                }}
            >
                <AppLogo fontSize="1rem" />
            </div>
            
            {
                formSubmited && success
                    ? <Success
                        title="Sucesso!"
                        content="A sua password foi redefinida com sucesso"
                    />
                    : (
                        <div className="main">
                            <h2
                                style={{
                                    fontWeight: "800",
                                    color: "#1d3557",
                                    textAlign: "center"
                                }}
                            >
                                Redefinição da password
                            </h2>
                            <p
                                style={{
                                    fontWeight: "600",
                                    color: "#1d3557",
                                    textAlign: "center"
                                }}
                            >
                                Informe abaixo uma nova password
                            </p>
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                {submitingForm && <Loading text="Redefinindo a password..." />}
                                {!!error && <Alert message={error.message} severity="error"  />}
                                {!!success && <Alert message="Registo salvo!" severity="success" />}
                            </div>
                            <form 
                                method=""
                                action=""
                                style={{
                                    transition: "all 300ms ease",
                                    pointerEvents: submitingForm ? "none" : "all",
                                }}
                            >

                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Nova password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && !!formik.errors.password}
                                    helperText={formik.touched.password && formik.errors.password}
                                    variant="standard"
                                    sx={{
                                        mb: "1rem"
                                    }}
                                />

                                <button type="submit" className="btn primary" onClick={handleEnviar}>Enviar</button>
                                
                            </form>
                            {/* <Box
                                sx={{
                                    mt: "1rem",
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                }}
                            >
                                <Link
                                    to="/login"
                                    style={{color:"#1d3557"}}
                                >
                                    Voltar ao formulário de login
                                </Link>
                            </Box> */}
                        </div>
                    )
            }
            
        </div>
    )
}

export default ChangePasswordPage