
import "./style.css"

export default function Alert({message, severity, background = true}) {
    return (
        <div id="client-alert-component" className={`${severity} ${background ? "" : "no-background"}`} >
            <span className="material-symbols-outlined">error</span>
            <p>{message}</p>
        </div>
    )
}