import React, { createContext, useState } from "react"

export const AdminPaginationContext = createContext()

export const AdminPaginationContextProvider = ({children}) => {
    
    const [page, setPage] = useState(1)

    const updatePage = (page) => {
        setPage(page)
    }

    return (
        <AdminPaginationContext.Provider value={{page, updatePage}}>
            {children}
        </AdminPaginationContext.Provider>
    )
}