import React, {  } from "react"

import "./../data-table.css"

import Button from "../../components/button"

export default function DataTable({data, deleteBtnClickHandler}) {

    return (
            <div className="admin-data-table">
                <table>
                    <thead>
                        <tr
                            style={{backgroundColor: "#dfdcdc"}}
                        >
                            <th style={{width: "50px"}}>N/O</th>
                            <th>Titulo</th>
                            <th className="btns-operacao">Operação</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item, index) => (
                            <tr key={item.id_fotonovela} >
                                <td>{index + 1}</td>
                                <td>{item.titulo_fotonovela}</td>
                                <td
                                    className="btns-operacao"
                                >
                                    <Button
                                        text="Deletar"
                                        googleIconName="delete"
                                        crudOperation="delete"
                                        primary={false}
                                        size="small"
                                        clickHandler={deleteBtnClickHandler.bind(this, item)}
                                    />
                                </td>
            
                            </tr>
                        ))
                    }
                        
                    </tbody>
                </table>
                
            </div>
    )
}


