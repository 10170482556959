import React, {useState} from "react"

import "./style.css"

import FnButton from "./../../components/fn-button"
import ViewFotonovela from "../../../view-fotonovela"

export default function Chapters({fotonovela, idSubscritor}) {

    const [viewFotonovela, setViewFotonovela] = useState(false)
    const [initialChapterIndex, setInitialChapterIndex] = useState(0)

    const openViewFotonovela = (chapterIndex) => {
        setInitialChapterIndex(chapterIndex)

        if (fotonovela.capitulos.length < 1) return

        setViewFotonovela(true)
    }
   
    const closeViewFotonovela = () => {
        setViewFotonovela(false)
    }

    return (
        <>

            <table id="chapters">
                <thead>
                    <tr>
                        <th>N.</th>
                        <th>Titulo</th>
                        <th>Views</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fotonovela.capitulos.map( (capitulo, index) => (
                            <tr key={capitulo.id}>
                                <>
                                    {
                                        viewFotonovela &&
                                        <ViewFotonovela
                                            isOpen={viewFotonovela}
                                            handleClose={closeViewFotonovela}
                                            fotonovela={fotonovela}
                                            inicialChapterIndex={initialChapterIndex}
                                            idSubscritor={idSubscritor}
                                        />
                                    }
                                    <td>{index + 1}</td>
                                    <td>{capitulo.titulo}</td>
                                    <td>{capitulo.visualizacoes}</td>
                                    <td>
                                        {
                                            fotonovela.available && <FnButton handleClick={() => openViewFotonovela(index)} iconButton="icon-button" label="Visualizar"  icon={<span className="material-symbols-outlined">imagesmode</span>} />
                                        }
                                    </td>
                                </>
                            </tr>
                        ) )
                    }
                </tbody>
            </table>
        </>
        
    )
}