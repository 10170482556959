import { ResponsiveChoropleth } from '@nivo/geo'
import { usersPerCountry } from '../../../../data/data'
import { geoFeatures } from '../../../../data/data'

const GeographyChart = ({data}) => {

    return (
        <ResponsiveChoropleth
            data={data}
            features={geoFeatures.features}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            colors="nivo"
            domain={[ 0, 1000000 ]}
            unknownColor="#666666"
            label="properties.name"
            valueFormat=".2s"
            projectionScale={100}
            projectionTranslation={[0.49, 0.6]}
            projectionRotation={[ 0, 0, 0 ]}
            graticuleLineColor="#dddddd"
            borderWidth={0.3}
            borderColor="#152538"
            legends={[{
                        anchor: 'bottom-left',
                        direction: 'column',
                        justify: true,
                        translateX: 20,
                        translateY: -100,
                        itemsSpacing: 0,
                        itemWidth: 94,
                        itemHeight: 18,
                        itemDirection: 'left-to-right',
                        itemTextColor: "gray",
                        itemOpacity: 0.85,
                        symbolSize: 18,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }]
        }
    />
    )
}

export default GeographyChart