import React, { useEffect, useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { eliminate } from "../../../../services/admin/capitulo-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import Switch from '@mui/material/Switch';
import { Box, Divider, TextField } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

const validationSchema = Yup.object({})

export default function DeleteCapituloFormDialog({
    idCapitulo, closeFormHandler, afterSubmitHandler
}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [values, setValues] = useState({id: idCapitulo})

    useEffect(() => {
        setValues({id: idCapitulo})
    }, [idCapitulo])

    const submitHandler = async () => {
        
        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await eliminate(
                formik.values.id
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const closeHandler = () => {
        closeFormHandler();

        if (formSubmited) {
            afterSubmitHandler();
        }
    }


    const formik = useFormik({
        initialValues: values,
        validationSchema: validationSchema,
        // onSubmit: submitHandler
    })

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle
                    sx={{
                        m: 0,
                        p: 1,
                        fontWeight: "600",
                        textAlign: "center",
                    }}
                    id="customized-dialog-title"
                >
                    Deletar capítulo
                </DialogTitle>
                

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 0 16px"
                    }}
                >
                    {submitingForm && <Loading text="Deletando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo deletado!" severity="success" />}
                </div>

                <form
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "0 16px 16px 16px",
                        width: "360px"
                    }}
                >
                    {
                        formSubmited
                            ? (
                                <div
                                    style={{
                                        marginTop: "10px"
                                    }}
                                >
                                    <Button
                                        text="Fechar"
                                        googleIconName="close"
                                        crudOperation="cancel"
                                        primary={true}
                                        clickHandler={closeHandler}
                                        fullWidth={true}
                                        hideIcon={true}
                                    />
                                </div>
                                
                            ) 
                            : (
                                <>
                                    <p
                                        style={{
                                            padding: "10px",
                                            margin: "0 0 5px 0",
                                            fontWeight: "500",
                                            textAlign: "center",
                                        }}
                                    >
                                        <b>Esta operação não pode ser desfeita</b>. Serão igualmente deletados todos os registros associados a ele.
                                    </p>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "6px",
                                        
                                        }}
                                    >
                                        <Button
                                            text="Deletar"
                                            googleIconName="delete"
                                            crudOperation="delete"
                                            primary={true}
                                            clickHandler={submitHandler}
                                            fullWidth={true}
                                            hideIcon={true}
                                        />
                                        <Button
                                            text="Cancelar"
                                            googleIconName="close"
                                            crudOperation="cancel"
                                            primary={true}
                                            clickHandler={closeFormHandler}
                                            fullWidth={true}
                                            hideIcon={true}
                                        />
                                    </div>
                                </>
                            )
                    }
                    
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}