import React, { useContext, useEffect, useState } from "react"
import json2mq from "json2mq"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useMediaQuery } from "@mui/material"
import {format} from "date-fns"

import "./style.css"
import { baseURL } from "../../../services/api"
import { addFavorito, deleteFavorito } from "../../../services/client/subscritor-api"
import { AuthClientContext } from "./../../../contexts/auth-client"
import {calculateFotonovelaRating, getDecryptedDataFromLocalStorage} from "./../../../utils"

import Generos from "../../../components/lancamento/genero"
import LancamentoRating from "../../../components/lancamento-rating"
import ViewFotonovela from "../../view-fotonovela"
import Comment from "./comment"
import FnButton from "./../components/fn-button"
import Chapters from "./chapters"
import ViewFotonovelaDemo from "../../view-fotonovela/demo"

const getFotonovelaDemo = (fotonovela) => {

    if (fotonovela.capitulos.length < 1) return null
    if (!!fotonovela.demo["id"] === false) return null

    const idCapitulo = fotonovela.demo["id_capitulo"]
    const idPrimeiraCena = fotonovela.demo["id_primeira_cena"]
    const idUltimaCena = fotonovela.demo["id_ultima_cena"]

    const capituloDemo = fotonovela.capitulos.filter( capitulo => capitulo.id === idCapitulo )[0]
    const cenasDemo = capituloDemo.cenas.filter( cena => cena.id >= idPrimeiraCena && cena.id <= idUltimaCena )

    return {
        id: fotonovela.id,
        titulo: fotonovela.titulo,
        capitulo: {
            id: capituloDemo.id,
            titulo: capituloDemo.titulo
        },
        cenas: cenasDemo
    }
}

const DetalhesFotonovela = () => {

    const navigate = useNavigate()

    const params = useParams()
    
    const location = useLocation()
    const state = location.state

    const savedData = getDecryptedDataFromLocalStorage("fn_current-fn")

    if (!savedData) navigate("/painel-usuario/fotonovelas")
    
    const fotonovela = savedData // state.data => ja nao estamos pegando do state porque quando re-renderiza perde os dados
    const fotonovelaDemo = getFotonovelaDemo(fotonovela)

    const {user} = useContext(AuthClientContext)

    const [viewFotonovela, setViewFotonovela] = useState(false)
    const [viewFotonovelaDemo, setViewFotonovelaDemo] = useState(false)
    const [addingFavorito, setAddingFavorito] = useState(false)
    const [removingFavorito, setRemovingFavorito] = useState(false)
    const [error, setError] = useState(null)

    const matches = useMediaQuery(json2mq({
        maxWidth: 600
    }))

    const openViewFotonovela = () => {
        if (fotonovela.capitulos.length < 1) return

        setViewFotonovela(true)
    }
   
    const openViewFotonovelaDemo = () => {

        if (!!fotonovelaDemo === false) return

        setViewFotonovelaDemo(true)
    }
   
    const closeViewFotonovela = () => {
        setViewFotonovela(false)
    }
    
    const closeViewFotonovelaDemo = () => {
        setViewFotonovelaDemo(false)
    }

    

    const saveFavorito = async () => {
 
        setAddingFavorito(true)
        setError(null)

        try {
            await addFavorito( user.id, fotonovela.id )

            setAddingFavorito(false)

            fotonovela.favorite = true

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setAddingFavorito(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setAddingFavorito(false)

        }
        
    }
  
    const removeFavorito = async () => {
    
        setRemovingFavorito(true)
        setError(null)

        try {
            await deleteFavorito( user.id, fotonovela.id )

            setRemovingFavorito(false)

            fotonovela.favorite = false

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setRemovingFavorito(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setRemovingFavorito(false)

        }
        
    }

    return (
        <>
            {
                viewFotonovela 
                    ? (<ViewFotonovela
                        isOpen={viewFotonovela}
                        handleClose={closeViewFotonovela}
                        fotonovela={fotonovela}
                        idSubscritor={user.id}
                    />) : ""
            }
            {
                viewFotonovelaDemo 
                    ? (<ViewFotonovelaDemo
                        isOpen={viewFotonovelaDemo}
                        handleClose={closeViewFotonovelaDemo}
                        fotonovela={fotonovelaDemo}
                    />) : ""
            }

            <div id="detalhe-fotonovela">
                <div className="title">
                    <h1>{fotonovela.titulo}</h1>
                    <span
                        className="material-symbols-outlined"
                        style={{
                            color: fotonovela.available ? "#198754" : "#ccc"
                        }}
                    >
                        verified
                    </span>
                    <span
                        className="material-symbols-outlined"
                        style={{
                            color: fotonovela.favorite ? "#198754" : "#ccc"
                        }}
                    >
                        favorite
                    </span>
                </div>
                <div className="details-wrapper">
                    <img
                        src={`${baseURL}/uploads/fotonovelas/${fotonovela.id}/${fotonovela.img_capa}?nocache=${Date.now() * Math.random()}`}
                        alt={fotonovela.img_capa}
                    />
                    <div className="text">
                        <div >
                            <span className="title">Resumo</span>
                            <p>{fotonovela.descricao}</p>
                        </div>
                        <div >
                                <span className="title">Gêneros</span>
                                <Generos generos={fotonovela.generos}/>
                        </div >
                        <div >
                            <span className="title">Atualizado em</span>
                            <p> {format(new Date(fotonovela.updated_at.date), "dd/MM/yyyy")} </p>
                        </div >
                        <div>
                            <span className="title">Classificação </span>
                            <LancamentoRating rating={calculateFotonovelaRating(fotonovela.avaliacoes)} readOnly={true} />
                        </div>
                        <div>
                            {
                                fotonovela.available 
                                    ? <FnButton handleClick={openViewFotonovela} label="Visualizar" variant="primary" size="big"  icon={<span className="material-symbols-outlined">imagesmode</span>} />
                                    : <FnButton handleClick={openViewFotonovelaDemo} label="Visualizar Demo" variant="primary" size="big"  icon={<span className="material-symbols-outlined">imagesmode</span>} />
                            }
                            {
                                fotonovela.favorite 
                                    ? <FnButton handleClick={removeFavorito} inactive={ removingFavorito ? "inactive" : "" } label="Favorito" size="big" danger="danger" icon={<span className="material-symbols-outlined">heart_minus</span>} />
                                    : <FnButton handleClick={saveFavorito} inactive={ addingFavorito ? "inactive" : "" } label="Favorito" size="big" okay="okay" icon={<span className="material-symbols-outlined">heart_plus</span>} />
                            }
                            
                        </div>
                        
                    </div>
                </div>

                <div className="chapters">
                    <h1>Capítulos</h1>
                    {
                        fotonovela.capitulos.length > 0 &&
                        <Chapters fotonovela={fotonovela} idSubscritor={user.id} />
                    }

                    {
                        fotonovela.capitulos.length < 1
                        && <div
                            className="flex-row"
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                gap:"3px",
                                color: "#bababa",
                                height: "100%"
                            }}>
                            <span className="material-symbols-outlined" style={{color: "#bababa", fontSize: "15px"}}>
                                error
                            </span>
                            <small style={{color: "#bababa",}}>Nenhum capítulo encontrado</small>
                        </div>
                    }
                </div>

                <div className="comments">
                    <h1>Comentários</h1>
                    <div>
                        {
                            fotonovela.avaliacoes.map( comentario => <Comment key={comentario.id} data={comentario} /> )
                        }
                        {
                            fotonovela.avaliacoes.length < 1
                            && <div
                                className="flex-row"
                                style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    gap:"3px",
                                    color: "#bababa",
                                    width: "100%",
                                }}>
                                <span className="material-symbols-outlined" style={{color: "#bababa", fontSize: "15px"}}>
                                    error
                                </span>
                                <small style={{color: "#bababa",}}>Sem comentários de momento</small>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </>
       
    )
}

export default DetalhesFotonovela