import React, { useState, useEffect, useContext, } from 'react';

import "./../pages.css"
import {getAll} from "../../../../services/admin/usuario-api"
import {AdminPaginationContext} from "../../../../contexts/admin-pagination"
import {AuthAdminContext} from "./../../../../contexts/auth-admin"

import Button from "../../components/button"
import SearchField from "../../components/search-field"
import AdminPagination from "../../components/pagination"
import UsuarioDataTable from "./data-table"
import UsuarioCreateFormDialog from "./create-form-dialog";
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';

export default function AdminUsuarios () {

    const [formOpen, setFormOpen] = useState(false);
    const [usuariosAdmins, setUsuariosAdmins] = useState([])
    const [totalUsuariosAdmins, setTotalUsuariosAdmins] = useState(0)
    const [paginationCount, setPaginationCount] = useState(1)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}
    const [searchField, setSearchField] = useState("")

    const {user} = useContext(AuthAdminContext)
    const {page} = useContext(AdminPaginationContext)

    useEffect(function(){
        fetchUsuarios()
    }, [page])

    const openForm = () => {
        setFormOpen(true)
    }

    const closeForm = () => {
        setFormOpen(false);
    };

    const fetchUsuarios = async () => {
 
        setLoadingUsers(true)
        setError(null)

        try {
            const {usuarios_admins, total} =  await getAll(10, page, searchField)

            setUsuariosAdmins(usuarios_admins)

            setTotalUsuariosAdmins(total)

            setPaginationCount(
                total > 0 ? Math.round( total / 10) : 1
            )

            setLoadingUsers(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingUsers(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingUsers(false)

        }
        
    }

    return (
        <>
        {
            formOpen ? (<UsuarioCreateFormDialog closeFormHandler={closeForm} afterSubmitHandler={fetchUsuarios} />) : ""
        }
        <div id="admin-usuarios-page" className="page">
            <h1>Usuários</h1>
            <div>
                <div>
                    <SearchField
                        placeholder="Pesquisar por email..."
                        onClickHandler = {fetchUsuarios}
                        onChangeHandler = {(e) => setSearchField(e.target.value)}
                    />
                    {
                        user.is_super
                            ? (
                                <Button
                                    text="Adicionar novo"
                                    googleIconName="add"
                                    crudOperation="create"
                                    type="submit"
                                    primary={true}
                                    hideIcon={true}
                                    clickHandler={openForm}
                                />
                            )
                            : null
                    }
                   
                </div>

                <>
                    {
                        loadingUsers
                        && <div
                            style={{
                                backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                boxShadow: "10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1)"
                            }}  
                        >
                                <Loading text="Carregando os dados..." />
                            </div>
                    }
                    {!!error && <Alert message={error.message} severity="error" />}
                </>
                {
                    !loadingUsers && Boolean(error) === false
                    ? (
                        <UsuarioDataTable
                            data = {usuariosAdmins}
                            loggedUser={user}
                            afterSubmitHandler={fetchUsuarios}
                        />
                    )
                    : null
                }
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <span
                        style={{marginRight: "20px", fontSize: "12px", fontWeight: "500"}} 
                    >
                        {`${ usuariosAdmins.length } de ${totalUsuariosAdmins} registos encontrados`}
                    </span>
                    
                    <AdminPagination
                        count={ paginationCount === 0 ? 1 : paginationCount }
                        route="/painel-administrador/usuarios"
                    />
                </div>
            </div>
        </div>
        </>
    )
}