import "./style.css"

export default function Button ({
    text = "Button",
    googleIconName = "add",
    crudOperation = "create",
    size = "normal",
    fullWidth = false,
    type = "button",
    disabled = false,
    primary = true,
    hideIcon = false,
    clickHandler
}) {
    return (
        <button
            id="admin-button"
            type={type}
            disabled={disabled}
            className={`${crudOperation} ${size} ${fullWidth ? "fullWidth" : ""} ${disabled ? "disabled" : ""} ${primary ? "primary" : ""} ${hideIcon ? "hide-icon" : ""}`}
            onClick={clickHandler}
        >
            <span className="material-symbols-outlined">{googleIconName}</span>
            <span>{text}</span>
        </button>
    )
}