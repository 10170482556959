import React, { createContext, useState } from "react"

export const ClientPaginationContext = createContext()

export const ClientPaginationContextProvider = ({children}) => {
    
    const [page, setPage] = useState(1)

    const updatePage = (page) => {
        setPage(page)
    }

    return (
        <ClientPaginationContext.Provider value={{page, updatePage}}>
            {children}
        </ClientPaginationContext.Provider>
    )
}