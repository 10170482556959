
import { get, post, remove, patch, } from "../api"

import {refreshJwtIfHasExpired} from "./usuario-api"


const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

export async function getAll(limit, page, email="") {

    await refreshJwtIfHasExpired()

    const endpoint = "/subscritores"

    const configsModified = {
        ...configs, params: {
            limit,
            page,
            email,
        }
    }

    const data = await get(endpoint, configsModified)

    return data
    
}

export async function getAllSubscricoes(idSubscritor) {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${idSubscritor}/subscricoes`

    const data = await get(endpoint, configs)

    return data
    
}

export async function resendConfirmationEmail(idSubscritor) {

    await refreshJwtIfHasExpired()

    const endpoint = `/subscritores/${idSubscritor}/resend-confirmation-email`

    const data = await post(endpoint, {}, configs)

    return data
    
}


