
import React from "react"
import { ImagePicker } from 'react-file-picker'
import "./style.css"
import Alert from "../../../../components/alert"
import { useState } from "react"
import Button from "../button"

export default function CustomImagePicker({extensions=[], dimensions, changeHandler, what = "o ficheiro"}) {

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [image, setImage] = useState(null)

    const onErrorHandler = (errorMessage) => {
        setError(true)
        setErrorMessage(errorMessage)

        setImage(null)

        changeHandler(null)
    }

    const onChangeHandler = (base64) => {
        setImage(base64)

        setError(false)
        setErrorMessage("")

        changeHandler(base64)
    }

    return <div
                id="custom-image-picker"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "stretch"
                    }}
                >
                    <div>
                        <ImagePicker
                            extensions={extensions}
                            dims={dimensions}
                            onChange={onChangeHandler}
                            onError={onErrorHandler}
                        >
                            <button type="button">
                                <span className="material-symbols-outlined">cloud_upload</span>
                                <span>Clique para buscar {what}</span>
                                
                            </button>
                        </ImagePicker>
                    </div>
                    {!!image && <div>
                         <img src={image} width="60px" /> 
                    </div> }
                    {!!image && <div>
                         <Button
                            text=""
                            googleIconName="close"
                            crudOperation="cancel"
                            primary={false} size="small"
                            clickHandler={() => {setImage(null); changeHandler(null)}}
                        />
                    </div>}

                    
                </div>
                
                {error && <Alert message={errorMessage} severity="error" />}
            </div>
}