import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { useMediaQuery } from "@mui/material"
import json2mq from "json2mq"

import { update } from '../../../services/client/subscritor-api';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from './../../admin/components/button';
import Loading from "./../../../components/loading"
import Alert from "./../../../components/alert"
import Switch from '@mui/material/Switch';
import { Box, Divider, TextField } from '@mui/material';

const validationSchema = Yup.object({
    "primeiro_nome": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(15, "15 caracteres no máximo")
        .required("Campo obrigatório"),
    "ultimo_nome": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(15, "15 caracteres no máximo")
        .required("Campo obrigatório"),
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function AlterPersonalDataFormDialog({closeFormHandler, afterSubmitHandler, initialValues}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const matchesMax480 = useMediaQuery(
        json2mq({
            maxWidth: 480
        })
    )
    
    const matchesMax361 = useMediaQuery(
        json2mq({
            maxWidth: 361
        })
    )

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()

    }

    const submitForm = async (values) => {
        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await update(
                initialValues.id,
                {
                    primeiro_nome: values.primeiro_nome,
                    ultimo_nome: values.ultimo_nome,
                }
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Alterar dados pessoais
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                        width: matchesMax361 ? "250px" : ( matchesMax480 ? "300px" : "400px")
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                        }}
                    >
                        <small>*As alterações terão efeitos no próximo início de sessão.</small>
                        <TextField
                            fullWidth
                            id="primeiro_nome"
                            name="primeiro_nome"
                            label="Primeiro Nome"
                            size="small"
                            value={formik.values['primeiro_nome']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['primeiro_nome'] && Boolean(formik.errors['primeiro_nome'])}
                            helperText={formik.touched['primeiro_nome'] && formik.errors['primeiro_nome']}
                        />
                    
                        <TextField
                            fullWidth
                            id="ultimo_nome"
                            name="ultimo_nome"
                            label="Ultimo Nome"
                            size="small"
                            value={formik.values['ultimo_nome']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['ultimo_nome'] && Boolean(formik.errors['ultimo_nome'])}
                            helperText={formik.touched['ultimo_nome'] && formik.errors['ultimo_nome']}
                        />

                    </div>

                    <Button
                        text="Salvar o registo"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={formik.submitForm}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}