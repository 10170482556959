import React from "react-transition-group"
import { Link } from "react-router-dom"

import "./style.css"

const Btn = ({
    isPrimary = false,
    text = "",
    stretch=false,
    borderRadius = "20px",
    size = "medium",
    onClickHandler = null
}) => {
    return (
        <div
            id="btn-wrapper"
            onClick={onClickHandler}
            style={{
                width: `${stretch ? "100%" : "fit-content"}`,
                userSelect: "none"
            }}
        >
            <Link
                className={isPrimary ? "primary" : ""}
                style={{
                    display: "block",
                    borderRadius: borderRadius,
                    padding: "0.4rem 1.5rem",
                    fontSize: `${size === "large" ? "1rem" : "0.8rem"}`,

                }}
            >
                {text}
            </Link>
        </div>
        
    )
}

export default Btn