import { jwtDecode } from "jwt-decode";

import {api, get, post, patch, remove, options} from "./../api"
import {
    saveEncryptedDataInLocalStorage,
    getDecryptedDataFromLocalStorage
} from "./../../utils"

const configs = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
}

export async function signIn (email, password) {
    const endpoint = "/auth/sign-in-usuarios-admins"
    const data = {email, password}

    return await post(endpoint, data, configs)
    
}

export async function refreshJwt () {
    const endpoint = "/auth/refresh-usuarios-admins"
    const data = {}

    const loggedUser = getDecryptedDataFromLocalStorage("fn_user")
    const savedToken = getDecryptedDataFromLocalStorage("fn_token")

    if (!loggedUser || !savedToken) { 
        return Promise.reject(
            JSON.stringify({code: 0, message: "Token inválido"})
        )
        
    }

    try {
        const responseData = await post(endpoint, data, configs)

        const jwtDecoded = jwtDecode(responseData.jwt)
        const userData = jwtDecoded.data
        const token = responseData.jwt

        saveEncryptedDataInLocalStorage("fn_user", userData)
        saveEncryptedDataInLocalStorage("fn_token", token)

        api.defaults.headers.Authorization = `Bearer ${token}`

    } catch (error) {
        return Promise.reject(error)
    }

}

export async function verifyToken (jwt) {
    const endpoint = "/auth/verify-usuarios-admins"

    api.defaults.headers.Authorization = `Bearer ${jwt}`

    try {
        await options(endpoint)

        return true
    } catch (error) {
        return Promise.reject(
            JSON.stringify({code: 0, message: "Token inválido"})
        )
    }
    
}

export const refreshJwtIfHasExpired = async () => {
    
    const savedToken = getDecryptedDataFromLocalStorage("fn_token")

    if (savedToken) {
        api.defaults.headers.Authorization = `Bearer ${savedToken}`
    }

    try {
        await verifyToken(savedToken)

    } catch (error) {
        await refreshJwt()
    }
}

export async function create (data) {
    await refreshJwtIfHasExpired()
    
    const endpoint = "/usuarios-admins"
    return await post(endpoint, data, configs)
   
}

export async function update (id, data) {
    await refreshJwtIfHasExpired()

    const endpoint = `/usuarios-admins/${id}`
    
    return await patch(endpoint, data, configs)
   
}

export async function eliminate (id) {
    await refreshJwtIfHasExpired()

    const endpoint = `/usuarios-admins/${id}`
    
    return await remove(endpoint, configs)
   
}

export async function getAll(limit, page, email="") {

    await refreshJwtIfHasExpired()

    const endpoint = "/usuarios-admins"

    const configsModified = {
        ...configs, params: {
            limit,
            page,
            email,
        }
    }

    const data = await get(endpoint, configsModified)    

    return data
    
}