import React, { useState, useEffect, useContext, } from 'react';

import "./../pages.css"
import {getAll} from "../../../../services/admin/periodo-api"

import Button from "../../components/button"
import DataTable from "./data-table"
import CreateFormDialog from "./create-form-dialog";
import Loading from '../../../../components/loading';
import Alert from '../../../../components/alert';

export default function Periodo () {

    const [formOpen, setFormOpen] = useState(false);
    const [periodos, setPeriodos] = useState([])
    const [totalPeriodos, setTotalPeriodos] = useState(0)
    const [loadingPeriodos, setLoadingPeriodos] = useState(false)
    const [error, setError] = useState(null) // <--- error={message:string}

    useEffect(function(){
        fetchData()
    }, [])

    const openForm = () => {
        setFormOpen(true)
    }

    const closeForm = () => {
        setFormOpen(false);
    };

    const fetchData = async () => {
 
        setLoadingPeriodos(true)
        setError(null)

        try {
            const {periodos, total} =  await getAll()

            setPeriodos(periodos)

            setTotalPeriodos(total)

            setLoadingPeriodos(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingPeriodos(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingPeriodos(false)

        }
        
    }

    return (
        <>
        {
            formOpen ? (<CreateFormDialog closeFormHandler={closeForm} afterSubmitHandler={fetchData} />) : ""
        }
        <div id="periodo-page" className="page">
            <h1>Periodos</h1>
            <div>
                <div>
                    {
                        <Button
                            text="Adicionar novo"
                            googleIconName="add"
                            crudOperation="create"
                            type="submit"
                            primary={true}
                            hideIcon={true}
                            clickHandler={openForm}
                        />
                    }
                   
                </div>

                <>
                    {
                        loadingPeriodos
                        && <div
                            style={{
                                backgroundColor: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                boxShadow: "10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1)"
                            }}  
                        >
                                <Loading text="Carregando os dados..." />
                            </div>
                    }
                    {!!error && <Alert message={error.message} severity="error" />}
                </>
                {
                    !loadingPeriodos && Boolean(error) === false
                    ? (
                        <DataTable
                            data = {periodos}
                            afterSubmitHandler={fetchData}
                        />
                    )
                    : null
                }
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"
                    }}
                >
                    <span
                        style={{marginRight: "20px", fontSize: "12px", fontWeight: "500"}} 
                    >
                        {`${totalPeriodos} registos encontrados`}
                    </span>
                    
                </div>
            </div>
        </div>
        </>
    )
}