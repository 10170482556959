import React, { useState, useEffect, useContext, } from 'react';

import "./style.css"
import {saveEncryptedDataInLocalStorage, getDecryptedDataFromLocalStorage} from '../../../../utils';
import { AuthAdminContext } from '../../../../contexts/auth-admin';

import AlterarNomesFormDialog from "./alter-nomes-form-dialog"
import AlterPasswordFormDialog from './alter-password-form-dialog';

export default function AdminUserProfile () {

    const [alterNomesFormOpen, setAlterNomesFormOpen] = useState(false);
    const [alterPasswordFormOpen, setAlterPasswordFormOpen] = useState(false);

    const {user} = useContext(AuthAdminContext)

    const openAlterNomesFormOpen = () => {
        setAlterNomesFormOpen(true)
    }

    const closeAlterNomesFormOpen = () => {
        setAlterNomesFormOpen(false);
    };

    const openAlterPasswordFormOpen = () => {
        setAlterPasswordFormOpen(true)
    }

    const closeAlterPasswordFormOpen = () => {
        setAlterPasswordFormOpen(false);
    };

    const fetchUsuarios = async () => {
 
        // setLoadingUsers(true)
        // setError(null)

        // try {
        //     const {usuarios_admins, total} =  await getAll(10, page, searchField)

        //     setUsuariosAdmins(usuarios_admins)

        //     setTotalUsuariosAdmins(total)

        //     setPaginationCount(
        //         total > 0 ? Math.round( total / 10) : 1
        //     )

        //     setLoadingUsers(false)

        // } catch (error) {
        //     const reason = JSON.parse(error)

        //     if (reason.code !== 400) {
        //         setLoadingUsers(false)
        //         setError({message: `Ooops! Erro inesperado`})
 
        //         return
        //     }

        //     setError({message: reason?.message})
        //     setLoadingUsers(false)

        // }
        
    }

    return (
        <>
            {
                alterNomesFormOpen ? (<AlterarNomesFormDialog initialValues={user} closeFormHandler={closeAlterNomesFormOpen} afterSubmitHandler={fetchUsuarios} />) : ""
            }
            {
                alterPasswordFormOpen ? (<AlterPasswordFormDialog  initialValues={user} closeFormHandler={closeAlterPasswordFormOpen} afterSubmitHandler={fetchUsuarios} />) : ""
            }
            <div id="admin-user-profile-page">
                <h1>Meu Perfil</h1>
                <div>
                    <section>
                        <span className="material-symbols-outlined">account_circle</span>
                        <div className="flex-row">
                            <h3>{`${user.primeiro_nome} ${user.ultimo_nome}`}</h3>
                            <span className="material-symbols-outlined" onClick={openAlterNomesFormOpen}>edit</span>
                        </div>
                        <p>{user.email}</p>
                        <div className="flex-row">
                            <p>Password: *****</p>
                            <span className="material-symbols-outlined" onClick={openAlterPasswordFormOpen}>edit</span>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}