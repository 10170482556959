import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { useMediaQuery } from "@mui/material"
import json2mq from "json2mq"

import {subscribe} from "./../../../services/client/subscritor-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, TextField } from '@mui/material';
import Loading from "../.../../../../components/loading"
import Alert from "../.../../../../components/alert"
import CustomFilePicker from "./../../../components/file-picker"
import InputSelectOne from "../../admin/components/input-select-one"
import Btn from "../../../components/btn"

const validationSchema = Yup.object({})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

const formatCurrency = (number) => new Intl.NumberFormat( "ao-AO", {style: "currency", currency: "AOA"} ).format(number)

const defaultPlano = {
    id: 0,
    nome: "",
    preco: 0,
    periodo: {qtde_dias: 0}
}

export default function SubscribeFormDialog({planos=[], loggedUser, closeFormHandler, afterSubmitHandler, }) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [selectedPlano, setSelectedPlano] = useState(defaultPlano)
    const [valor, setValor] = useState("")
    const [duracao, setDuracao] = useState("")
    const [base64File, setBase64File] = useState(null)

    const matchesMax480 = useMediaQuery(
        json2mq({
            maxWidth: 480
        })
    )
    
    const matchesMax361 = useMediaQuery(
        json2mq({
            maxWidth: 361
        })
    )

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        if (selectedPlano.id === 0) {
            setError({message: `Nenhum plano selecionado`})
            return
        }
       
        if (duracao == "0 dias") {
            setError({message: `Nenhum plano selecionado`})
            return
        }
       
        if (!base64File) {
            setError({message: `Nenhum comprovante carregado`})
            return
        }

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await subscribe({
                id_plano: selectedPlano.id,
                id_subscritor: loggedUser.id,
                valor_pago: selectedPlano.preco,
                cambio: 1,
                comprovante: base64File    
            })

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const inputSelectOptions = planos.map( plano => plano.nome )

    const inputSelectChangeHandler = (event) => {
        const {
            target: { value },
            } = event;

            const planoFiltered = planos.filter( plano => plano.nome === value )[0]
    
            setSelectedPlano(planoFiltered)

            setValor(
                formatCurrency( planoFiltered.preco )
            )

            setDuracao(
                `${planoFiltered.periodo.qtde_dias} dias`
            )
    };


    const handleFilePickerChange = (base64) => {
        setBase64File(base64)
    }

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    return (
        <div >
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#eee" }} id="customized-dialog-title">
                    Nova subscrição
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {
                        !!success && (
                            <>
                                <Alert message="Subscrição enviada!" severity="success" />
                                <p style={{textAlign: "left", marginTop: "10px"}}>*Por favor, aguarde pela validação da subscrição</p>
                            </>
                            )
                    }
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                        width: matchesMax361 ? "250px" : ( matchesMax480 ? "300px" : "400px")
                    }}
                >

                    {
                        !!formSubmited === false &&
                        (
                            <>
                                <div
                                    style={{
                                        marginBottom: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "stretch",
                                        gap: "10px",
                                        width: "100%"
                                    }}
                                >
                                    <InputSelectOne
                                        placeholder="Plano" onChangeHandler={inputSelectChangeHandler} options={inputSelectOptions} initialValue=""
                                    />

                                    <TextField
                                        fullWidth
                                        id="valor"
                                        name="valor"
                                        label="Valor a pagar"
                                        size="small"
                                        disabled
                                        value={valor}
                                    />

                                    <TextField
                                        fullWidth
                                        id="duracao"
                                        name="duracao"
                                        label="Duração"
                                        size="small"
                                        disabled
                                        value={duracao}
                                    />
                                
                                    
                                    <Box>
                                        <CustomFilePicker
                                            extensions={['.pdf, image/png, image/jpg, image/jpeg']}
                                            changeHandler={ handleFilePickerChange }
                                            what="o comprovante"
                                        />
                                    </Box>

                                </div>

                                <Btn size="large" isPrimary={true} text="Enviar" stretch={true} borderRadius="5px" onClickHandler={formik.submitForm} />
                                
                            </>
                        )
                    }
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}