import React, { useEffect, useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { update } from "../../../../services/admin/fotonovela-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import { Box, Divider, TextField } from '@mui/material';
import CustomImagePicker from '../../components/image-picker';
import InputSelect from "./../../components/input-select"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

const validationSchema = Yup.object({
    "titulo": Yup
        .string()
        .min(2, "2 caracteres no mínimo")
        .max(30, "30 caracteres no máximo")
        .required("Campo obrigatório"),
    "descricao": Yup
        .string()
        .min(5, "5 caracteres no mínimo")
        .max(150, "150 caracteres no máximo")
        .required("Campo obrigatório"),
})

export default function UpdateFormDialog({
    initialValues, closeFormHandler, afterSubmitHandler
}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [values, setValues] = useState(initialValues)

    useEffect(() => {
        setValues(values)
    }, [initialValues])

    const submitHandler = async () => {
        if (generos.length > 3) {
            setError({message: "Selecione no máximo 3 gêneros"})
            return
        }
        
        if (
            Object.values(formik.errors).length > 0
        ) {
            return 
        }

        let strGeneros = ""

        if (generos.length < 1) {
            strGeneros = ""
        } else {
            strGeneros = generos.reduce( (pv, cv) => {
                return `${pv},${cv}`
            }, "" )

            strGeneros = strGeneros.substring(1)
        }

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        const {titulo, descricao} = formik.values
        
        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        try {
            await update(
                formik.values.id,
                {
                    titulo,
                    descricao,
                    generos: strGeneros,
                    imgCapaBase64: base64Image    
                }
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const closeHandler = () => {
        closeFormHandler();

        if (formSubmited) {
            afterSubmitHandler();
        }
    }

    const [generos, setGeneros] = useState([]);

    const inputSelectOptions = [
            "Ação",
            "Aventura",
            "Drama",
            "Comédia",
            "Romântica",
            "Ficção",
            "Terror",
        ];

    const inputSelectChangeHandler = (event) => {
        const {
        target: { value },
        } = event;

        setGeneros(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [base64Image, setBase64Image] = useState(null)

    const imagePickerChangeHandler = (base64) => {
        setBase64Image(base64)
    }


    const formik = useFormik({
        initialValues: values,
        validationSchema: validationSchema,
        // onSubmit: submitHandler
    })

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Alterar fotonovela
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Alterando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo alterado!" severity="success" />}
                </div>

                <form
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                            width: "400px"
                        }}
                    >
                        <TextField
                            fullWidth
                            id="titulo"
                            name="titulo"
                            label="Título"
                            size="small"
                            value={formik.values['titulo']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['titulo'] && Boolean(formik.errors['titulo'])}
                            helperText={formik.touched['titulo'] && formik.errors['titulo']}
                        />
                        
                        <InputSelect placeholder="Gêneros" onChangeHandler={inputSelectChangeHandler} options={inputSelectOptions} />
                        
                        <Box>
                            <CustomImagePicker
                                extensions={['jpg', 'jpeg', 'png']}
                                dimensions={{
                                    minWidth: 100,
                                    // maxWidth: 500,
                                    minHeight: 100, 
                                    // maxHeight: 500
                                }}
                                changeHandler={ imagePickerChangeHandler }
                                what='a imagem de capa'
                            />
                        </Box>
                        

                        <TextField
                            fullWidth
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            size="small"
                            multiline={true}
                            value={formik.values['descricao']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['descricao'] && Boolean(formik.errors['descricao'])}
                            helperText={formik.touched['descricao'] && formik.errors['descricao']}
                        />

                    </div>

                    <Button
                        text="Salvar o registo"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={submitHandler}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}