import React, {useState, useContext} from "react"
import { Link } from "react-router-dom";

import "./style.css"
import AppLogo from "./../../../components/app-logo"
import { AuthClientContext } from "../../../contexts/auth-client";

import { Box, Button, TextField } from "@mui/material";
import Alert from "../components/alert";
import Loading from "../../../components/loading";

const LoginClient = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [error, setError] = useState(null)

    const [loading, setLoading] = useState(false)

    const {login} = useContext(AuthClientContext)


    const submitHandler = async (e) => {
        e.preventDefault()

        setLoading(true)
        setError(null)

        try {
            await login(email, password)

            setLoading(false)
        } catch(error) {
            const reason = JSON.parse(error)

            if (reason.code == 401) {
                setError({message: "Credenciais inválidas"})
                setLoading(false)
                return
            }

            setError({message: `Ooops! Erro inesperado. Tente novamente`})
            setLoading(false)
        }
    }

    return (
        <div id="login-client">
            <div
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    padding: ".5rem",
                    display: "grid",
                    placeItems: "center"
                }}
            >
                <AppLogo fontSize="1rem" />
            </div>
            <div className="main" >
                <h1
                    style={{
                        fontWeight: "800",
                        color: "#1d3557",
                        textAlign: "center"
                    }}
                >
                    Login
                </h1>
                <p
                    style={{
                        fontWeight: "600",
                        color: "#1d3557",
                        textAlign: "center"
                    }}
                >
                    Entre com o seu email e password
                </p>

                <div
                    style={{
                        margin: "10px 0"
                    }}
                >
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!loading && <Loading text="Verificando as credenciais..." gifSize="15px"  />}
                </div>

                <form method="" action="">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        type="text"
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            mb: "1rem"
                        }}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            mb: "2rem"
                        }}
                    />

                    <button type="submit" className="primary" onClick={submitHandler}>Entrar</button>

                    <Link
                        to="/recuperacao-password"
                        style={{
                            display: "block",
                            width: "100%",
                            textAlign: "center",
                            marginTop: "1rem",
                        }}>
                            Esqueci a password
                    </Link>
                </form>
                <Box
                    sx={{
                        mt: "5rem",
                        display: "flex", justifyContent: "center", alignItems: "center"
                    }}
                >
                    <p style={{marginRight: "0.8rem", color: "#495057"}}>Não tens uma conta?</p>
                    <Link
                        to="/registrar-se"
                        style={{color:"#1d3557"}}
                    >
                        Registrar-se
                    </Link>
                </Box>
                
            </div>
        </div>
    )
}

export default LoginClient