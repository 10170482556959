import Btn from "../../../../components/btn"
import "./style.css"

const PerfilBox = ({show = false, userData, handleSair, handleOpenUserProfilePage}) => {
    return (
        <div id="perfil-box" className={`${show ? "show" : ""}`}>
            <div className="header">
                <h1>{ `${userData.primeiroNome} ${userData.ultimoNome}` }</h1>
                <h3>{ userData.email }</h3>
            </div>
            <div className="items flex-column">
                <a href="#" className="flex-row" onClick={(e) => {e.preventDefault(); handleOpenUserProfilePage()}}>
                    <span className="material-symbols-outlined">person</span>
                    <span>Minha conta</span>
                </a>
                <a
                    href="#"
                    className="flex-row"
                    onClick={(e) => {e.preventDefault(); handleSair()}}
                >
                    <span className="material-symbols-outlined">logout</span>
                    <span>Sair</span>
                </a>
                {/* <a href="#" className="flex-row">
                    <span className="material-symbols-outlined">shield</span>
                    <span>Segurança</span>
                </a>
                <a href="#" className="flex-row">
                    <span className="material-symbols-outlined">notifications</span>
                    <span>Notificações</span>
                </a>
                <a href="#" className="flex-row">
                    <span className="material-symbols-outlined">help</span>
                    <span>Obter Ajuda</span>
                </a> */}
            </div>
        </div>
    )
}


export default PerfilBox