import React, { useState } from 'react';
import * as Yup from "yup"
import { useFormik } from 'formik';

import { addProduto } from "../../../../services/admin/plano-api"

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/button';
import Loading from "../../../../components/loading"
import Alert from "../../../../components/alert"
import { Divider } from '@mui/material';
import InputSelectOne from "./../../components/input-select-one"

const validationSchema = Yup.object({})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));

export default function AddFotonovelaFormDialog({idPlano, closeFormHandler, afterSubmitHandler, fotonovelas}) {

    const [submitingForm, setSubmitingForm] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [idFotonovela, setIdFotonovela] = useState(0)

    const closeHandler = () => {
        if (formSubmited) {
            afterSubmitHandler()
        }

        closeFormHandler()
    }

    const submitForm = async (values) => {

        setSubmitingForm(true)
        setError(null)
        setSuccess(false)

        const idFotonovelaSelected = idFotonovela !== 0 ? idFotonovela.split(" ")[0] : idFotonovela

        try {
            await addProduto(
                idPlano,
                {id_fotonovela: idFotonovelaSelected}
            )

            setSuccess(true)

            setSubmitingForm(false)

            setFormSubmited(true)
        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setError({message: `Ooops! Erro inesperado. Tente novamente`})
                setSubmitingForm(false)
                return
            }

            setError({message: reason?.message})
            setSubmitingForm(false)
        }
          
    }

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        onSubmit: submitForm
    })

    const fotonovelaSelectChangeHandler = (event) => {
        const {
        target: { value },
        } = event;

        setIdFotonovela(value)
    };

    const fotonovelaOptions = fotonovelas.map( fn => `${fn.id} - ${fn.titulo}` )

    return (
        <div>
            <BootstrapDialog
                onClose={closeHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Adicionar fotonovela
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider/>

                <div
                    style={{
                        margin: "10px 16px 6px 16px"
                    }}
                >
                    {submitingForm && <Loading text="Salvando o registo..." />}
                    {!!error && <Alert message={error.message} severity="error" />}
                    {!!success && <Alert message="Registo salvo!" severity="success" />}
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    style={{
                        pointerEvents: submitingForm ? "none" : "auto",
                        margin: "10px 16px 16px 16px",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            gap: "10px",
                            width: "400px"
                        }}
                    >
                        <InputSelectOne placeholder="Fotonovela" onChangeHandler={fotonovelaSelectChangeHandler} options={fotonovelaOptions} initialValue="" />

                    </div>

                    <Button
                        text="Salvar o registo"
                        googleIconName="save"
                        crudOperation="create"
                        primary={true}
                        clickHandler={formik.submitForm}
                        fullWidth={true}
                        hideIcon={true}
                    />
                    
                </form>

            </BootstrapDialog>
        </div>
    );
}