
import Welcome from "./welcome"
import "./style.css"
import HomeContent from "./content"

const Home = () => {
    return (
        <div id="home">
            <Welcome />
            <HomeContent />
        </div>
    )
}

export default Home