
import React, { useContext } from "react"
import { AuthAdminContext } from "./contexts/auth-admin"
import AdminLoadingPage from "./scenes/admin/pages/loading-page"
import { Navigate } from "react-router-dom"

export default function AdminPrivateRoute({children}){

    const {signedIn, loading} = useContext(AuthAdminContext)

    if (loading) {
        return <AdminLoadingPage text="Carregando a página..." />
    }

    if (!signedIn) {
        return <Navigate to="/painel-administrador/login" />
    }

    return (
        <>
            {children}
        </>
    )
}

