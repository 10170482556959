import React, {useState, useEffect, useContext} from "react"

import { getAllFotonovelas } from "../../../../services/client/subscritor-api"
import {AuthClientContext} from "./../../../../contexts/auth-client"

import Lancamento from "../../../../components/lancamento"
import ClientPagination from "./../../components/pagination"
import { ClientPaginationContext } from "../../../../contexts/client-pagination"
import Loading from "./../../../../components/loading"
import {calculatePaginationTotalPages} from "../../../../utils"

const Todos = () => {

    const {user} = useContext(AuthClientContext)

    const [lancamentos, setLancamentos] = useState([])
    const [totalLancamentos, setTotalLancamentos] = useState(0)
    const [loadingFotonovelas, setLoadingFotonovelas] = useState(false)
    const [error, setError] = useState(null)
    const [paginationCount, setPaginationCount] = useState(1)

    const {page} = useContext(ClientPaginationContext)

    useEffect(() => {

        fetchFotonovelas()

    },[page])

    const fetchFotonovelas = async () => {

        setLoadingFotonovelas(true)
        setError(null)

        try {
            const {fotonovelas, total} =  await getAllFotonovelas(user.id, 3, page)

            setLancamentos(fotonovelas)
            setTotalLancamentos(total)

            setPaginationCount(
                calculatePaginationTotalPages(total)
            )

            setLoadingFotonovelas(false)

        } catch (error) {
            const reason = JSON.parse(error)

            if (reason.code !== 400) {
                setLoadingFotonovelas(false)
                setError({message: `Ooops! Erro inesperado`})
 
                return
            }

            setError({message: reason?.message})
            setLoadingFotonovelas(false)

        }
        
    }

    return (
        <div className="todos-lancamentos" style={{paddingTop: "1rem"}} >
            <>
                {
                    !loadingFotonovelas && lancamentos.length < 1 &&
                    <div
                        className="flex-row"
                        style={{
                            textAlign: "center",
                            justifyContent: "center",
                            gap:"3px",
                            color: "#bababa",
                            marginTop: "10rem"
                        }}>
                        <span className="material-symbols-outlined" style={{color: "#bababa", fontSize: "15px"}}>
                            error
                        </span>
                        <small style={{color: "#bababa",}}>Nenhum registo encontrado</small>
                    </div>
                }

                {
                    loadingFotonovelas
                        ? (
                            <div style={{marginTop: "10rem"}}>
                                <Loading text="Carregando..." />
                            </div>
                        )
                        : (
                            <>
                                {
                                    lancamentos.map(item => {
                                        return ( <Lancamento key={item.id} lancamento={item} /> )
                                    })
                                }
                                {
                                    !loadingFotonovelas && lancamentos.length > 0 &&
                                    <div style={{padding: "1rem 0"}}>
                                        <ClientPagination count={ paginationCount === 0 ? 1 : paginationCount} route="/painel-usuario/fotonovelas"/>
                                    </div>
                                }
                            </>
                        )
                }
            </>
        
        </div>
    )
}

export default Todos