import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import "./../data-table.css"

import Button from "../../components/button"
import DeleteFormDialog from "./delete-form-dialog"


const formatCurrency = (number) => new Intl.NumberFormat( "ao-AO", {style: "currency", currency: "AOA"} ).format(number)

export default function DataTable({data, afterSubmitHandler, fotonovelas}) {
    const [deleteFormOpen, setDeleteFormOpen] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState()

    const navigate = useNavigate()

    const openDeleteFormHandler = (item) => {
        setSelectedRecord(item)
        setDeleteFormOpen(true)
    }

    const openDetails = (item) => {
        navigate(
            `${item.id}`,
            {
                state: {
                    data: item,
                    fotonovelas: fotonovelas
                }
            }
        )
    }

    return (
        <div className="admin-data-table">
            <table>
                <thead>
                    <tr
                        style={{backgroundColor: "#dfdcdc"}}
                    >
                       <th style={{width: "50px"}}>N/O</th>
                       <th>Nome</th>
                       <th>Preço</th>
                       <th >Disponível</th>
                       <th >Periodo</th>
                       <th className="btns-operacao">Operação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data.map((item, index) => (
                        <tr key={item.id} >
                            <td>{index + 1}</td>
                            <td>{item.nome}</td>
                            <td>{ formatCurrency ( item.preco ) }</td>
                            <td>{ `${item.is_available ? "Sim" : "Não"}` }</td>
                            <td>{item.periodo.nome}</td>
                            <td
                                className="btns-operacao"
                            >
                                <Button
                                    text="Deletar"
                                    googleIconName="delete"
                                    crudOperation="delete"
                                    primary={false}
                                    size="small"
                                    clickHandler={openDeleteFormHandler.bind(this, item)}
                                />
                                <Button
                                    text=""
                                    googleIconName="open_in_new"
                                    crudOperation="cancel"
                                    primary={false}
                                    size="small"
                                    clickHandler={openDetails.bind(this, item)}
                                />
                            </td>
        
                        </tr>
                    ))
                }
                    
                </tbody>
            </table>
            {
                deleteFormOpen && (
                        <DeleteFormDialog
                            initialValues={selectedRecord}
                            closeFormHandler={() => setDeleteFormOpen(false)}
                            afterSubmitHandler={afterSubmitHandler}
                        />
                    )
            }
            
        </div>
    )
}


