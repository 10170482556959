
import SectionTitle from "../global/section-title"
import "./style.css"

const Planos = () => {
    return (
        <div
            id="lp-planos"
           >
            <SectionTitle title="Planos" subtitle="Escolha o plano de sua preferência" />
            <div
                className="planos-wrapper">
                <div
                    className="plano"
                >
                    <h3>Mensal</h3>
                    <h4>800,00Kz</h4>
                    <p>Tenha acesso, durante 30 dias, a todos os conteudos disponibilizados usando o plano mais básico</p>
                </div>
                <div
                    className="plano"
                >
                    <h3>Anual</h3>
                    <h4>6.000,00Kz</h4>
                    <p>Poupe 37% e tenha acesso, durante 360 dias, a todos os conteudos disponibilizados usando o plano mais básico</p>
                </div>
            </div>
        </div>
    )
}


export default Planos